import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, public modalService: NgbModal,
    public activeModal: NgbActiveModal,) { }

  termsofUseUrl: any; 

  ngOnInit(): void {
    this.termsofUseUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(environment.TERMS_OF_USE_CONTENT);
  }

}
