import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AnalyticsService } from '../service/analytics.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  selectedAuthorData: any;

  constructor(private utilityService: UtilitiesService, private http: HttpClient,  private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.analyticsService.pageView('News and Resources', 'Page Load');
    this.analyticsService.eventEmitter('News and Resources', window.location.href, 'Page Load');
    this.selectedAuthorData = this.utilityService.getAuthorData();
  }

  playVideo() {
    this.analyticsService.pageView('News and Resources', 'Play Video');
    this.analyticsService.eventEmitter('News and Resources', window.location.href, 'Play Video');
    window.open('https://authorportal.hbgusa.com/widget/web/guest/banner-video/-/56_INSTANCE_SNACwLIQEiA1', '_blank', 'location=yes,height=350,width=600,scrollbars=no,status=yes' );
  }

  public downloadDocument(fileName: any) {
    this.analyticsService.eventEmitter('News and Resources, Download Document', window.location.href, fileName);
    this.getJSON(fileName).subscribe(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }, err => {
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  public getJSON(fileName: any): Observable<any> {
    var filePath = "./assets/docs/" + fileName;
    return this.http.get(filePath, { responseType: 'blob' });
  }

}
