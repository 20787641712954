import { HttpClient, HttpHeaders } from '@angular/common/http';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
// import { REFUSED } from 'dns';
// import { filter } from 'lodash';
import { Observable, Subject, forkJoin, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { UtilitiesService } from 'src/app/service/utilities.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
// import * as CryptoJS from 'crypto-js';
// import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  httpOptions: { headers: HttpHeaders; };
  private idleChanges = new Subject;
  idleChangesObs = this.idleChanges.asObservable();
  constructor(private http: HttpClient, private route: ActivatedRoute, private auth: AuthService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    };
  }

  public saveSection(requestString: any) {
    const formData = new FormData();
    formData.append('json', JSON.stringify(requestString));
    const url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.answer/save-answers";
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  getPermission(userId) {
    var url = 'https://hbg-sitea-dev-api-mgmt.azure-api.net/hbg-authorportal-api/api/v1/user/permissions';
    // ?userId=21425&subscription-key=7a6b9fd4d2c34584a9a4f6555873e48d'
    // var url = environment.pluginUrl + "/api/jsonws/user/get-current-user";
    return this.http.get(url, {
      params: {
        'userId': userId,
        'subscription-key': '7a6b9fd4d2c34584a9a4f6555873e48d'
      },
      withCredentials: true
    });
  }
  idleChangesQuestionnaire() {
    this.idleChanges.next();
  }

}
