<app-header></app-header>
<div class="dashboard-wrapper">
    <section class="pb-md-4 pb-3">
        <div class="container">
            <div class="row">
                <div class="d-md-flex d-block author-info">
                    <div class="flex-shrink-0 text-md-start text-center mb-3">
                        <div class="zoom-effect user-profile-pic no-animation">
                            <img src="{{authorImageUrl}}" class="fix-height" alt="">
                        </div>

                    </div>
                    <div class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-5">
                        <h2 class="mb-3"><span class="fst-italic">{{selectedAuthorData?.fullName}}</span>{{ authorBlurbTitlePostFix }}
                        </h2>
                        <p>{{description}}
                        </p>
                        <ng-container *ngIf="isAuthorRole">
                            <a (click)="gaClick('About You', selectedAuthorData.authorProfileId)" [class.link-disabled]="!pagePermission?.canViewAq" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'questionnaire','aboutYou']"
                                class="w-100 d-block mb-2 my-2">About You <img class="ms-2"
                                    src="assets/images/arrow-right.png" alt="">
                            </a>
                            <a (click)="gaClick('About Your Book', selectedAuthorData.authorProfileId)" [class.link-disabled]="!pagePermission?.canViewAq" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'questionnaire','aboutBook']" class="w-100 d-block my-0"
                                *ngIf="currentBookFuture?.title">
                                <span></span>About Your Book, "{{currentBookFuture?.title}}"
                                <img class="ms-2" src="assets/images/arrow-right.png" alt="">
                            </a>
                            <hr>
                            <a (click)="gaClick('Read Hachette Author Guide')" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'guide']"
                                class="w-100 d-block">Read Hachette's Author Guide <img class="ms-2"
                                    src="assets/images/arrow-right.png" alt=""> </a>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <ng-container *ngIf="pagePermission?.canViewSales && authorWorks.length > 0 && hasPOSFLAG">
        <app-sales-glance></app-sales-glance>
    </ng-container> -->
    <!-- This is updated due to requirnment change in APO-1024 -->

    <div class="my-book-bg" *ngIf="authorWorks && authorWorks.length > 0"></div>
    <section class="book-sec-main" *ngIf="authorWorks && authorWorks.length > 0">
        <div class="container">
            <div class="row text-center align-items-center">
                <div class="col-md-3">
                    <div class="input-group" *ngIf="authorWorks.length > 5">
                        <input [(ngModel)]="searchBook" type="text" class="form-control border-end-0"
                            placeholder="Search by Book..." aria-label="Search Book" aria-describedby="basic-addon2">
                        <span class="input-group-text bg-white" id="basic-addon2"><img
                                src="assets/images/search-gray.png" alt=""></span>
                    </div>
                </div>
                <div class="col-md-6 my-md-0 my-3">
                    <h4 class="background"><span>MY BOOKS</span></h4>
                </div>
                <div class="col-md-3" *ngIf="authorWorks.length > 5">
                    <div class="btn-group float-md-end float-none bg-white mb-2" role="group"
                        aria-label="Basic radio toggle button group">
                        <label (click)="setBookLimit(5); gaClick('My Book limit change ', 5)" [class.active]="bookShelfConstant.initialLimit === 5"
                            class="btn btn-outline-primary count-btn active border" for="btnradio1">5</label>

                        <label (click)="setBookLimit(10); gaClick('My Book limit change ', 10)" [class.active]="bookShelfConstant.initialLimit === 10"
                            class="btn btn-outline-primary count-btn border" for="btnradio2">10</label>

                        <label (click)="setBookLimit(authorWorks.length); gaClick('My Book limit change ', 'All')"
                            [class.active]="bookShelfConstant.initialLimit === authorWorks.length"
                            class="btn btn-outline-primary count-btn border" for="btnradio3">All</label>
                    </div>
                </div>
            </div>
            <div class="row mt-4 row-cols-5 my-book justify-content-center">
                <!-- For multiple books -->
                <ng-container *ngIf="authorWorks.length > 2">
                    <div *ngFor="let work of authorWorks | slice:0:bookShelfConstant.initialLimit | searchFilter: searchBook ; let i=index"
                        class="col px-4 text-center mb-md-5 mb-3">
                        <div title="{{work.title}}" class="zoom-effect">
                            <img href="#" src="{{work.coverImgURL}}" onerror="this.src='./assets/images/default-book.svg';" class="w-100 mb-2 border bg-white p-2 fix-height cursor-pointer"
                                alt="" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',work?.isbn]">
                        </div>
                        <a class="text-decoration-none" href="#" (click)="gaClick('Book Detail', work.isbn)" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',work?.isbn]">{{work.title}}</a>
                        <p class="fst-italic mt-1 mb-2" *ngIf="work.products[0]?.onSaleDate">On Sale: {{work.formattedWorkOnSaleDate |date : 'MM.dd.yy'}}</p>
                        <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',work?.isbn]" (click)="gaClick('Book Detail', work.isbn)" class="d-block">View Book Details</a>
                        <a *ngIf="work.products[0]?.onSaleDate && showSalesLink(work.formattedWorkOnSaleDate, work.isbn)"  [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'salesDashboard',work?.isbn]" (click)="gaClick('View Sales', work.isbn)">View Sales</a>
                    </div>
                </ng-container>
                <!-- For multiple books /. -->
                <!-- For single and double book -->
                <div class="w-100 d-flex justify-content-evenly flex-nowrap" *ngIf="authorWorks.length  <= 2">
                    <div class="card book-card mb-3 mx-auto rounded-0"
                        *ngFor="let work of authorWorks | slice:0:bookShelfConstant.initialLimit | filter: searchBook ; let i=index">
                        <div class="card-body p-4">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 book-cover" title="{{work.title}}">
                                    <img href="#" src="{{work.coverImgURL}}" onerror="this.src='./assets/images/default-book.svg';" alt="{{work.title}}" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',work?.isbn]" class="cursor-pointer">
                                </div>
                                <div class="book-details flex-grow-1 ms-4 text-start">
                                    <h3 (click)="gaClick('Book Detail', work.isbn)" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',work?.isbn]">{{work.title}}</h3>
                                    <p class="date w-100 d-block mb-1"><em>On Sale</em> : {{work.formattedWorkOnSaleDate
                                        | date : 'MM.dd.yy'}}</p>
                                    <a href="javascript:void(0)" (click)="gaClick('Book Detail', work.isbn)" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',work?.isbn]" class="w-100 d-block">View Book Details <i
                                            class="fa fa-chevron-right"></i></a>
                                            <a *ngIf="work.products[0]?.onSaleDate && showSalesLink(work.formattedWorkOnSaleDate, work.isbn)" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'salesDashboard',work?.isbn]" (click)="gaClick('View Sales', work.isbn)" class="w-100 d-block">View Sales <i class="fa fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- For single and double book /. -->
            </div>
        </div>
    </section>

    <ng-container *ngIf="workList && workList.length > 0" >
        <app-hbg-team #hbgTeam [workList]="workList"></app-hbg-team>
    </ng-container>
    <app-news-widget></app-news-widget>
</div>

<app-footer></app-footer>
