import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable, Subject, forkJoin, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {
  private authorsList: any;
  private USER_ID = 'userId';
  private productWorksDetails: any;

  public getUserId() { return localStorage.getItem(this.USER_ID); }

  constructor(private http: HttpClient, private auth: AuthService) { }

  getWorks() {
    let url = environment.d3AuthorApiUrl + "author/profile/works?authorProfileId=" + this.auth.getSelectedAuthor() + "&userId=" + this.auth.getUserId();
    return this.http.get(url, this.loadAuthorHttpOptions());
  }

  getTeamMemberInfo(workIsbn: any) {
    let url = environment.d3AuthorApiUrl + "author/profile/teams?authorProfileId=" + this.auth.getSelectedAuthor() + "&userId=" + this.auth.getUserId()+"&workIsbn="+workIsbn;
    return this.http.get(url, this.loadAuthorHttpOptions());
  }
  getUserBio(email: any) {
    let url = environment.utilApiUrl + "/bookends/user/bio?id=" + btoa(email);
    return this.http.get(url, this.loadUtilHttpOptions());
  }
  getUserPhoto(email: any) {
    let url = environment.utilApiUrl + "/bookends/user/photo?id=" + btoa(email);
    return this.http.get(url, this.loadUtilHttpOptions());
  }

  changePassword(userData: any) {
    
    const formData = new FormData();
    formData.append('userId', userData.userId);
    formData.append('oldPassword', userData.oldPassword);
    formData.append('password', userData.password);
    formData.append('reEnterPassword', userData.reEnterPassword);
    let url = environment.pluginUrl + '/api/jsonws/login-portlet.authentication/update-password';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  clearCache() {
    this.authorsList = [];
  }

  public loadHttpOptions = {
    headers: new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment["Ocp-Apim-Subscription-Key"]
    }),
  };

  public loadUtilHttpOptions() {
    return {
      'headers': new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.apiParams['Ocp-util-Apim-Subscription-Key'],
      }),
    };
  }

  public loadAuthorHttpOptions() {
    return {
      'headers': new HttpHeaders({
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'Ocp-Apim-Subscription-Key':environment.apiParams['Ocp-author-Apim-Subscription-Key'] ,
      }),
    };
  }

  getAuthors(loadCache = false) {
    var url = environment.d3AuthorApiUrl +'author/profile/allAuthorProfiles';
    if (this.authorsList && loadCache) {
      return of(this.authorsList);
    }
    return this.http.get(url, {
      params: {
        'userId': this.getUserId(),
      },
      headers : this.loadAuthorHttpOptions().headers
    }).pipe(map((res) => {
      this.authorsList = res;
      return res;
    }));
    
  }

  getAuthorPhoto(assetId) {
    var url = environment.utilApiUrl + '/assets/getAuthorPhoto?assetId='+assetId;
    return this.http.get(url, this.loadUtilHttpOptions());
  }

  clearWorkDetails() {
    this.productWorksDetails = [];
  }

  getProductWorks() {
    let url = environment.d3AuthorApiUrl + "author/profile/works?authorProfileId=" + this.auth.getSelectedAuthor() + "&userId=" + this.auth.getUserId();
    if (this.productWorksDetails && this.productWorksDetails.length > 0) {
      return of(this.productWorksDetails);
    }
    return this.http.get(url, {
      headers : this.loadAuthorHttpOptions().headers
    }).pipe(map((res) => {
      this.productWorksDetails = res;
      return res;
    }));
  }
  
  getRoyaltyStatements(url: any) {
    url = "/api/jsonws" + url;
    return this.http.get(url, this.loadUtilHttpOptions());  
  }

  submitResetRequest(email){
    const formData = new FormData();
    formData.append('_58_emailAddress', email.trim());
    var url = environment.pluginUrl + '/web/guest/home?p_p_id=58&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&p_p_col_id=column-1&p_p_col_count=1&_58_struts_action=/login/forgot_password';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded', responseType: 'text' } as any));//.pipe(map((rsp: any) => { return rsp; }));;
  }
}
