import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { ChooseDiffBookModalComponent } from 'src/app/shared/choose-diff-book-modal/choose-diff-book-modal.component';
import {
  find as _find,
  orderBy as _orderBy,
  isEmpty as _isEmpty,
  cloneDeep as _cloneDeep,
} from 'lodash-es';
import { questionnaireConstants } from '../questionnaire-constants';
import { AuthorService } from 'src/app/service/author.service';
import { PermissionService } from 'src/app/service/permission.service';
import { environment } from 'src/environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { SessionService } from 'src/app/service/session.service';
import { QuestionnaireService } from 'src/app/service/questionnaire.service';

@Component({
  selector: 'app-about-book',
  templateUrl: './about-book.component.html',
  styleUrls: ['./about-book.component.scss'],
})
export class AboutBookComponent implements OnInit {
  @Input() settings: [];
  @Output('saveSection') saveSection: EventEmitter<string> = new EventEmitter();
  currentBookFuture: any;
  authorWorks: any = [];
  coverImgURL = environment.coverImgURL;
  selectedAuthor: string;
  selectedAuthorData: any;
  subscription: Subscription;
  aboutBookQuestionnaires: any = [];
  showText: boolean = false;
  canEdit: any = true;
  years: any = [];
  months: any = questionnaireConstants.months;
  days: any = questionnaireConstants.days;
  allContacts: any = [];
  pagePermission: any = [];
  options: any = {
    types: ['geocode'],
    forceSelection: true,
  };
  oldaboutBookQuestionnaires: any[];
  saveTimeInterval: Subscription;
  multiSelectSeparator = environment.multiSelectSeparator;

  constructor(
    private questionnaireService: QuestionnaireService,
    public sessionService: SessionService,
    private utilityService: UtilitiesService,
    private permissionService: PermissionService,
    private authorService: AuthorService,
    public authService: AuthService,
    public modalService: NgbModal,
    private analyticsService: AnalyticsService
  ) {
    this.pagePermission = this.permissionService.getPagePermission();
    this.canEdit = this.pagePermission.canEditAq == 1 ? true : false;

    this.subscription = this.authService.selectedAuthor.subscribe((result) => {
      this.bindAuthorData();
    });

    this.subscription = this.utilityService.selectedBook.subscribe((result) => {
      if (result) {
        this.currentBookFuture = result;
      }
    });
  }

  ngOnInit(): void {
    this.analyticsService.pageView('About Your Book', 'Page Load');
    this.analyticsService.eventEmitter(
      'About Your Book',
      window.location.href,
      'Page Load'
    );
    this.getYears();
    this.bindAuthorData();
    this.getBooks();
    this.autoSaveForm();
    this.formChangesOnLogout();
  }
  public getYears() {
    const currentYear = new Date().getFullYear();
    let i: any;
    for (
      i = currentYear - questionnaireConstants.maxYears;
      i <= currentYear;
      i++
    ) {
      this.years.push(i);
    }
  }

  bindAuthorData() {
    this.selectedAuthor = localStorage.getItem('selectedAuthor');
    this.selectedAuthorData = this.utilityService.getAuthorData();
  }

  getBooks() {
    this.utilityService.showLoader();
    this.authorService.getWorks().subscribe(
      (result: any) => {
        this.utilityService.hideLoader();
        this.authorWorks = result;
        var imageUrl = this.coverImgURL;
        this.authorWorks.forEach((element) => {
          element['coverImgURL'] = imageUrl + element.isbn + '/';
        });

        let currentBookFutureInput = _cloneDeep(this.authorWorks);
        if (currentBookFutureInput && currentBookFutureInput.length > 0) {
          this.currentBookFuture = currentBookFutureInput[0];
          this.currentBookFuture['coverImgURL'] =
            this.coverImgURL + this.currentBookFuture['isbn'] + '/';
          this.getQuestionnaires();
          this.getContacts();
        }
      },
      (err) => {
        this.utilityService.hideLoader();
        this.utilityService.showInformation(
          this.utilityService.getErrorMessage(),
          'Error'
        );
      }
    );
  }

  // Choose different book
  chooseDifferentBook() {
    this.checkFormChanges();
    const modalRef = this.modalService.open(ChooseDiffBookModalComponent, {
      centered: true,
      size: 'xl',
      windowClass: 'animated fadeIn',
    });

    modalRef.componentInstance.authorWorks = this.authorWorks;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (this.currentBookFuture.isbn !== receivedEntry.isbn) {
        this.currentBookFuture = receivedEntry;
        this.getQuestionnaires();
        this.getContacts();
      }
    });
  }

  public handleAddressChange(address, answer) {
    if (address.formatted_address) {
      // this.contactSection.city = this.getAddressComponent(questionnaireConstants.cityVar, address.address_components);
      // this.contactSection.state = this.getAddressComponent(questionnaireConstants.stateVar, address.address_components);
      // this.contactSection.country = this.getAddressComponent(questionnaireConstants.countryVar, address.address_components);
      // this.contactSection.zip = this.getAddressComponent(questionnaireConstants.zipVar, address.address_components);
      answer.answerText = address.formatted_address;
    } else {
      answer.answerText = address?.target?.value;
    }
  }

  downloadPdf() {
    var postData = {};
    var aboutBookQuestionnaireId = _find(this.settings, {
      key: 'ABOUT_BOOK_QUESTIONNAIRE_ID',
    }).value;

    var aboutYouQuestionnaireId = _find(this.settings, {
      key: 'ABOUT_AUTHOR_QUESTIONNAIRE_ID',
    }).value;

    postData['authorId'] = this.authService.getSelectedAuthor();
    if (this.currentBookFuture && this.currentBookFuture.isbn) {
      postData['scopeId'] = this.currentBookFuture.isbn;
    }

    (postData['scopeType'] = 'Work'),
      (postData['aboutYouQuestionnaireId'] = parseInt(
        aboutYouQuestionnaireId,
        10
      )),
      (postData['aboutYourBookQuestionnaireId'] = parseInt(
        aboutBookQuestionnaireId,
        10
      )),
      (postData['authorName'] = this.selectedAuthorData.fullName.toString());
    postData['bookName'] = this.currentBookFuture.title;
    this.utilityService.getAuthorQuestionnairePdfData(postData);
  }

  save(quest: any, form?: any, autoSave = '', index?: any, saveType: any = '') {
    if (saveType == 'saveAll') {
      this.checkFormChanges();
      return;
    }
    if (autoSave != 'autoSave') {
      if (this.EmailValidation(form, 'manualSave', index)) {
        return;
      }
    }
    this.gaClick(quest.name, 'About Your Book - Questionnaire');
    this.showText = false;
    quest['scopeType'] = 'Work';
    quest['scopeId'] = this.currentBookFuture.isbn;
    quest.selectedtab = 'aboutbook';
    quest.saveMode = autoSave;
    this.saveSection.emit(quest);
    this.bindQuestionnairesValue();
    this.questionnaireService.idleChangesQuestionnaire();
  }

  selectChange(question) {
    this.aboutYouFormChanged();
    this.setDate(question);
    this.showText = true;
  }

  textKeyPress() {
    this.showText = true;
  }
  aboutYouFormChanged = function () {
    if (this.isAboutYouFormChanged) {
      this.isAboutYouFormChanged = true;
    }
  };

  setDate(question) {
    if (question.day && question.month && question.year) {
      question.answers.answerText =
        question.day + '/' + question.month + '/' + question.year;
    }
  }

  getContacts() {
    var params = {};
    params['scopeId'] = this.currentBookFuture.isbn;
    params['scopeType'] = 'Work';
    this.utilityService.showLoader();
    this.authService
      .getContactsQuestionares(params)
      .subscribe((result: any) => {
        this.utilityService.hideLoader();
        this.allContacts = result;
      });
    // questionnaireService.getContacts( postData, ignoreLoadingBar ).
    //   then( function ( contacts ) {
    //     $ctrl.allContacts = contacts.data;
    //   } );
  }

  getQuestionnaires() {
    //   aboutYouSettingKey: 'ABOUT_AUTHOR_QUESTIONNAIRE_ID',
    // aboutYourBookSettingKey: 'ABOUT_BOOK_QUESTIONNAIRE_ID',

    var questionnaireId = _find(this.settings, {
      key: 'ABOUT_BOOK_QUESTIONNAIRE_ID',
    }).value;

    var params = {
      questionnaireId: parseInt(questionnaireId, 10),
      scopeType: 'Work',
      scopeId: this.currentBookFuture.isbn,
    };

    // questionnaireConstants.aboutBookScopeType;
    this.utilityService.showLoader();
    this.authService.getBookQuestionares(params).subscribe(
      (result: any) => {
        this.utilityService.hideLoader();
        this.aboutBookQuestionnaires = result;
        this.oldaboutBookQuestionnaires = _cloneDeep(result);

        this.bindQuestionnairesValue();
      },
      (err) => {
        this.utilityService.showInformation(
          this.utilityService.getErrorMessage(),
          'Error'
        );
      }
    );
  }

  getDate(question) {
    var date;
    question.answers = question.answers ? question.answers : {};
    if (question.answers.answerText) {
      date = question.answers.answerText.split('/');
      question.month = date[1];
      question.day = date[0];
      question.year = date[2];
    }
    return question;
  }

  saveContactSection(contactForm: any) {
    this.utilityService.showLoader();
    var postdata: any = {};
    postdata.check = true;
    postdata.scopeId = this.currentBookFuture.isbn;
    postdata.scopeType = questionnaireConstants.aboutBookScopeType;
    contactForm.mailingAddress =
      contactForm.mailingAddress && contactForm.mailingAddress.formatted_address
        ? contactForm.mailingAddress.formatted_address
        : contactForm.mailingAddress || undefined;
    postdata.contactUpdates = [contactForm];

    this.authService.saveContactsQuestionares(postdata).subscribe(
      (result: any) => {
        this.utilityService.hideLoader();
        this.getContacts();
      },
      (err) => {
        this.utilityService.showInformation(
          this.utilityService.getErrorMessage(),
          'Error'
        );
      }
    );
  }

  addNewContact(contactType: any) {
    var newContact = _cloneDeep(questionnaireConstants.newContact);
    newContact.contactType = contactType;
    this.allContacts.push(newContact);
  }

  deleteContact(contactId) {
    if (!contactId) {
      return;
    }
    this.utilityService.showLoader();
    this.authService.deleteContact(contactId).subscribe(
      (result: any) => {
        this.utilityService.hideLoader();
        this.getContacts();
      },
      (err) => {
        this.utilityService.showInformation(
          this.utilityService.getErrorMessage(),
          'Error'
        );
      }
    );
  }

  public getContactData(contactType) {
    let postData: any = {};
    postData.authorId = this.authService.getSelectedAuthor();
    if (this.currentBookFuture && this.currentBookFuture.isbn) {
      postData['scopeId'] = this.currentBookFuture.isbn;
    }
    postData.scopeType = questionnaireConstants.aboutBookScopeType;
    postData.contactType = contactType;
    postData['authorName'] = this.selectedAuthorData.fullName.toString();
    postData['bookName'] = this.currentBookFuture.title;
    this.utilityService.getContactData(postData);
  }

  public deleteNewItem(isNew: any, index: any) {
    if (isNew) {
      this.allContacts[index]['isDeleted'] = true;
    }
  }

  public gaClick(event: string, eventAction?: string) {
    if (!eventAction) {
      eventAction = 'About Your Book - Questionnaire';
    }

    this.analyticsService.eventEmitter(
      eventAction,
      window.location.href,
      event
    );
  }
  private autoSaveForm() {
    if (this.saveTimeInterval) {
      this.saveTimeInterval?.unsubscribe();
    }

    this.saveTimeInterval = interval(180000).subscribe(() => {
      this.checkFormChanges();
    });
  }
  public checkFormChanges() {
    for (var i = 0; i < this.oldaboutBookQuestionnaires?.length; i++) {
      let oldData = this.oldaboutBookQuestionnaires[i]['questions'].map((x) =>
        x.answers.answerText
          ? x.answers.answerText.toString()
          : x.answers.answerText === false
          ? 'false'
          : null
      );
      let newData = this.aboutBookQuestionnaires[i]['questions'].map((x) =>
        x.answers.answerText
          ? x.answers.answerText.toString()
          : x.answers.answerText === false
          ? 'false'
          : null
      );
      if (JSON.stringify(oldData) != JSON.stringify(newData)) {
        if (
          this.EmailValidation(this.aboutBookQuestionnaires[i], 'onTabChange')
        ) {
          this.save(
            this.aboutBookQuestionnaires[i],
            undefined,
            'autoSave',
            '',
            ''
          );
        }
      }
    }
    this.oldaboutBookQuestionnaires = _cloneDeep(this.aboutBookQuestionnaires);
  }
  bindQuestionnairesValue() {
    this.aboutBookQuestionnaires.forEach((section) => {
      section.counter = 0;

      section.questions.forEach((ques) => {
        if (ques.questionType !== 'checkbox') {
          section.counter =
            ques.questionType === 'label'
              ? section.counter
              : section.counter + 1;
          ques.counter = ques.questionType === 'label' ? '' : section.counter;
          ques.answers = ques.answers ? ques.answers : {};
        }
        if (ques.questionType == 'checkbox') {
          if (
            (ques.answers?.answerText && ques.answers?.answerText == 'true') ||
            ques.answers?.answerText == true
          ) {
            ques.answers.answerText = true;
          } else if (
            ques.answers.answerText == 'false' ||
            ques.answers.answerText == false
          ) {
            ques.answers.answerText = false;
          } else {
            if (ques.answers == '') {
              ques.answers = {};
            }
          }
        }

        if (ques.questionType === 'date') {
          ques = this.getDate(ques);
        }

        if (ques.questionType == 'textarea') {
          let rowCount = 3;
          if (ques.answers.answerText) {
            rowCount = ques.answers.answerText.split('\n').length;
            if (rowCount <= 3) {
              ques.answers.rowCount =
                parseInt((ques.answers.answerText.length / 150).toString()) + 2;
            }
            if (rowCount > 20) {
              ques.answers.rowCount = 20;
            } else {
              ques.answers.rowCount = rowCount;
            }
          } else {
            ques.answers.rowCount = rowCount;
          }
        }

        ques.answerModifiedDate = !_isEmpty(ques.answers)
          ? new Date(ques.answers.modifiedDate)
          : '';
      });

      var _seactions = _orderBy(
        section.questions,
        'answerModifiedDate',
        'desc'
      );
      if (_seactions.length > 0 && _seactions[0].answerModifiedDate) {
        section.modifiedDate = new Date(_seactions[0].answerModifiedDate);
        if (section.modifiedDate == 'Invalid Date') {
          section.modifiedDate = false;
        }
      } else {
        section.modifiedDate = false;
      }
    });
  }
  EmailValidation(form, type, index?) {
    if (type == 'onTabChange') {
      let emailFiedlds = form.questions.filter(
        (x) => x.questionType == 'email'
      );
      let emailInvalid = false;
      let reg = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      emailFiedlds.forEach((element) => {
        if (
          element.answers.answerText &&
          element.answers.answerText != '' &&
          !reg.test(element.answers.answerText)
        ) {
          emailInvalid = true;
          return;
        }
      });
      if (emailInvalid) {
        return false;
      }
      return true;
    }
    if (type == 'manualSave') {
      try {
        if (form?.controls) {
          let emails = Object?.keys(form?.controls).filter((x) => {
            if (x.startsWith('email')) {
              return x;
            }
          });
          let emailInvalid = false;
          emails.forEach((element) => {
            if (
              form.controls[element].invalid &&
              form.controls[element].dirty
            ) {
              emailInvalid = true;
              return;
            }
          });
          if (emailInvalid) {
            return true;
          }
        }
      } catch {}
      this.oldaboutBookQuestionnaires[index] = _cloneDeep(
        this.aboutBookQuestionnaires[index]
      );
      this.saveTimeInterval?.unsubscribe();
      this.autoSaveForm();
      return false;
    }
  }
  formChangesOnLogout() {
    this.sessionService.questionnaireObs.subscribe((res) => {
      this.checkFormChanges();
    });
  }
  ngOnDestroy() {
    this.checkFormChanges();
    this.saveTimeInterval?.unsubscribe();
  }

  updateCheckBoxQuesType(ques, evt) {
    // Find the index of the category matching the question's category ID
    let categoryIndex = this.aboutBookQuestionnaires.findIndex(
      (category) => category.categoryId == ques.categoryId
    );

    // Find the index of the question within the found category
    let quesIndex = this.aboutBookQuestionnaires[
      categoryIndex
    ].questions.findIndex(
      (bookQuestionnaire) => bookQuestionnaire.questionId == ques.questionId
    );

    // Retrieve the current answer text, defaulting to an empty string if not found
    let ansStr =
      this.aboutBookQuestionnaires[categoryIndex].questions[quesIndex].answers
        .answerText || '';

    // If the current answer is 'true' or true, reset it to an empty string to handle checkbox behavior
    if (ansStr == 'true' || ansStr === true) {
      ansStr = '';
    }
    // Similarly, reset if the current answer is 'false' or false
    else if (ansStr == 'false' || ansStr === false) {
      ansStr = '';
    }

    // If the checkbox is checked, add the value to the answer string
    if (evt.checked) {
      ansStr.length
        ? (ansStr += this.multiSelectSeparator + evt.source.value)
        : (ansStr += evt.source.value);
    } else {
      // If the checkbox is unchecked, remove the value from the answer string
      let data = ansStr.split(this.multiSelectSeparator);
      // Find the index of the unchecked value
      let index = data.findIndex((res) => res === evt.source.value);
      // Remove the unchecked value
      data.splice(index, 1);
      ansStr = data.join(this.multiSelectSeparator);
    }

    // Update the answer text in the questionnaire with the modified answer string
    this.aboutBookQuestionnaires[categoryIndex].questions[
      quesIndex
    ].answers.answerText = ansStr;
  }

  isCheckBoxChecked(answer, option) {
    if (answer.split(this.multiSelectSeparator).length) {
      return answer.split(this.multiSelectSeparator).some((res) => {
        return res == option;
      });
    } else {
      return false;
    }
  }
  getAnswerTextAsBoolean(question: any): boolean {
    return (
      question.answers.answerText === 'true' ||
      question.answers.answerText === true
    );
  }

  setAnswerText(question: any, value: boolean) {
    question.answers.answerText = value.toString();
  }
}
