import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/service/banner.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/service/analytics.service';
@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss']
})
export class NewsWidgetComponent implements OnInit {
  feeds: any = [];
  selectedAuthorData: any;

  constructor(private bannerService: BannerService, private utilityService: UtilitiesService,
              private router : Router, private analyticsService : AnalyticsService) { }

  ngOnInit(): void {
    this.selectedAuthorData = this.utilityService.getAuthorData();
    this.getRssFeed();
  }

  getRssFeed() {
    this.utilityService.showLoader();
    this.bannerService.getRssFeeds().subscribe((result: any) => {
      this.utilityService.hideLoader();
      this.feeds = result;
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
    });
  }

  public gaClick(event: any) {
   
    this.analyticsService.eventEmitter('Dashboard News', window.location.href, event);
  }

}
