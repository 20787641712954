<app-header></app-header>
<section class="book-sec-main hbg-news m-0">
    <div class="container">
        <h5 class="mt-5 mb-3">
            <span>HBG NEWS</span>
        </h5>
        <div class="row py-4 row-repeat border-bottom" *ngFor="let section of chunkedData">
            <div class="col-md-4 col-xs-12 px-4 mb-3" *ngFor="let feed of section">
                <div class="zoom-effect news-pic d-inline-block mb-3" [routerLink]="['/navigation',selectedAuthorId,'newsArticle', feed.articleId]">
                    <img role="button" class="thumbnail-image" onerror="this.style.display='none'" src="{{feed.featuredImage}}" class="w-100 fix-height"
                        alt="" >
                </div>
                <a class="font-large fs-5 lh-base" [routerLink]="['/navigation', selectedAuthorId,'newsArticle', feed.articleId]">{{feed.title}}</a>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>