import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { BookSelectorService } from 'src/app/service/book-selector.service';
import * as moment from 'moment';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { SessionService } from 'src/app/service/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, cloneDeep as _cloneDeep, chain as _chain } from 'lodash-es';
import { AuthorService } from 'src/app/service/author.service';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/service/analytics.service';
import * as _ from "lodash-es";
import { PermissionService } from 'src/app/service/permission.service';

@Component({
  selector: 'app-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.scss']
})
export class BookDetailsComponent implements OnInit {
  form: FormGroup;
  value = ["QZEQ", "QZYQ"];
  show = false;
  bookDetail;
  productIsbn;
  coverImgURL = environment.coverImgURL;
  showDownloadImage;
  allFormats = [];
  FALSE = '0';

  quoteLength;
  quoteLengthStatic;
  hasQuotesReviewsData = false;

  selectedAuthorData: any;
  selectedFormat: any;

  authorWorks: any;
  latestBooks: any;
  booksInSeries: any;

  loader: boolean = false;
  teamList: any;
  pagePermission: any;

  constructor(private authService: AuthService, private bookSelectorService: BookSelectorService,
    private utilityService: UtilitiesService, private activatedRoute: ActivatedRoute, private sessionService: SessionService,
    private router: Router, private authorService: AuthorService, private analyticsService: AnalyticsService, private permissionService: PermissionService) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.productIsbn = params.isbn;
      this.getFormats(params.isbn);
    });

    if (!this.productIsbn) {
      // show error popup
    }

    this.pagePermission = this.permissionService.getPagePermission();
  }

  ngOnInit(): void {
    this.analyticsService.pageView('Book Details', 'Page Load');
    this.analyticsService.eventEmitter('Book Details', window.location.href, 'Page Load');
    this.authorService.clearWorkDetails();
    this.selectedAuthorData = this.utilityService.getAuthorData();
    this.quoteLength = 5;
    this.quoteLengthStatic = 5;
  }

  getFormats(isbn) {
    this.utilityService.showLoader();
    this.allFormats = [];
    this.authService.getFormats(isbn).subscribe((result: any) => {
      result.forEach(obj => {
        if (obj.futureOnix === this.FALSE) {
          let formatObj = {};
          formatObj['isbn'] = obj.productIsbn;
          formatObj['description'] = obj.productDesc + " " + obj.subProductCodeDesc + " " + obj.editionDesc;
          this.allFormats.push(formatObj);
        }
      });
      if (this.allFormats.length == 0) {
        this.sessionService.logout();
      } else {
        this.getBookDetails(isbn);

      }
      this.utilityService.hideLoader();
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  getBookDetails(isbn) {
    this.utilityService.showLoader();
    this.hasQuotesReviewsData = false;
    this.analyticsService.eventEmitter('View Book Details', window.location.href, isbn);

    this.authService.getBookDetails(isbn).subscribe((response: any) => {
      if (response.length > 0) {
        this.bookDetail = response[0];
        this.bookDetail.onixSynopsis = this.bookDetail.onixSynopsis ? unescape(this.bookDetail.onixSynopsis) : '';
        if (this.bookDetail.quotesReviewsData && this.bookDetail.quotesReviewsData.length > 0) {
          this.bookDetail.quotesReviewsData.forEach(obj => {
            try {
              obj.quote = obj.quote ? decodeURIComponent(unescape(obj.quote)) : '';
              obj.source = obj.source ? decodeURIComponent(unescape(obj.source)) : '';
            }
            catch (err) {
              obj.quote = obj.quote;
              obj.source = obj.source;
            }
            this.hasQuotesReviewsData = true;
          });
        }
        this.bookDetail.selectedIsbn = isbn;
        this.bookDetail.formats = this.allFormats
        this.bookDetail.coverImgURL = this.coverImgURL + this.bookDetail.isbn + '/';
        this.checkImage(this.bookDetail.coverImgURL);

        //get the selected format
        const results = filter(this.allFormats, function (item) {
          return item.isbn === isbn;
        });
        this.selectedFormat = results[0];

        this.utilityService.hideLoader();
        if (isbn === this.productIsbn) {
          this.getLatestAndInSeriesBooks(this.bookDetail.series);
          this.getTeamDetails(isbn);
        }
      }
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  getTeamDetails(isbn) {
    this.utilityService.showLoader();
    this.authorService.getTeamMemberInfo(isbn).subscribe((data: any) => {
      if (data) {
        this.teamList = data;
      }
      this.utilityService.hideLoader();
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  checkImage(imgUrl) {
    let image = new Image();
    image.src = imgUrl;
    image.onload = (event) => {
      this.showDownloadImage = true;
    }
    image.onerror = (event) => {
      this.showDownloadImage = false;
    }
  }

  getDimensions(dimensions) {
    if (dimensions) {
      return dimensions.split('"').join('') + ' inches';
    }
    return '';
  }

  showSalesLink(onSaleDate, workIsbn) {
    let sales_works = this.bookSelectorService.allIsbns;
    let isbnFilteredResults = filter(sales_works, function (item) {
      return item.isbn === workIsbn;
    });
    let displaySalesLink = isbnFilteredResults && isbnFilteredResults.length == 0;
    return moment() < moment(onSaleDate) || displaySalesLink ? false : true;
  }

  toggleQuoteLength(quoteLength) {
    this.quoteLength = quoteLength === this.bookDetail.quotesReviewsData.length ? this.quoteLengthStatic : this.bookDetail.quotesReviewsData.length;
  }

  navigateToDifferentIsbn(isbn) {
    this.analyticsService.eventEmitter('View Book Details', window.location.href, isbn);
    this.getBookDetails(isbn);
  }

  getLatestAndInSeriesBooks(bookSeries) {
    this.utilityService.showLoader();
    let selectedIsbn = this.productIsbn;
    this.authorService.getProductWorks().subscribe((result: any) => {
      this.authorWorks = this.utilityService.filterProductWorkData(result);
      this.bookSelectorService.setInitialIsbns(_cloneDeep(this.authorWorks));

      var imageUrl = this.coverImgURL;
      this.authorWorks.forEach(element => {
        element['coverImgURL'] = imageUrl + element.isbn + '/';
      });


      // this.booksInSeries = 
      this.authorWorks = _.filter(_cloneDeep(this.authorWorks), function (work) {
        return work.products[0] && work.products[0].series === bookSeries && work.isbn != selectedIsbn;
      });
      this.authorWorks = _.sortBy(this.authorWorks, ['onSaleDate']);
      this.booksInSeries = _.reverse(this.authorWorks);
      // value();

      //series
      // this.booksInSeries = _chain(_cloneDeep(this.authorWorks)).
      //   filter(function (work) {
      //     return work.products[0] && work.products[0].series === bookSeries && work.isbn != selectedIsbn;
      //   }).
      //   sortBy(['onSaleDate']).
      //   reverse().
      //   value();

      //latest books
      const results = filter(_cloneDeep(this.authorWorks), function (book) {
        return book.isbn != selectedIsbn;
      });
      this.latestBooks = results.slice(0, 2);

      this.utilityService.hideLoader();

    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  redirectToSales(e, isbn) {
    this.analyticsService.eventEmitter('View Sales', window.location.href, isbn);
    let sales_works = this.bookSelectorService.allIsbns;
    if (sales_works && sales_works.length == 0) {
      this.utilityService.showInformation("There is no data to display at this time.", 'NotAllowed');
    } else {
      if (e.ctrlKey) {
        const pathname: string = location.pathname;
        const url = pathname + "#" + this.router.serializeUrl(
          this.router.createUrlTree(['navigation/' + this.selectedAuthorData.authorProfileId + '/salesDashboard/' + isbn])
        );
        window.open(url, '_blank');
      } else {
        this.router.navigate(['navigation/' + this.selectedAuthorData.authorProfileId + '/salesDashboard/' + isbn]);
      }
    }
  }

}
