import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselComponent } from 'ngx-slick-carousel'
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import {
  filter as _filter,
  cloneDeep as _cloneDeep,
  find as _find,
  findIndex as _findIndex

} from "lodash-es";
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SessionService } from 'src/app/service/session.service';
import { AuthorService } from 'src/app/service/author.service';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-select-author',
  templateUrl: './select-author.component.html',
  styleUrls: ['./select-author.component.scss']
})
export class SelectAuthorComponent implements OnInit {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @Input()
  authors: any = [];
  showSlider: boolean = true;
  filterTerm: string = "";
  slides: any = [];
  allAuthorsSlides: any = [];
  userDetail: any = {};
  count = 0;
  slideLength = 0;
  searchTextChanged = new Subject<string>();
  subscription: Subscription;

  constructor(public authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private analyticsService: AnalyticsService,
    public activeModal: NgbActiveModal,
    public utilityService: UtilitiesService,
    public session: SessionService,
    private authorService: AuthorService) { }

  ngOnInit(): void {
    this.subscription = this.searchTextChanged.pipe(debounceTime(800),
      distinctUntilChanged()
    )
      .subscribe(term => {
        this.onFilterChange();
      }),


      this.getAllAuthor();
  }
  slideConfig = { "slidesPerRow": 3, "rows": 2, "slidesToScroll": 1 };

  getAllAuthor() {
    if (this.authors && this.authors.length > 0) {
      this.slides = this.authors;
      // this.getSlidesLength();
      this.getAuthorPhoto();
      this.allAuthorsSlides = _cloneDeep(this.authors);
    } else {
      this.utilityService.showLoader();
      this.authorService.getAuthors(false).subscribe((result: any) => {

        this.slides = result;
        // this.getSlidesLength();
        this.getAuthorPhoto();
        this.allAuthorsSlides = _cloneDeep(result);

        this.utilityService.hideLoader();
      }, err => {
        this.utilityService.hideLoader();
        this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

      });
    }
  }

  selectedAuthor(id) {
    this.session.setCurrentAuthor(id);
    // localStorage.setItem('selectedAuthor', id);
    var selectedAuthor = _find(this.slides, { 'authorProfileId': id });
    delete selectedAuthor.imageUrl;
    localStorage.setItem('selectedAuthorData', JSON.stringify(selectedAuthor));
    this.analyticsService.eventEmitter('Author Selected Id', window.location.href, id);
    this.analyticsService.eventEmitter('Author Selected Name', window.location.href, selectedAuthor.fullName);
    
    // if (this.userDetail && this.userDetail.screenName) {
    //   this.authService.session(this.userDetail).subscribe((result: any) => {
    //     if (result && result.ticketID) {
    //       localStorage.setItem('ticket-ECM', result.ticketID);
    //       this.session.setMSTRSessionId(result.sessionState);
    //     }
    //   }, err => {
    //     this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    //   });
    // }

    this.activeModal.close(true);
    this.authService.changeSelectedAuthor();
    this.router.navigate(['navigation', id, 'dashboard'], { relativeTo: this.activatedRoute });

  }

  beforeChange(e) {
    this.count = e.nextSlide
    this.getAuthorPhoto();
  }

  remove() {
    this.filterTerm = "";
  }

  onKeyUp(searchTextValue: string) {
    this.searchTextChanged.next(searchTextValue);
  }

  onFilterChange() {
    var searchText = this.filterTerm;


    this.slides = _cloneDeep(this.allAuthorsSlides.filter(function (n) {
      return n.fullName.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) !== -1;
    }));
    // this.getSlidesLength();
    this.showSlider = false;
    setTimeout(() => {
      this.showSlider = true;
    }, 1);
    this.count = 0;

    this.slides.forEach(element => {
      delete element.isProcessing;
      delete element.imageUrl;
    });

    this.getAuthorPhoto();

  }

  updateAuthorData(data: any) {
    let searchIndex = _findIndex(this.allAuthorsSlides, { 'authorProfileId': data.authorProfileId }, 0);
    this.allAuthorsSlides[searchIndex] = _cloneDeep(data);
  }

  getAuthorPhoto() {

    for (let index = (this.count * 6); index < ((this.count * 6) + 6); index++) {

      let imageObj = _cloneDeep(this.slides[index]);

      if (imageObj) {
        let defaultImageURL = "assets/images/author-user-image-default.svg";
        if (this.slides[index].isProcessing == false || imageObj.authorProfilePhotoId == null) {
          if (!this.slides[index].imageUrl) {
            this.slides[index].imageUrl = defaultImageURL;
          }
          // return this.slides[index].imageUrl;
        }

        // && index >= ((this.count * 6) ) && index < ((this.count * 6)+6)
        if (imageObj.authorProfilePhotoId != null && this.slides[index].isProcessing == undefined) {
          this.slides[index].isProcessing = true;
          this.slides[index].imageUrl = "assets/images/Line-Preloader.gif";
          this.authorService.getAuthorPhoto(imageObj.authorProfilePhotoId).subscribe((result: any) => {
            this.slides[index].isProcessing = false;
            if (result.assetBase64 == null) {
              this.slides[index].imageUrl = defaultImageURL;
            } else {
              this.slides[index].imageUrl = 'data:image/jpg;base64,' + result.assetBase64;
            }
            // this.updateAuthorData(this.slides[index]);
          }, err => {
            this.slides[index].isProcessing = false;
            this.slides[index].imageUrl = defaultImageURL;
          });
        }
      }

    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}



