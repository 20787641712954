<app-header></app-header>

<div class="about-you">
    <section class="about-you-sec my-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link  border-0 px-2 py-12"
                                [ngClass]="{'active': selectedTab === 'aboutyou'}" id="about-you-tab" type="button"
                                role="tab" aria-controls="home" (click)="gaEvent('About You')"
                                [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'questionnaire','aboutyou']"
                                aria-selected="true">About You</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link border-0 px-2 py-12"
                                [ngClass]="{'active': selectedTab === 'aboutbook'}" id="about-your-book-tab"
                                type="button" role="tab" aria-controls="profile" (click)="gaEvent('About Your Book')"
                                [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'questionnaire','aboutbook']"
                                aria-selected="false">About Your Book</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent" *ngIf="settings.length > 0">
                        <div class="tab-pane fade content px-3 px-md-4 py-2"
                            [ngClass]="{'active show': selectedTab === 'aboutyou'}" id="about-tab" role="tabpanel"
                            aria-labelledby="about-you-tab">
                            <ng-container *ngIf="selectedTab === 'aboutyou'">
                                <app-about-you [settings]="settings" (saveSection)="saveSection($event)">
                                </app-about-you>
                            </ng-container>

                        </div>
                        <div class="tab-pane fade content px-3 px-md-4 py-2"
                            [ngClass]="{'active show': selectedTab === 'aboutbook'}" id="about-book-tab" role="tabpanel"
                            aria-labelledby="about-your-book-tab">
                            <ng-container *ngIf="selectedTab === 'aboutbook'">
                                <app-about-book [settings]="settings" (saveSection)="saveSection($event)"></app-about-book>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<app-footer></app-footer>