<app-header></app-header>

<div class="author-guide">
    <section class="my-4">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="px-3 px-md-5 py-2 author-illustrator">
                        <h4 class="back-line-txt text-center my-3">
                            <span>GUIDE FOR AUTHORS</span>
                        </h4>
                        <div class="row">
                            <div class="col-md-12 mb-3 mb-xl-0">
                                <div class="d-md-flex d-block">
                                    <div class="flex-shrink-0 text-md-start text-center mb-3">
                                        <div class="zoom-effect user-profile-pic m-md-0 m-auto h-auto">
                                            <a class="thumbnail" (click)="downloadEPUBFile()" target="_blank">
                                                <img alt="" src="assets/images/author-guide-cover.png" class="fix-height">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-md-3 ms-0 ps-0 ps-md-4 pe-0 pe-md-3 text-md-start text-center">
                                        <p class="lh-base mb-2">Welcome to the HBG Guide for Authors, where you can learn about all the steps in the publishing
                                            process from manuscript to finished book and find answers to authors’ most frequently
                                            asked questions.</p>
                                        <p class="lh-base mb-2">
                                            <a target="_blank" (click)="downloadEPUBFile()">Here you’ll find a digital version of the Guide for Authors,</a> as well as a timeline of key publishing dates.</p>
                                        <p class="lh-base mb-2">First-time authors, we strongly recommend you read the guide in full — it will answer
                                            many of the questions you may have, and it includes resources and tips you can
                                            use throughout the process. For published authors, the Guide for Authors is a terrific
                                            resource for you to get a refresher on any part of the publishing process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="my-book-bg pt-4 pb-0 pb-md-5 position-relative">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center mt-3">
                    <h1>Hachette Book Group Publication Process</h1>
                    <p class="offset-lg-2 lh-base text-lg-start text-md-center">This general Publication Timeline illustrates the many steps typically involved in launching a book. Actual <br> timeframes may vary from book to book. You’ll find additional information, with even more detail in our <br> HBG Guide for Authors.</p>
                </div>
            </div>
            <div class="hachette-book-step px-3">
                <div class="row">
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-1.svg" alt="">
                        <h1 class="my-2">Step 1</h1>
                        <h6 class="lh-base">Manuscript Delivered, Revised with Editor</h6>
                        <span class="w-100 d-block lh-lg mb-2">10-12 Months Prepub.</span>
                        <p class="text-start lh-base">This is when your
                            manuscript is delivered, discussed, and revised with your editor before moving on to
                            copyediting. Our Art department begins
                            designing the book jacket and our
                            Sales, Marketing, and Publicity groups
                            hold preliminary meetings with retailers and media outlets.</p>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-2.svg" alt="">
                        <h1 class="my-2">Step 2</h1>
                        <h6 class="lh-base">Manuscript Copyedited, Cover Design and Marketing Plans Set</h6>
                        <span class="w-100 d-block lh-lg mb-2">7-9 Months Prepub.</span>
                        <p class="text-start lh-base">After you review your copyedited manuscript, page proofs are composed for further review. The cover design is set, and marketing and publicity plans are drawn up and shared with the editorial team and author. Your publishing team will formally present the book, marketing and publicity plans to the sales team at Sales Conference. Presentations to retailers take place at this stage as well.
                        </p>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-3.svg" alt="">
                        <h1 class="my-2">Step 3</h1>
                        <h6 class="lh-base">Manuscript Proofread, Outreach to Media and Booksellers</h6>
                        <span class="w-100 d-block lh-lg mb-2">4-6 Months Prepub.</span>
                        <p class="text-start lh-base">At this point, your manuscript is nearly finalized. A final proofread of the revised page proofs takes place, along with flap and back cover material. Bound and digital galleys are created, and our publicity teams begin their work in earnest, sending galleys and pitch materials to the media and setting up interviews and appearances.
                        </p>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-4.svg" alt="">
                        <h1 class="my-2">Step 4</h1>
                        <h6 class="lh-base">Retailers Place Orders, Online and Social Media Campaigns Launch</h6>
                        <span class="w-100 d-block lh-lg mb-2">3 Months Prepub.</span>
                        <p class="text-start lh-base">The final jackets and covers are approved and sent for printing.
                            Retailers place their orders, and our publicists begin online and
                            social media campaigns along with additional media outreach tailored to you and the content of your
                            book.
                        </p>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-5.svg" alt="">
                        <h1 class="my-2">Step 5</h1>
                        <h6 class="lh-base">Books Bound and Shipped to the Warehouse, eBook Produced</h6>
                        <span class="w-100 d-block lh-lg mb-2">2 Months Prepub.</span>
                        <p class="text-start lh-base">All final materials are sent to the printer at this point, and the
                            first printing quantity is set. The books are bound and shipped to our warehouse, and
                            ebooks are produced for simultaneous publication with the print edition.</p>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-6.svg" alt="">
                        <h1 class="my-2">Step 6</h1>
                        <h6 class="lh-base">Publicity Pitches and Social Media Activity Continue</h6>
                        <span class="w-100 d-block lh-lg mb-2">1 Month Prepub.</span>
                        <p class="text-start lh-base">Your book arrives in the warehouse. You receive
                            finished copies, and press materials along with copies of the book are
                            sent to media outlets for reviews. Print and ebook versions are
                            distributed
                            to retailers in advance of the book's
                            on-sale date.
                        </p>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-7.svg" alt="">
                        <h1 class="my-2">Step 7</h1>
                        <h6 class="lh-base">Initial Formats On-Sale, Consumer Marketing Rolls Out</h6>
                        <span class="w-100 d-block lh-lg mb-2">On-Sale Date</span>
                        <p class="text-start lh-base">Online and social media marketing for your book is officially rolled out, along with the interviews and appearances your publicity team has secured. Most publicity coverage will likely occur in the first few weeks after your book goes on sale.</p>
                    </div>
                    <div class="col-lg-3 col-md-6 text-center mb-4">
                        <img src="assets/images/step-8.svg" alt="">
                        <h1 class="my-2">Step 8</h1>
                        <h6 class="lh-base">Keeping Track of Your Book’s Performance</h6>
                        <span class="w-100 d-block lh-lg mb-2">Post-Launch</span>
                        <p class="text-start lh-base">Once your book is released, we begin tracking the sales and monitoring our inventory. You will also be able to track your book’s performance via the author portal or by reaching out to your editor for specific information. Meanwhile, the publicity and marketing support continues for up to 3-4 months, until the next season launches. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<app-footer></app-footer>