import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { BannerService } from '../service/banner.service';
import { UtilitiesService } from '../service/utilities.service';
import { authorGuideConstants } from '../shared/constants/authorGuideConstants';

@Component({
  selector: 'app-author-guide',
  templateUrl: './author-guide.component.html',
  styleUrls: ['./author-guide.component.scss']
})
export class AuthorGuideComponent implements OnInit {
  authorGuideUrl: string = '';
  guideConstants;

  constructor(private sanitizer: DomSanitizer, private bannerService: BannerService, private utilityService: UtilitiesService) {
  }

  ngOnInit(): void {
    //this.authorGuideUrl = environment.authorGuideUrl;
    this.guideConstants = authorGuideConstants;
  }

  getAuthorGuideFile() {
    this.bannerService.getAuthorGuideFileAsBase64(environment.authorGuideUrl).subscribe((res) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(res);
      reader.onload = (event: any) => {
        this.authorGuideUrl = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(this.getGuideFileURL(event.target.result)));
        this.utilityService.hideLoader();
        window.open(this.authorGuideUrl);
      }
    }, err => {
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
      this.utilityService.hideLoader();
    })
  }
  openAuthorGuideFile() {
    if (this.authorGuideUrl == '') {
      this.utilityService.showLoader();
      this.getAuthorGuideFile();
    }
    else {
      window.open(this.authorGuideUrl);
    }

  }
  getGuideFileURL(data: any) {
    var file = new Blob([(data)], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    return fileURL;
  }
  downloadEPUBFile() {
    this.utilityService.showLoader();
    var file = environment.pluginUrl + "/welcome?p_p_id=epubportlet_WAR_epubportlet&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_resource_id=getEPUBFile&p_p_cacheability=cacheLevelPage&p_p_col_id=column-1&p_p_col_count=5";
    // var file = "https://authorportaldev.hbgusa.com/welcome?p_p_id=epubportlet_WAR_epubportlet&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_resource_id=getEPUBFile&p_p_cacheability=cacheLevelPage&p_p_col_id=column-1&p_p_col_count=5"
    this.bannerService.downloadAuthorGuideFile(file).subscribe(res => {
      var headerData = res;
      const blob = this.converBase64toBlob(headerData.data, 'octet/stream');
      var url = window.URL.createObjectURL(blob);
      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "HBG_Author_Guide.pdf";
      a.click();
      window.URL.revokeObjectURL(url);
      this.utilityService.hideLoader();
    }, err => {
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
      this.utilityService.hideLoader();
    })
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content);
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    });
    return blob;
  }

}
