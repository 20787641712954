export const authordesc = {
    description: 'Complete this questionnaire and keep it current in order' +
    ' to help us craft the best marketing and publicity strategies for you ' +
    'and your book. Your Author and Illustrator Questionnaire includes essential information about' +
    ' your background, publishing history, social media, key contacts and other details' +
    ' the HBG team uses to promote your book in an effective and authentic way. A' +
    ' thorough Author and Illustrator Questionnaire is vital to your success.',
    shortDescription: 'Fill out your AQ and keep it current in order to help your HBG ' +
    ' Team craft the best marketing and publicity strategies for your book.',
    agentDescription: 'In the Agent Portal, you can access a range of resources and ' +
    'information. Scroll below to view your Author’s sales at-a-glance or click ' +
    'the “Sales and Royalties” tab for detailed information. You can also see your Author’s ' +
    'team at HBG, view book details, and read up on HBG news. Note: sales info will ' +
    'begin appearing after the on-sale date, and the subrights and royalties ' +
    'pages will appear once there is final information to display.',
    authorBlurbTitlePostFix: '\'s Author and Illustrator Questionnaire',
    agentRole: 'agent'
  };

