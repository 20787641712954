import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private $gaService: GoogleAnalyticsService, private session: SessionService
    ) { }

  public eventEmitter(eventAction: string, eventCategory: string, eventLabel: string, eventValue: number = -1) {
    this.$gaService.event(eventAction, eventCategory, eventLabel, eventValue);
  }

  public pageView(eventAction: string, eventCategory: string) {
    let userId = null;
    if(this.session.isLoggedIn()) {
      userId = this.session.getCurrentUserId();
    }

    eventAction = 'AP - ' + eventAction;
    this.$gaService.pageView(window.location.hash.toString().replace('#',''), eventAction, eventCategory, {
      user_id: userId
    });
  }

  public setUserId(userId: number) {
    this.$gaService.set('userId',userId);
  }
  

  
}