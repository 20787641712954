<div class="terms-of-use">
    <div class="modal-header border-bottom-0">
      <h2 class="modal-title mx-auto">
        Terms of Use
      </h2>
    </div>
    <div class="modal-body">
      <div id="terms-and-conditions-text" class="iframe-container">
        <iframe width="100%" height="100%" frameborder="0" [src]="termsofUseUrl">
        </iframe>
      </div>
    </div>
    <div class="modal-footer text-center">
      <button class="btn btn-theme-blue mx-auto px-5" type="button" (click)="activeModal.close()" tabindex="-1">
        Close
      </button>
    </div>
  </div>