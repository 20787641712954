import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SelectAuthorComponent } from './shared/select-author/select-author.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderComponent } from './shared/loader/loader.component';
import { ChooseDiffBookModalComponent } from './shared/choose-diff-book-modal/choose-diff-book-modal.component';
import { AuthorGuideComponent } from './author-guide/author-guide.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { ViewAuthorComponent } from './shared/view-author/view-author.component';
import { AboutBookComponent } from './questionnaire/about-book/about-book.component';
import { AboutYouComponent } from './questionnaire/about-you/about-you.component';
import { NewsComponent } from './news/news.component';
import { MstrViewComponent } from './dashboard/mstr-view/mstr-view.component';
import { SalesComponent } from './sales/sales.component';
import { SalesGlanceComponent } from './sales/sales-glance/sales-glance.component';
import { AuthorComponent } from './login/author/author.component';
import { AgentComponent } from './login/agent/agent.component';
import { SalesTabComponent } from './sales/sales-tab/sales-tab.component';
import { RoyaltiesTabComponent } from './sales/royalties-tab/royalties-tab.component';
import { SubrightsTabComponent } from './sales/subrights-tab/subrights-tab.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule, MatFormFieldControl } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { PublishingGroupsComponent } from './publishing-groups/publishing-groups.component';
import { AuthInterceptor } from './service/http-with-injector/http-interceptor';
import { WelcomeBannerComponent } from './dashboard/welcome-banner/welcome-banner.component';
import { NewsWidgetComponent } from './dashboard/news-widget/news-widget.component';
import { HbgTeamComponent } from './dashboard/hbg-team/hbg-team.component';
import { NewsArchiveComponent } from './news/news-archive/news-archive.component';
import { NewsArticleComponent } from './news/news-article/news-article.component';
import { BookDetailsComponent } from './questionnaire/book-details/book-details/book-details.component';
import { TeamMemberProfileComponent } from './dashboard/team-member-profile/team-member-profile.component';
import { NewsRightPanelComponent } from './news/news-right-panel/news-right-panel.component';
import { SearchFilterPipe } from './shared/pipe/search-filter.pipe';
import { TermsOfUseComponent } from './login/terms-of-use/terms-of-use.component';
import { ModalComponent } from './directives/modal.component';
import { DialogInformationComponent } from './modalDialog/dialog-information/dialog-information.component';
import { TruncatePipe } from './truncate.pipe';
import { FaqComponent } from './faq/faq.component';
import { ContactsComponent } from './questionnaire/contacts/contacts.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { DialogSuccessComponent } from './modalDialog/dialog-success/dialog-success.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component'; 
import { MinusSignToParens } from './directives/minus-sign-to-parens.pipe';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    DialogInformationComponent,
    SelectAuthorComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ChooseDiffBookModalComponent,
    AuthorGuideComponent,
    AboutYouComponent,
    QuestionnaireComponent,
    ViewAuthorComponent,
    AboutBookComponent,
    NewsComponent,
    MstrViewComponent,
    SalesComponent,
    SalesGlanceComponent,
    AuthorComponent,
    AgentComponent,
    SalesTabComponent,
    RoyaltiesTabComponent,
    SubrightsTabComponent,
    PublishingGroupsComponent,
    WelcomeBannerComponent,
    NewsWidgetComponent,
    HbgTeamComponent,
    NewsArchiveComponent,
    NewsArticleComponent,
    BookDetailsComponent,
    TeamMemberProfileComponent,
    NewsRightPanelComponent,
    SearchFilterPipe,
    TermsOfUseComponent,
    TruncatePipe,
    FaqComponent,
    ContactsComponent,
    ChangePasswordComponent,
    DialogSuccessComponent,
    SalesDashboardComponent,
    MinusSignToParens
  ],
  imports: [
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SlickCarouselModule,
    Ng2SearchPipeModule,
    HttpClientModule,
    NgSelectModule,
    AutocompleteLibModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    BrowserAnimationsModule,
    NgxPageScrollCoreModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerKey,
    }),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: 'googleTagManagerId',  useValue: environment.googleTagManagerKey},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
