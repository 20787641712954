export const questionnaireConstants = {
    aboutYouSettingKey: 'ABOUT_AUTHOR_QUESTIONNAIRE_ID',
    aboutYourBookSettingKey: 'ABOUT_BOOK_QUESTIONNAIRE_ID',
    aboutBookScopeType: 'Work',
    quoteLength: 65,
    formElementLong: 'long_name',
    formElementShort: 'short_name',
    days: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    maxYears: 110,
    baseYear: 1900,
    relation: [
        'Father',
        'Mother',
        'Daughter',
        'Son',
        'Spouse',
        'Brother',
        'Sister'
    ],
    contactSections: [
        {
            title: 'Blurber Contacts',
            contactType: 'Blurber',
            heading: 'HBG wants to help you activate your own network of contacts. ' +
                'Launching your book is a great time to call in favors. ' +
                'Please spend some time considering contacts you have who might help.',
            description: '<b>Blurbers:</b> Please list appropriate people to whom we might send bound galleys for advance' +
                ' comment (authors, prominent people, specialists in your field, etc.). Please indicate if you know' +
                ' someone personally and provide mailing addresses and social media information.'
        },
        {
            title: 'Media Contacts',
            contactType: 'Media',
            heading: 'HBG wants to help you activate your own network of contacts. ' +
                'Launching your book is a great time to call in favors. ' +
                'Please spend some time considering contacts you have who might help.',
            description: '<b>Media Contact:</b> Please list any media contacts (reviewers, columnists,' +
                ' broadcasters, bloggers) with whom you have a personal connection. Please provide mailing' +
                ' addresses and social media information.'
        },
        {
            title: 'Influencer Contacts',
            contactType: 'Influencer',
            heading: 'HBG wants to help you activate your own network of contacts. ' +
                'Launching your book is a great time to call in favors. ' +
                'Please spend some time considering contacts you have who might help.',
            description: '<b>Influencers:</b> Please list any thought leaders or tastemakers in your field who should be sent' +
                ' copies of the finished book for word-of-mouth purposes. Think broadly here. An influencer can' +
                ' be anyone from a librarian you know personally to a CEO who invited you to speak at a company.' +
                ' They could also be leaders of book clubs you have visited or creatives in related fields.' +
                ' Please indicate if you know someone personally. Please provide mailing addresses and, if' +
                ' applicable, social media information.'
        }
    ],
    cityVar: 'locality',
    stateVar: 'administrative_area_level_1',
    countryVar: 'country',
    zipVar: 'postal_code',
    emailValidationPattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
    numberOnlyValidation: new RegExp('^\\d+$'),
    newContact: {
        apartmentDetails: "",
        city: "",
        companyName: "",
        contactId: 0,
        contactName: "",
        // contactType: "Blurber",
        country: "",
        emailAddress: "",
        mailingAddress: "",
        officePhone: 0,
        // questionnaireId: 31265,
        relationship: "",
        // scopeId: 9780316445382,
        scopeType: "Work",
        socialMediaUrl: "",
        state: "",
        // userId: 4076002,
        userName: "",
        zip: "",
        isNew: true

    }
};