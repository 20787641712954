export const bookSelectorConstants: any = {
    empty_isbn: {
        'author': 'No Author',
        'isbn': '',
        'title': 'No Products Available',
        'products': [
            {
                'productDesc': 'none',
                'byLine': 'No Author',
                'editionDesc': '',
                'productIsbn': '',
                'onSaleDate': '0001-01-01',
                'subProductCodeDesc': ''
            }
        ]
    },
    default_isbns: {
        dashboard: [],
        sales: [],
        royalties: [],
        subrights: [],
        compare: []
    },
    large_selector_threshold: 4,
    max_visible: 5,
    onix_sales_window: 8,
    events: {
        ISBNS_LOADED: 'finished loading isbns.'
    },
    bookTitleCharacterLength: 40,
    bookTitleCharacterEllipsis: '...',
}