<section class="book-sec-main hbg-news m-0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h5 class="background">
                    <span class="bg-white">HBG NEWS</span>
                </h5>
                <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'newsArchive']"
                    class="more-news">MORE NEWS
                    <img class="ms-1" src="assets/images/arrow-right.png" alt=""> </a>
            </div>
        </div>
        <div class="row mt-3 col-container">
                <div class="col-md-4 col-xs-12 px-4 mb-3" *ngFor="let feed of feeds| slice:0:3;">
                <div class="zoom-effect news-pic d-inline-block">
                    <img (click)="gaClick(feed.articleId)" src="{{feed.featuredImage}}"
                        class="w-100 fix-height cursor-pointer" alt=""
                        [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'newsArticle', feed.articleId]" onerror="this.style.display='none'">
                </div>
                <a class="font-large fs-5 lh-base text-decoration-none" (click)="gaClick(feed.articleId)"
                    [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'newsArticle', feed.articleId]">{{feed.title}}</a>
            </div>
        </div>
    </div>
</section>