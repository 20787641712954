import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { SelectAuthorComponent } from '../select-author/select-author.component';
import {
  find as _find
} from "lodash-es";
import { AuthorService } from 'src/app/service/author.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { SessionService } from 'src/app/service/session.service';

@Component({
  selector: 'app-view-author',
  templateUrl: './view-author.component.html',
  styleUrls: ['./view-author.component.scss']
})
export class ViewAuthorComponent implements OnInit {
  subscription: any;
  defaultSubscription: any;
  selectedAuthor: string;
  authors: any = [];
  selectedAuthorData: any = {};
  keyword = 'fullName';

  constructor(public sessionService: SessionService,public authService: AuthService, private router: Router, public modalService: NgbModal, private analyticsService : AnalyticsService,
    private utilityService: UtilitiesService, private authorService: AuthorService, private activatedRoute: ActivatedRoute) {

    this.defaultSubscription = this.authService.defaultselectedAuthor.subscribe(result => {
      this.selectedAuthor = this.authService.getSelectedAuthor();;
    });

    this.subscription = this.authService.selectedAuthor.subscribe(result => {
      this.selectedAuthor = this.authService.getSelectedAuthor();;
    });
  }

  ngOnInit(): void {
    this.getAllAuthor();
  }

  getAllAuthor() {
    this.authorService.getAuthors().subscribe((result: any) => {
      this.authors = result;
      this.selectedAuthor = this.authService.getSelectedAuthor();
    }, err => {
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  selectAuthor() {
    this.sessionService.questionnaireChangesOnLogout();
    const modalRef = this.modalService.open(SelectAuthorComponent, {
      centered: true,
      size: "md",
      windowClass: "animated fadeIn"
    });
    modalRef.result.then(
      result => {
        if (result) {

        }
      }
    );
  }

  authorChange(event: any) {
    var selectedAuthor = _find(this.authors, { 'authorProfileId': parseInt(this.selectedAuthor) })
    this.analyticsService.eventEmitter('Author Selected Name', window.location.href, selectedAuthor.fullName);
    if (selectedAuthor && selectedAuthor.authorProfileId) {
      let defaultURL = "assets/images/author-user-image-default.svg";
      localStorage.setItem('selectedAuthor', selectedAuthor.authorProfileId);

      // this.selectedAuthor = _find(this.authors, { 'authorProfileId': selectedAuthor.authorProfileId });

      this.setSelectedAuthorInLocalStorage(selectedAuthor);

    }
    this.router.navigate(['navigation', this.selectedAuthor, 'dashboard']);
    // this.router.navigate(['navigation', id, 'dashboard'], { relativeTo: this.activatedRoute });
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  setSelectedAuthorInLocalStorage(selectedAuthor) {
    localStorage.setItem('selectedAuthorData', JSON.stringify(selectedAuthor));
    // this.authService.changeSelectedAuthor();
  }
  authorClick(){
    this.sessionService.questionnaireChangesOnLogout();
  }

  onKeydown(event) {
    if (event.key == ("ArrowUp") || event.key == ("ArrowDown")|| event.key == ("ArrowRight") || event.key == ("ArrowLeft")) {
      event.preventDefault();
    }
  }
  
}
