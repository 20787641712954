import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilitiesService } from 'src/app/service/utilities.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loader: boolean = false;
  subscription: Subscription;

  constructor(public utilityService: UtilitiesService) {
    this.subscription = this.utilityService.loader.subscribe(result => {
      setTimeout(() => {
        this.loader = result ? true : false;  
      }, 10);      
    });
  }

  ngOnInit(): void {
  }

}
