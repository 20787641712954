import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { AuthorService } from 'src/app/service/author.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { AuthService } from '../../service/auth.service';
import { bookSelectorConstants } from '../../shared/constants/bookSelectorConstants';

@Component({
  selector: 'app-choose-diff-book-modal',
  templateUrl: './choose-diff-book-modal.component.html',
  styleUrls: ['./choose-diff-book-modal.component.scss']
})
export class ChooseDiffBookModalComponent implements OnInit {
  @Input()
  authorWorks: any = [];
  @Output() 
  passEntry: EventEmitter<any> = new EventEmitter();

  searchBook: string;
  hideSearch = false;
 
  constructor(public authService: AuthService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private analyticsService : AnalyticsService,
    public utilityService: UtilitiesService,
    public authorService: AuthorService
  ) {  }

  ngOnInit(): void {
    this.hideSearch = this.authorWorks.length <= bookSelectorConstants.max_visible;
  }

  selectBook(selectedBook: any) {
    this.analyticsService.eventEmitter('Choose different Book', window.location.href, selectedBook.title);
    this.analyticsService.eventEmitter('Choose different Book', window.location.href, selectedBook.isbn);
    this.passEntry.emit(selectedBook);
    this.activeModal.close();
  }



}
