<div class="tab-inner-content p-4 pt-0">
    <h4 class="back-line-txt text-center my-3"><span>AUTHOR AND ILLUSTRATOR
            QUESTIONNAIRE</span></h4>
    <div class="row author-info">
        <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
            <div class="d-md-flex d-block">
                <div class="flex-shrink-0 text-md-start text-center mb-3">
                    <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                        <img alt="" class="fix-height" src="{{authorImageUrl}}">
                    </div>
                </div>
                <div class="flex-grow-1 ms-md-3 ms-0 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                    <h3 class="author-title"><em>{{selectedAuthorData?.fullName}}'s</em> <br> Author and
                        Illustrator <br> Questionnaire</h3>
                    <p>Fill in your biographical details, social media handles,
                        and contact info in
                        the fields below and help us craft the best marketing and publicity
                        strategies for your books.
                        If any of your info changes, you can update your AQ at any time.</p>
                    <span class="download-pdf d-block">To download Author and Illustrator Questionnaire responses
                        (PDF),
                        click the "
                        <a (click)="gaClick('About Your Book')"
                            [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'questionnaire','aboutbook']"><b><u>About
                                    Your Book</u></b></a>" tab.</span>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-xl-6 ps-xl-2 ps-md-4 pe-lg-0">
            <div class="px-md-3">
                <h3 class="mb-4">Your Author and Illustrator Questionnaire: A Key Tool</h3>
                <p class="author-text-blur pe-5">Why spend the time on filling out your Author and Illustrator
                    Questionnaire?
                    The AQ is essential to your book’s success. Be sure to fill yours out in detail.
                    Here’s why:</p>
                <div class="author-tick pe-5">
                    <ul class="tick-list">
                        <li>A great AQ helps us identify as many of your potential readers as possible.</li>
                        <li>HBG’s publicity, marketing, and sales staff use information from your AQ to devise smart
                            strategies for promoting your book and getting it into as many retail channels as possible.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5 tab-info-details">
        <div class="accordion accordion-flush tab-info-details" id="accordionFlushExample">
            <div class="accordion-item border mb-4" *ngFor="let authque of aboutBookQuestionnaires; let i = index">
                <h2 class="accordion-header" id="base{{authque?.categoryId}}" (click)="gaClick(authque?.name)">
                    <button class="accordion-button  px-3 py-2 " type="button" data-bs-toggle="collapse"
                        [class.collapsed]="i != 0" [attr.data-bs-target]="'#main' + authque?.categoryId"
                        aria-expanded="false" [attr.aria-controls]="'main' + authque?.categoryId">
                        {{authque?.name}}
                    </button>
                </h2>
                <div id="main{{authque?.categoryId}}" class="accordion-collapse collapse" [class.show]="i == 0"
                    [attr.aria-labelledby]="'base'+ authque?.categoryId" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <!-- <span *ngIf="showText" class="alert-msg w-100 fw-bolder mb-4 d-block">Please remember to use the
                            “save”
                            button at the bottom of this form to save your changes before leaving this section of the QA
                            !!!</span> -->
                        <form #f="ngForm" novalidate>
                            <div class="questions bootstrap-material-design"
                                *ngFor="let ques of authque.questions; let i=index;">
                                <label *ngIf="ques.questionType !== 'checkbox'"
                                    [ngClass]="{'black-label px-2': ques.questionType === 'label','px-4': ques.questionType != 'label'}">
                                    <i *ngIf="ques.icon && ques.icon !== 'none'" class="label-icon"
                                        style="background:url(app/assets/icons/{{ques.icon}}.svg) no-repeat 0 0"></i>
                                    <ng-container class="pull-left me-2" *ngIf="authque.serialNumberEnable">
                                        {{ques?.order|| ques?.counter}}
                                    </ng-container>

                                    {{ques.questionText}}
                                </label>
                                <span
                                    *ngIf="ques.questionType === 'email' && f.controls['email'+i] && f.controls['email'+i].invalid && f.controls['email'+i].dirty && ques.answers.answerText"
                                    class="red alert-message ms-3">Please enter a valid email address.</span>
                                <div *ngIf="ques.questionType !== 'checkbox'">
                                    <mat-form-field class="w-100 border-0" *ngIf="ques.questionType === 'text'"
                                        appearance="legacy">
                                        <input matInput [disabled]="!canEdit" name="username{{i}}"
                                            [(ngModel)]="ques.answers.answerText" (input)="textKeyPress()"
                                            (change)="selectChange(ques)">
                                    </mat-form-field>
                                    <mat-form-field class="w-100 border-0" *ngIf="ques.questionType === 'email'"
                                        appearance="legacy">
                                        <input matInput [disabled]="!canEdit" name="email{{i}}"
                                            [(ngModel)]="ques.answers.answerText" (change)="selectChange(ques)"
                                            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
                                    </mat-form-field>
                                    <mat-form-field class="w-100 border-0" *ngIf="ques.questionType === 'textarea'"
                                        appearance="legacy">
                                        <textarea maxlength="9980" rows="{{ques.answers.rowCount}}"
                                            [disabled]="!canEdit" (input)="textKeyPress()" (change)="selectChange(ques)"
                                            [(ngModel)]="ques.answers.answerText" name="textarea{{i}}"
                                            matInput></textarea>
                                    </mat-form-field>
                                    <ng-container *ngIf="ques.questionType === 'radio'">
                                        <mat-radio-group name="{{i}}_radio" [(ngModel)]="ques.answers.answerText">
                                            <mat-radio-button class="ms-5 d-block"
                                                *ngFor=" let option of ques.optionValues.split('\n'); let i_index = index"
                                                [disabled]="!canEdit" value="{{ option }}">{{
                                                option }}</mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                    <ng-container>
                                        <mat-form-field class="w-100 mb-3" *ngIf="ques.questionType === 'select'"
                                            appearance="legacy">
                                            <mat-select [(ngModel)]="ques.answers.answerText" name="answerText{{i}}">
                                                <mat-option [disabled]="!canEdit"
                                                    *ngFor=" let option of ques.optionValues.split('\n'); let i_index = index"
                                                    [disabled]="!canEdit" value="{{ option }}">
                                                    {{ option }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- <mat-form-field *ngIf="ques.questionType === 'address'" class="w-100 border-0"
                                            appearance="legacy">
                                            <input matInput [disabled]="!canEdit" g-places-autocomplete
                                                [(ngModel)]="ques.answers.answerText" name="answerText{{i}}"
                                                ng-blur="$ctrl.setAddress( ques.answers, i, section )"
                                                options="$ctrl.autocompleteOptions" (change)="selectChange(ques)" />                                                
                                        </mat-form-field> -->

                                        <!-- (focusout)="setAddress($event,i,ques)"  -->
                                        <mat-form-field *ngIf="ques.questionType === 'address'" class="w-100 border-0"
                                            appearance="legacy">
                                            <input matInput [disabled]="!canEdit" ngx-google-places-autocomplete
                                                value="{{ques.answers.answerText}}" [options]='options'
                                                #placesRef="ngx-places"
                                                (onAddressChange)="handleAddressChange($event, ques.answers)"
                                                (input)="handleAddressChange($event, ques.answers)" />
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                                <div *ngIf="ques.questionType === 'date'" class="row">
                                    <div class="col-md-2 col-xs-4 no-gutter">
                                        <div class="form-group">
                                            <select [disabled]="!canEdit"
                                                class="w-100 form-control border-0 border-bottom rounded-0 py-2 bg-transparent"
                                                abm-form-control [(ngModel)]="ques.month" (change)="selectChange(ques)"
                                                name="month">
                                                <option selected value="undefined"> Month </option>
                                                <option *ngFor=" let month of months" value="{{month}}">{{month}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-xs-4">
                                        <div class="form-group">
                                            <select [disabled]="!canEdit"
                                                class="w-100  form-control border-0 border-bottom rounded-0 py-2 bg-transparent"
                                                abm-form-control [(ngModel)]="ques.day" (change)="selectChange(ques)"
                                                name="day">
                                                <option selected value="undefined"> Day </option>
                                                <option *ngFor=" let day of days" value="{{day}}">{{day}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-xs-4">
                                        <div class="form-group">
                                            <select [disabled]="!canEdit"
                                                class="w-100 form-control border-0 border-bottom rounded-0 py-2 bg-transparent"
                                                abm-form-control [(ngModel)]="ques.year" (change)="selectChange(ques)"
                                                name="year">
                                                <option selected value="undefined"> Year </option>
                                                <option *ngFor=" let year of years" value="{{year}}">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div *ngIf="ques.questionType === 'checkbox'">
                                    <mat-checkbox class="ms-5 matCheckboxClass" [disabled]="!canEdit" name="chk{{i}}"
                                        [ngModel]="getAnswerTextAsBoolean(ques)"
                                        (ngModelChange)="setAnswerText(ques, $event)" (change)="selectChange(ques)">
                                        {{ ques.questionText }}
                                    </mat-checkbox>

                                </div>
                                <ng-container *ngIf="ques.questionType === 'multi-checkbox'">
                                    <ng-container
                                        *ngFor="let option of ques.optionValues.split('\n'); let i_index = index">
                                        <mat-checkbox class="ms-5 matCheckboxClass d-flex" [disabled]="!canEdit"
                                            (change)="updateCheckBoxQuesType(ques,$event)"
                                            [checked]="ques?.answers?.answerText?.length ? isCheckBoxChecked(ques?.answers?.answerText,option) : ''"
                                            name="multi-chk{{i_index}}" value="{{option}}">
                                            {{ option }}
                                        </mat-checkbox>
                                    </ng-container>
                                </ng-container>

                            </div>
                            <div class="d-flex">
                                <div class="mb-0 ">
                                    <button type="button" (click)="save(authque,f,'0',i,'saveAll')"
                                        [disabled]="!canEdit"
                                        class="btn btn-save btn-theme-blue my-2 my-md-0 me-2">Save</button>
                                </div>
                                <div>
                                    <p class="mb-0 ps-3"><b>This form auto-saves every 3 minutes.</b></p>
                                    <p class="last-saved ml-3 d-inline-block" *ngIf="authque.modifiedDate">Last saved at
                                        {{ authque.modifiedDate | date : 'shortTime'}} on {{ authque.modifiedDate | date
                                        :
                                        'fullDate'}}
                                        <!-- {{
                                        authque.modifiedDate | date : 'shortTime'}} on {{ authque.modifiedDate | date :
                                        'fullDate'}} -->
                                    </p>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>