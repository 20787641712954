import { Component, Input, OnInit } from '@angular/core';
import { BookSelectorService } from 'src/app/service/book-selector.service';
import { SessionService } from 'src/app/service/session.service';
import { salesConstants } from 'src/app/shared/constants/salesConstants';
import { environment } from 'src/environments/environment';
import * as _ from "lodash-es";
import { PermissionService } from 'src/app/service/permission.service';
import { AuthService } from 'src/app/service/auth.service';
import { AnalyticsService } from 'src/app/service/analytics.service';

@Component({
  selector: 'app-sales-glance',
  templateUrl: './sales-glance.component.html',
  styleUrls: ['./sales-glance.component.scss']
})
export class SalesGlanceComponent implements OnInit {
  pagePermission: any;
  authorProfileId;

  constructor(private bookSelectorService: BookSelectorService, private session: SessionService, private permissionService: PermissionService,
            private authService : AuthService, private analyticsService: AnalyticsService) {
    this.pagePermission = this.permissionService.getPagePermission();
    // this.subscription = this.authService.selectedAuthor.subscribe(result => {
    //   this.bindAuthorData();
    //   this.getBooks();
    // });
  }

  ngOnInit(): void {
    // this.getUrl();
    this.authorProfileId = this.authService.getSelectedAuthor();

  }

  public previousWork: any;
  public lastToken: any;
  public checkForToken: boolean = true;
  public url = environment.msSalesGlanceUrl + environment.auth;
  @Input()
  public msUrl: any;
  public isbn: any = '';
  public display: boolean = false;

  getUrl = function () {
    var currentWork = this.bookSelectorService.selectedIsbns.dashboard[0],
      newToken = this.session.getMSTRSessionId(),
      setUrl = function (token) {
        // this.msUrl = this.display ?
        //   $sce.trustAsResourceUrl(
        //     url.replace(':primary', currentWork.isbn).replace(':authToken', token)) : '';
        this.msUrl = this.display ?
          this.sanitizer.bypassSecurityTrustResourceUrl(
            this.msURL.replace(':primary', currentWork.isbn).replace(':authToken', token)) : '';
      };

    if (this.previousWork !== currentWork && _.isPlainObject(currentWork)) {
      this.previousWork = currentWork;

      // make sure author ids match before displaying dashboard
      this.display =
        _.some(currentWork.products, [salesConstants.hasSales, 'Y']) && this.session.getCurrentAuthor ===
        currentWork.authorId;
      // && salesService.checkPermission({
      //   isbn: currentWork.isbn,
      //   action: 'sales_data',
      //   items: this.myPermissions.permissionItems,
      //   actions: this.resourceActions
      // });

      this.isbn = currentWork.isbn;

      if (!this.checkForToken) {
        setUrl(this.lastToken);
      }
    }

    if (this.checkForToken) {
      if (_.isPlainObject(currentWork) && this.lastToken !== newToken) {

        setUrl(newToken);

        this.lastToken = newToken;
        this.checkForToken = false;
      }
    }
  };

  public gaClick(eventAction: string, event?: any) {
    
    eventAction = 'Dashboard - ' + eventAction;
    

    this.analyticsService.eventEmitter(eventAction, window.location.href, event);
  }

  // this.$doCheck = function () {
  //   $scope.getUrl();
  // };

  // $rootScope.$broadcast(EnvironmentConfig.msLoading);

  // // listen for mstr session creation
  // $rootScope.$on(salesConstants.events.hasMstrSession, function (evt, obj) {
  //   if (obj.mstrSessionExists) {
  //     $scope.getUrl();
  //   }
  // });

}
