<app-header></app-header>
<div class="publishing-groups container mt-4">
    <div class="header">
        <h5>HBG's Publishing Groups</h5>
    </div>
    <div class="row" *ngIf="url">
            <iframe class="col-xs-12 borderless" iframe-resizer="" frameBorder="0" width="100%" 
                [src]="url" id="myIframe" scrolling="no"></iframe>
        </div>
</div>
<app-footer></app-footer>