<app-header></app-header>
<div class="container py-4">
    <div class="book-details-block">
        <div class="row">
            <div class="col-sm-9 border-end">
                <div class="py-3 pb-5 outer-space">
                    <h4 class="back-line-txt text-center my-3"><span>BOOK DETAILS
                        </span></h4>
                    <!-- Heading end here -->
                    <div class="d-flex mt-4">
                        <div class="flex-shrink-0">
                            <figure>
                                <img src="{{bookDetail?.coverImgURL}}"
                                    onerror="this.src='./assets/images/default-book.svg';" alt="...">
                            </figure>

                            <div class="img-text-block">
                                <a [ngClass]="!showDownloadImage ? 'btn disabled' : ''" target="_blank" href="{{bookDetail?.coverImgURL}}" class="d-block">
                                    <i class="fa fa-download me-1"></i>
                                    <span>Download Cover</span>
                                </a>
                                <a *ngIf="bookDetail && showSalesLink(bookDetail?.onSaleDate, bookDetail.selectedIsbn)"  (click)="redirectToSales($event, bookDetail.selectedIsbn)" class="d-block mt-1">View Sales</a>
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-5">
                            <h2 *ngIf="bookDetail?.productTitle">{{bookDetail?.productTitle}}</h2>
                            <div ngbDropdown class="d-inline-block">
                                <button class="btn btn-outline-primary form-select" id="dropdownBasic1" ngbDropdownToggle>
                                    <span>
                                        {{selectedFormat?.description}}
                                    </span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <div *ngFor="let format of allFormats">
                                        <button ngbDropdownItem *ngIf="bookDetail?.selectedIsbn != format.isbn" [value]="format.isbn" (click)="navigateToDifferentIsbn(format.isbn)">
                                            <span>
                                                {{format.description}}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="info-group mt-3">
                                <p class="title mb-2" *ngIf="bookDetail?.byLine"><b>By: </b> <span>{{bookDetail?.byLine}}</span></p>
                                <p class="title mb-2" *ngIf="bookDetail?.isbn"><b>ISBN-13: </b> <span>{{bookDetail?.isbn}}</span></p>
                                <p class="title mb-2" *ngIf="bookDetail?.onSaleDate"><b>On Sale: </b> <span>{{bookDetail?.onSaleDate |date : 'MMM dd, yyyy'}}</span></p>
                            </div>
                            <div class="info-group mt-4">
                                <p class="title mb-2" *ngIf="bookDetail?.publisherDesc"><b><em>Publisher: </em></b> <span>{{bookDetail?.publisherDesc}}</span>
                                </p>
                                <p class="title mb-2" *ngIf="bookDetail?.imprint"><b><em>Imprint: </em></b> <span>{{bookDetail?.imprint}}</span>
                                </p>
                            </div>
                            <div class="info-group mt-4">
                                <p class="title mb-2" *ngIf="bookDetail?.usaNetPrice">
                                    <b>
                                        <em>Price: </em>
                                    </b>
                                    <span>{{bookDetail?.usaNetPrice | currency}}
                                        <span *ngIf="bookDetail?.canadaNetPrice">({{bookDetail?.canadaNetPrice | currency}} in Canada)</span>
                                    </span>
                                </p>
                                <p class="title mb-2">
                                    <span *ngIf="bookDetail?.trimSize">
                                        <b>
                                            <em>Size: </em>
                                        </b>
                                        <span>{{getDimensions(bookDetail?.trimSize )}}</span>
                                    </span>
                                    <span *ngIf="bookDetail?.nbrOfPages">
                                        <b>
                                            <em> Pages: </em>
                                        </b>
                                        <span>{{bookDetail?.nbrOfPages}}</span>
                                    </span>
                                </p>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="hasQuotesReviewsData">
                        <h4 class="back-line-txt text-center my-4">
                            <span>QUOTES AND REVIEWS</span>
                        </h4>
                        <!-- Heading end here -->
                        <section class="quote-section mt-4">
                            <div *ngFor="let quotesReview of bookDetail.quotesReviewsData | slice: 0:quoteLength;">
                                <div [innerHTML]="quotesReview.quote"></div>
                                <div [innerHTML]="quotesReview.source"></div>
                            </div>
                            <a (click)="toggleQuoteLength(quoteLength)" data-bs-toggle="collapse" role="button"
                                aria-expanded="false" class="text-end d-flex justify-content-end">
                                <span *ngIf="quoteLength < bookDetail.quotesReviewsData.length">VIEW MORE QUOTES AND
                                    REVIEWS
                                    <i class="fa fa-plus ms-1 mt-1"></i>
                                </span>
                                <span
                                    *ngIf="quoteLength === bookDetail.quotesReviewsData.length  && quoteLengthStatic != bookDetail.quotesReviewsData.length">
                                    View Less
                                    <i class="fa fa-minus ms-1 mt-1"></i>
                                </span>
                            </a>
                        </section>
                    </div>

                    <div *ngIf="bookDetail?.onixSynopsis">
                        <h4 class="back-line-txt text-center my-4">
                            <span>DESCRIPTION</span>
                        </h4>
                        <!-- Heading end here -->
                        <section class="quote-section mt-5">
                            <div [class.show]="show" [innerHTML]="bookDetail?.onixSynopsis"></div>
                        </section>
                    </div>

                    <div *ngIf="booksInSeries?.length > 0">
                        <h4 class="back-line-txt text-center my-4">
                            <span>MORE BOOKS FROM THIS SERIES</span>
                        </h4>
                        <section class="quote-section mt-4">
                            <div class="row row-cols-4 my-book justify-content-center">
                                <ng-container>
                                    <div *ngFor="let bookInSeries of booksInSeries; let i=index" class="col text-center mb-md-5 mb-3">
                                        <div title="{{bookInSeries.title}}" class="zoom-effect">
                                            <img href="#" src="{{bookInSeries.coverImgURL}}" onerror="this.src='./assets/images/default-book.svg';" class="w-100 mb-2 border bg-white p-2 fix-height cursor-pointer"
                                                alt="" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',bookInSeries?.isbn]">
                                        </div>
                                        <span>{{bookInSeries.title}}</span>
                                        <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',bookInSeries?.isbn]" class="d-block">View Book Details</a>
                                        <a class="d-block" *ngIf="bookInSeries && showSalesLink(bookInSeries?.onSaleDate, bookInSeries.isbn)" (click)="redirectToSales($event, bookInSeries.isbn)">View Sales</a>
                                    </div>
                                </ng-container>
                            </div>
                        </section>
                    </div>
                    
                </div>
            </div>
            <div class="col text-center">
                <div class="p-3 px-4 my-3 book-details">
                    <div *ngIf="teamList?.length > 0">
                        <h3 class="px-4">HBG TEAM FOR {{bookDetail?.productTitle | uppercase}}</h3>
                        <ul class="list-group team-member mt-3">
                            <li class="list-group-item px-0" *ngFor="let team of teamList ; let i = index">
                                <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId, 'work', productIsbn ,'member', team?.userId ]">
                                    <em>{{team?.fullName}}</em>
                                    <br>
                                    <span>{{team?.empTitle}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <ul class="list-group px-0 mb-5" *ngIf="latestBooks?.length > 0">
                        <!-- <li class="list-group-item py-0 border-top-0"> -->
                            <h3 class="mb-3">MY LATEST BOOKS</h3>
                        <!-- </li>
                        <ul class="list-group team-member"> -->
                            <li class="list-group-item px-0" *ngFor="let latestBook of latestBooks ; let i = index">
                                <div title="{{latestBook.title}}" class="img-block mx-auto px-5">
                                    <img href="#" src="{{latestBook.coverImgURL}}" onerror="this.src='./assets/images/default-book.svg';" class="w-100 mb-2 border bg-white p-2 fix-height cursor-pointer"
                                        alt="" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',latestBook?.isbn]">
                                </div>
                                <span>{{latestBook.title}}</span>
                                <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'book',latestBook?.isbn]" class="d-block">View Book Details</a>
                                <a class="d-block" *ngIf="latestBook && showSalesLink(latestBook?.onSaleDate, latestBook.isbn)"  (click)="redirectToSales($event, latestBook.isbn)">View Sales</a>
                            </li>
                        <!-- </ul> -->
                    </ul>
                    

                    <ul class="list-group px-0 mb-5">                        
                        <!-- <li class="list-group-item py-0"> -->
                            <h3 class="my-3">HBG News</h3>                           
                        <!-- </li> -->
                         <app-news-right-panel class="app-news"></app-news-right-panel>
                    </ul>
                
                    <!-- <h3>HBG News</h3> -->
                    <div class="disclaimer mt-5 px-3">
                        <h4 class="mb-0">DISCLAIMER</h4>
                        <p>HBG is pleased to offer you the promotional materials that are made available for download from this website (such
                            as cover images, guides, etc.), but you may ONLY use these promotional materials to promote the book(s) to which
                            the materials relate.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loader">
    <div id="loader-wrapper">
        <div id="loader"></div>
    </div>
</div>

<app-footer></app-footer>