import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.scss']
})
export class DialogInformationComponent implements OnInit {

  notificationMessage: any;
  errorType: any = 'Info';
  errorHeading: any = 'Success!';
  loaderSubscription: Subscription;
  selectedAuthorData;

  constructor(private utilitiesService: UtilitiesService,  private router: Router) {
    this.loaderSubscription = this.utilitiesService.showInformationStatus().subscribe(message => {
      this.show(message.message, message.type);
    });
  }

  ngOnInit() {
    this.selectedAuthorData = this.utilitiesService.getAuthorData();
  }

  show(message?: any, type?: any) {
    this.notificationMessage = message;
    this.errorType = type;
    if (!type) {
      this.errorType = 'Info';
      this.errorHeading = 'Success!';
    } else if (type === 'Error') {
      this.errorHeading = 'Error';
    } else if (type === 'Alert') {
      this.errorHeading = 'Alert';
    } else if (type === 'Warn') {
      this.errorHeading = 'Info';
      setTimeout(() => {
        this.closeModal();
      }, 3000);
    }
    this.utilitiesService.open('modal-dialog-info');
  }

  closeModal() {
    this.utilitiesService.close('modal-dialog-info');
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

  redirectToDahsboard() {
    this.utilitiesService.close('modal-dialog-info');
    this.router.navigate(['navigation', this.utilitiesService.getAuthorData().authorProfileId, 'dashboard']);
  }

}
