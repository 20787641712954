import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/service/banner.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';

import {
  find as _find, chunk as _chunk
} from "lodash-es";

@Component({
  selector: 'app-news-archive',
  templateUrl: './news-archive.component.html',
  styleUrls: ['./news-archive.component.scss']
})
export class NewsArchiveComponent implements OnInit {

  feeds: any = [];
  chunkedData: any;
  selectedAuthorId: string;

  constructor(private bannerService: BannerService, private utilityService: UtilitiesService, private authService: AuthService) { }

  ngOnInit(): void {
    this.selectedAuthorId = this.authService.getSelectedAuthor();
    this.getRssFeed();
  }

  getRssFeed() {
    this.utilityService.showLoader();
    this.bannerService.getRssFeeds().subscribe((result: any) => {
      this.utilityService.hideLoader();
      this.feeds = result;
      this.chunkedData = _chunk(this.feeds, 3);
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
    });
  }

}
