import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectAuthorComponent } from '../shared/select-author/select-author.component';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import {
  find as _find, cloneDeep as _cloneDeep
} from "lodash-es";
import { UtilitiesService } from '../service/utilities.service';
import { QuestionnaireService } from '../service/questionnaire.service';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../service/analytics.service';
import { PermissionService } from '../service/permission.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  authorWorks: any = [];
  coverImgURL = environment.coverImgURL;
  settings: any = [];
  aboutBookQuestionnaires: any = [];

  pagePermission: any = [];
  selectedAuthorId: string;

  constructor(public authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute, public modalService: NgbModal,
    private utilityService: UtilitiesService, private questionnaireService: QuestionnaireService, private analyticsService: AnalyticsService,
    private permissionService: PermissionService) {
    this.subscription = this.authService.selectedAuthor.subscribe(result => {

    });

    this.activatedRoute.params.subscribe((params: any) => {
      this.selectedTab = params.type.toString().toLowerCase();
    });

    this.pagePermission = this.permissionService.getPagePermission();
  }

  selectedTab: any;
  subscription: Subscription;
  authors: any = [];
  list: any = [];
  selectedAuthor: any = '';
  selectedAuthorData: any = {};
  bookShelfConstant: any = {
    // eslint-disable-next-line no-magic-numbers
    pager: [5, 10, 20, 'All'],
    initialLimit: 5,
    bookTitleCharacterEllipsis: '...',
    bookTitleCharacter: 75
  };
  searchBook: string;

  ngOnInit(): void {
    this.selectedAuthorId = this.authService.getSelectedAuthor();
    if (this.pagePermission?.canViewAq == 0) {
      this.router.navigate(['/navigation', this.selectedAuthorId, 'dashboard']);
    }

    this.analyticsService.pageView('Questionnaire', 'Page Load');
    this.analyticsService.eventEmitter('Questionnaire', window.location.href, 'Page Load');
    this.selectedAuthorData = this.utilityService.getAuthorData();
    this.getSettings();
  }

  getSettings() {
    this.authService.getSettings().subscribe((result: any) => {
      this.settings = result;
    }, err => {
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
    });
  }

  saveSection(sectionData) {
    var postData: any = {},
      temp: any = {},
      i = 0;
    postData.authorId = this.authService.getSelectedAuthor().toString();
    postData.check = true;
    if (sectionData.questions.length > 0) {
      postData.categoryId = sectionData.questions[0].categoryId;
    } else {
      postData.categoryId = sectionData.categoryId;
    }

    // if (this.selectedTab.toString().toLowerCase() === 'aboutbook') {
    if (sectionData.selectedtab === 'aboutbook') {
      postData.scopeType = sectionData.scopeType;
      postData.scopeId = sectionData.scopeId;
    }

    postData.answerUpdates = [];
    sectionData.questions.forEach(question => {
      // angular.forEach(sectionData.questions, function (question) {
      temp = {};
      temp.questionId = question.questionId;
      temp.answerId = question.answers.answerId;
      temp.answerText = question.answers.answerText;
      postData.answerUpdates.push(_cloneDeep(temp));
    });

    postData.isManual = false;
    if (sectionData.saveMode != "autoSave") {
      postData.isManual = false;
      this.utilityService.showLoader();
    }
    this.questionnaireService.saveSection(postData).subscribe((resp: any) => {

      if (sectionData.saveMode != "autoSave") {
        this.utilityService.hideLoader();
      }
      sectionData.modifiedDate = resp[0].answerSaveDate;
      let answerUpdates = [];
      answerUpdates = resp[0].answerUpdates;
      sectionData.authorId = resp[0].authorId;
      sectionData.categoryId = resp[0].categoryId;
      sectionData.scopeId = resp[0].scopeId;
      sectionData.scopeType = resp[0].scopeType;
      // angular.forEach(sectionData.questions, function (question) {
      sectionData.questions.forEach(ques => {
        ques.questionId = answerUpdates[i].questionId;
        ques.answers.answerId = answerUpdates[i].answerId;
        ques.answers.answerText = answerUpdates[i].answerText;
        ques.answers.modifiedDate = resp[0].answerSaveDate;
        i++;
        // if (ques.questionType === 'checkbox') {
        //   ques.answers = ques.answers ? ques.answers : {};
        //   ques.answers.answerText = ques.answers.answerText ? (ques.answers.answerText == 'true' ? true : false) : false;
        // }
      });

    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
    });
  };

  gaEvent(event: any) {
    this.analyticsService.eventEmitter('Author and Illustrator QA', window.location.href, event);
  }




}

