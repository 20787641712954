import { QuestionnaireService } from './../service/questionnaire.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from '../service/permission.service';
import { SessionService } from '../service/session.service';
import { UtilitiesService } from '../service/utilities.service';
import { AuthService } from '../service/auth.service';
import { ChangePasswordComponent } from '../login/change-password/change-password.component';
import { BannerService } from '../service/banner.service';
import { BookSelectorService } from '../service/book-selector.service';
import { AnalyticsService } from '../service/analytics.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  activeModal: NgbActiveModal;
  selectedAuthorId: string;
  loggedInUser;
  userInitials: string;
  pagePermission: any;
  modalRef: any;
  systemMessage: any = "";
  hbg_user_email: any = 'hbgusa.com';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  showChangePassword: boolean = true;
  activeInterval;
  constructor(public questionnaireService: QuestionnaireService, public sessionService: SessionService, private utilityService: UtilitiesService, private permissionService: PermissionService, private router: Router,
    private authService: AuthService, public modalService: NgbModal, private analyticsService: AnalyticsService, public bannerService: BannerService, private bookSelectorService: BookSelectorService
    , private idle: Idle, private keepalive: Keepalive) {
      this.router.events.subscribe((ev)=>{
        if(ev instanceof NavigationEnd){
          this.pagePermission = this.permissionService.getPagePermission();
        }
      })
    this.init_idle(idle, keepalive)
  }

  ngOnInit(): void {
    this.selectedAuthorId = this.authService.getSelectedAuthor();
    this.loggedInUser = JSON.parse(localStorage.getItem('userInfo'));
    const firstInitial = this.loggedInUser.firstName ? this.loggedInUser.firstName.substring(0, 1) : '';
    const lastInitial = this.loggedInUser.lastName ? this.loggedInUser.lastName.substring(0, 1) : '';
    this.userInitials = firstInitial + lastInitial;

    if (this.loggedInUser.emailAddress && this.loggedInUser.emailAddress.toLowerCase().indexOf(this.hbg_user_email) > 0) {
      this.showChangePassword = false;
    }

    this.checkBannerMessage();
    this.idleResetOnQuestionnaireSave();
  }
  logout() {
    this.sessionService.questionnaireChangesOnLogout()
    this.sessionService.logout();
  }

  // Choose different book
  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      centered: true,
      // size: "md",
      windowClass: "animated fadeIn"
    });

    // modalRef.componentInstance.authorWorks = this.authorWorks;
    // this.activeModal.close();
  }

  checkBannerMessage() {
    if (this.sessionService.showSystemMessage) {
      this.systemMessage = "";
      this.bannerService.getSystemMessages().subscribe((result: any) => {
        if (result && result.length > 0) {
          this.systemMessage = result[0].message;
        }
      });
    }
  }

  closeSystemMessage() {
    this.systemMessage = "";
    this.sessionService.showSystemMessage = false;
  }

  idleResetOnQuestionnaireSave() {
    this.questionnaireService.idleChangesObs.subscribe((res => {
      this.reset();
    }))
  }

  public routeToSales() {
    let sales_works = this.bookSelectorService.allIsbns;
    if (sales_works && sales_works.length == 0) {
      this.utilityService.showInformation("There is no data to display at this time.", 'NotAllowed');
    } else {
      this.router.navigate(['/navigation', this.selectedAuthorId, 'salesDashboard']);
    }
  }

  public routeToQA() {
    if (this.pagePermission.canViewAq == 1) {
      this.router.navigate(['/navigation', this.selectedAuthorId, 'questionnaire', 'aboutYou']);
    } else {
      // this.utilityService.showInformation("Sorry, You do not have permission to view this feature.", 'NotAllowed');
    }
  }

  public headerClick(tab: string) {
    this.analyticsService.eventEmitter('Header menu click', window.location.href, tab);
  }

  init_idle(idle: Idle, keepalive: Keepalive) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(1800);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.sessionService.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

}
