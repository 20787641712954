import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormFieldControl } from '@angular/material/form-field';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import {
  find as _find, orderBy as _orderBy, isEmpty as _isEmpty
} from "lodash-es";
import { Subscription } from 'rxjs';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { questionnaireConstants } from '../questionnaire-constants'
import { PermissionService } from 'src/app/service/permission.service';
import { AnalyticsService } from 'src/app/service/analytics.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  pagePermission: any = [];

  constructor(private permissionService: PermissionService, private analyticsService : AnalyticsService) {
    this.pagePermission = this.permissionService.getPagePermission();
    this.canEdit = this.pagePermission.canEditAq == 1 ? true : false;
  }
  // @Input() contactSection: {};
  canEdit: boolean = true;
  showText: boolean = false;
  isContactFormChanged: boolean = false;
  options: any = {
    types: ['geocode'],
    forceSelection: true,
  }
  @Input() contactSection: any;
  @Input() nameHeading: any;
  @Output('saveSection') saveSection: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

  }

  save() {
    this.analyticsService.eventEmitter("Save - Contact, About You Book questionnaire", window.location.href, this.nameHeading);
    this.showText = false;
    this.contactSection.selectedtab = "contact";
    this.saveSection.emit(this.contactSection);
  }

  public handleAddressChange(address: Address) {
    if (address.formatted_address) {
      this.contactSection.city = this.getAddressComponent(questionnaireConstants.cityVar, address.address_components);
      this.contactSection.state = this.getAddressComponent(questionnaireConstants.stateVar, address.address_components);
      this.contactSection.country = this.getAddressComponent(questionnaireConstants.countryVar, address.address_components);
      this.contactSection.zip = this.getAddressComponent(questionnaireConstants.zipVar, address.address_components);
      this.contactSection.mailingAddress = address.formatted_address;
    }
    else {
      this.contactSection.mailingAddress = address;
    }
  }

  public getAddressComponent(field, addressComponents) {
    var addressField = _find(addressComponents, function (addressComponent) {
      return addressComponent.types.indexOf(field) >= 0;
    });

    return addressField ? addressField.long_name : '';
  };

  public contactFormChanged() {
    if (!this.isContactFormChanged) {
      this.isContactFormChanged = true;
    }
  };

  public textKeyPress() {
    this.showText = true;
  }

}
