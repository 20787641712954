<div class="login-wrapper">
    <div class="container-fluid">
        <div class="row">
            <!-- Login form section -->
            <div class="login-form col justify-content-center align-self-center px-md-0 py-md-0 py-3">
                <div class="m-auto form-container">
                    <div class="author-logo mx-auto">
                        <img class="card-img-top" src="assets/images/author-logo.svg"
                            alt="HBG Author Partner Portal logo">
                    </div>
                    <div *ngIf="displayView.login">
                        <form class="input-form mt-5">
                            <div class="form-floating is-focused mb-5">
                                <input type="email" id="floatingInput"
                                    name="floatingInput" [(ngModel)]="credentials.userName" class="form-control"
                                    [ngClass]="credentials.userName.length > 0 ? 'success' : 'danger'"
                                    (keyup)="onKeyUp($event)">
                                <label for="credentials.userName"><i class="fa fa-lock prefix"></i> Email
                                    Address</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="password" id="floatingPassword"
                                    name="floatingPassword" [(ngModel)]="credentials.password" class="form-control"
                                    [ngClass]="credentials.password.length > 0 ? 'success' : 'danger'"
                                    (keyup)="onKeyUp($event)">
                                <label for="credentials.password"><i class="fa fa-key"></i> Password</label>
                            </div>
                            <p class="text-center error-message mt-4">
                                <span *ngIf="loginError">{{loginError}}</span>
                            </p>
                            <div class="text-center my-4">
                                <button type="button" class="btn w-100 btn-theme-blue border-0 login-btn mt-2"
                                    [class.disabled]="!validateLogin()" [disabled]="!validateLogin()"
                                    (click)="login()">Log In</button>
                            </div>
                        </form>
                        <p class="text-center">
                            <a href="javascript:void(0)" (click)="showView('forgotPassword')">Forgot your password?</a>
                            <br>
                            If you forgot your email address or have a problem logging in, please
                            contact us at <a
                                href="mailto:HBG-AuthorPortalSupport@hbgusa.com">HBG-AuthorPortalSupport@hbgusa.com</a>
                        </p>
                    </div>

                    <!-- Forgot Password view starts here -->
                    <div *ngIf="displayView.forgotPassword">
                        <form class="input-form my-0">
                            <div class="forgot-paasowrd mt-4">
                                <h2 class="heading">Forgot Password</h2>
                                <p class="mb-4">Please enter your email address and we will send
                                    you an email about
                                    how to reset your password.</p>
                            </div>
                            <div class="form-floating forgot-password mb-4">
                                <input type="text" name="emailAddress" id="emailAddress"
                                    name="floatingInput" [(ngModel)]="forgotPasswordObject.emailAddress" maxlength="50"
                                    email="true" (focusout)="validateEmailAddress()" class="form-control" [ngClass]="forgotPasswordObject.emailAddress.length > 0 ? 'success' : ''"
                                    (keyup)="onKeyUp($event)">
                                <label for="forgotPasswordObject.emailAddress"><i class="fa fa-lock prefix"></i>
                                    Email Address</label>
                            </div>

                            <p class="text-center error-message mt-4" *ngIf="forgotPasswordErrorMessage">
                                <span>{{forgotPasswordErrorMessage}}</span>
                            </p>
                            <div class="text-center">
                                <button type="submit" class="btn w-100 mw-100 btn-theme-blue border-0 login-btn"
                                    (click)="submitResetPassword()"
                                    [class.disabled]="!forgotPasswordObject.emailAddress || forgotPasswordErrorMessage"
                                    [disabled]="!forgotPasswordObject.emailAddress || forgotPasswordErrorMessage">Reset
                                    Password</button>
                            </div>

                            <button (click)="showView('login')" type="submit" class="btn btn-link my-3 px-0 text-left">
                                <img src="./assets/images/back-arrow.svg" width="15">
                                <span class="font-size-18 ml-2 mt-2">Back to Log in</span>
                            </button>
                        </form>
                    </div>
                    <!-- Forgot Password view ends here -->



                    <!-- Set Password view starts here -->
                    <div *ngIf="displayView.setPassword">
                        <form class="input-form my-0">
                            <div class="forgot-paasowrd mt-4">
                                <h2 class="heading">Set Password</h2>
                                <p class="mb-4">Please click on set password and we will send you an
                                    email about how
                                    to set your password.</p>
                            </div>

                            <div class="form-floating is-focused mb-4">
                                <input type="text" name="emailAddress" id="emailAddress"
                                    [(ngModel)]="forgotPasswordObject.emailAddress" maxlength="50" email="true"
                                    class="form-control" disabled>
                                <label for="forgotPasswordObject.emailAddress"><i class="fa fa-lock prefix"></i>
                                    Email Address</label>
                            </div>

                            <p class="text-center error-message mt-4" *ngIf="forgotPasswordErrorMessage">
                                <span>{{forgotPasswordErrorMessage}}</span>
                            </p>
                            <div class="text-center">
                                <button type="submit" class="btn w-100 mw-100 btn-theme-blue border-0 login-btn"
                                    (click)="submitResetPassword()" [class.disabled]="!validateEmailAddress()"
                                    [disabled]="!forgotPasswordObject.emailAddress">Set Password</button>
                            </div>

                            <button (click)="loadLogin()" type="submit" class="btn btn-link my-3 px-0 text-left">
                                <img src="./assets/images/back-arrow.svg" width="15">
                                <span class="font-size-18 ml-2 mt-2">Back to Log in</span>
                            </button>
                        </form>
                    </div>
                    <!-- Set Password view ends here -->

                    <!-- Response view starts here -->
                    <div *ngIf="displayView.responseView">
                        <div class="forgot-paasowrd mt-4">
                            <h2 class="heading">{{responseHeading}}</h2>
                            <p class="mb-0">{{responseMessage}}</p>
                        </div>
                        <button (click)="loadLogin()" type="submit" class="btn btn-link mb-2 px-0 text-left">
                            <img src="./assets/images/back-arrow.svg" width="15">
                            <span class="font-size-18 ml-2 mt-2">Back to Log in</span>
                        </button>
                    </div>
                    <!-- Response view ends here -->


                    <div class="text-center px-1">
                        <p class="text-start description mb-1">
                            The Hachette Book Group Author Portal (“Portal”) is for each Hachette author and
                            his/her designated representatives only (collectively, “Authorized Users”). By
                            accessing the Portal you acknowledge and agree that: you are an Authorized User;
                            you will not share your personal log-in credentials with anyone else; the Portal
                            and information contained therein are confidential and proprietary to Hachette;
                            you consent to Hachette’s use of your personal data collected through the
                            Portal, which will be used in accordance with its <a
                                href="https://www.hbgauthorresources.com/landing-page/info-for-authors-author-privacy-policy/"
                                target="_blank" class="term-of-use text-decoration-none">Privacy Policy</a>; and you
                            will adhere
                            to the <a (click)="getTermOfUser()" class="term-of-use text-decoration-none">Terms of
                                Use.</a>
                        </p>
                        <p class="description mb-0">
                            © {{ currentYear }} Hachette Book Group<span> - v3.9.0</span>
                        </p>
                    </div>
                </div>
            </div>

            <!-- author login banner -->
            <div class="col right-welcome d-md-block d-none p-0">
                <div class="author p-0 d-flex position-relative">
                    <div class="banner-text">
                        <div class="w-75 mx-auto px-5">
                            <h1 class="mb-4">Welcome, Authors.</h1>
                            <p class="lh-base">
                                The Hachette Book Group Author Portal makes it easy to access the information you
                                need as an HBG author. Here you’ll find publishing tips and resources to help you
                                complete your manuscript and launch your book, as well as sales and royalty
                                information. Complete your Author Questionnaire, connect with your HBG team, read
                                HBG news, and much more. Log in to begin.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
