import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { AuthService } from 'src/app/service/auth.service';
import { AuthorService } from 'src/app/service/author.service';
import { SessionService } from 'src/app/service/session.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogSuccessComponent } from 'src/app/modalDialog/dialog-success/dialog-success.component';


@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
    
    loading = false;
    errorMessage = "";
    changePasswordObject: any = {};
    changePasswordForm: FormGroup;
    errorObject: { [key: string]: any } = {
        newPassword: false,
        confirmPassword: false,
        passwordMismatch: false,
    };
    passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{10,}$/;

    public errorValidPassword = "Please enter a valid password.";
    public errorPasswordMismatch = "Password doesn't match";

    changePasswordErrorObject: { [key: string]: string } = {
        "110": "Your current password was not correct. Please try again.",
        "120": "Password cannot be one of previous 3. Please try another password.",
        "10": "Current user not found.",
        "-999": "Only NON-CORP users can perform change password operation.",
    };

    constructor(public modalService: NgbModal, public activeModal: NgbActiveModal, private manageUsersService: AuthorService, private authService: AuthService,
        private utilitiesService: UtilitiesService, private analyticsService : AnalyticsService, private sessionService: SessionService  ) { }

    ngOnInit() {
        this.changePasswordForm = new FormGroup({
            'oldPassword': new FormControl(''),
            'newPassword': new FormControl(null, [Validators.required, Validators.pattern(this.passwordRegex)]),
            'confirmPassword': new FormControl(null, [Validators.required, Validators.pattern(this.passwordRegex)])
        }, this.passwordMatchValidator);
    }

    changePassword() {
        if(this.changePasswordForm.valid && !this.errorObject.passwordMismatch) {
            this.analyticsService.eventEmitter('Change Password', 'Password changed', window.location.href);
            this.loading = true;
            this.changePasswordObject.userName = "asdf"; 
            // this.authService.getUserDetails().emailAddress;
            this.changePasswordObject.newPassword = this.changePasswordForm.get('newPassword').value;
            this.changePasswordObject.oldPassword = this.changePasswordForm.get('oldPassword').value;

            var userData = {
              userId: this.sessionService.getCurrentUserId(),
              oldPassword: this.changePasswordForm.get('oldPassword').value,
              password: this.changePasswordForm.get('newPassword').value,
              reEnterPassword: this.changePasswordForm.get('newPassword').value
            };
            this.utilitiesService.showLoader();
            this.manageUsersService.changePassword(userData).subscribe((result: any) => {
                this.utilitiesService.hideLoader();
                this.loading = false;
                if(result.ERROR_MESSAGE)  {
                  this.errorMessage = result.ERROR_MESSAGE;
                } else {
                  this.activeModal.close();
                  this.handleSuccess(result);
                }
                
            }, err => {
                this.utilitiesService.hideLoader();
                this.loading = false;
                console.log(err);
                
            });
        }

    }

    public checkValidationError(field) {
        this.errorObject[field] = false;
        this.errorObject.passwordMismatch = false;
        if (this.hasError(field)) {
            this.errorObject[field] = true;
            return;
        }

        if (this.checkValidation()) {
            this.errorObject.passwordMismatch = this.isPasswordMismatch() ? true : false;
        }
    }

    public emptyErrorMessage() {
        this.errorMessage = "";
    }

    public hasError(field) {
        let error = this.changePasswordForm.get(field).errors;
        if (error && error.pattern) {
            return true;
        }
    }

    public passwordMatchValidator(formGroup: FormGroup) {
        return formGroup.get('newPassword').value === formGroup.get('confirmPassword').value ? null : { 'mismatch': true };
    }

    public checkValidation() {
        return this.changePasswordForm.get('newPassword').value && this.changePasswordForm.get('confirmPassword').value
            && !this.errorObject.newPassword && !this.errorObject.confirmPassword
    }

    public isPasswordMismatch() {
        return this.changePasswordForm.errors != null && this.changePasswordForm.errors.mismatch;
    }

    handleSuccess(result: any) {
        if (result != null) {
            const modalRef = this.modalService.open(DialogSuccessComponent, {
              centered: true,
              size: "md",
              windowClass: "animated fadeIn"
            });      
        }
    }

    handleError(err) {
        if (err && err.error) {
            let statusCode = err.error.statusCode;
            if(this.changePasswordErrorObject[statusCode]) {
                this.errorMessage = this.changePasswordErrorObject[statusCode]
            } else {
                this.errorMessage = this.utilitiesService.getErrorMessage();
            }
        }
    }


    closeModal() {
        this.activeModal.close();
        this.changePasswordForm.reset();
        this.clearErrorObject();
        this.errorMessage = "";
    }

    clearErrorObject() {
        this.errorObject = {
            newPassword: false,
            confirmPassword: false,
            passwordMismatch: false,
        };
        this.errorMessage = "";
    }
}
