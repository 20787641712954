<app-header></app-header>

<div class="news-resources mt-3">
    <section id="SubHeaderSection">
        <div class="container py-3">
            <div class="content">
                <div class="row text-center align-items-center">
                    <div class="col-12">
                        <h4 class="back-line-txt text-center my-3"><span>NEWS AND RESOURCES</span></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
                        <div class="d-md-flex d-block">
                            <div class="flex-grow-1 text-md-start text-center w-100">
                                <div class="col-12 mb-4">
                                    <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'guide']"
                                        class="mb-1">HBG Guide for Authors</a>
                                    <p class="lh-base">The Guide for Authors gives you a comprehensive overview of the
                                        publishing process at HBG and answers authors’ most frequently asked questions.
                                        Whether you’re a first time author or a veteran, read the Guide to learn best
                                        practices for preparing your manuscript.
                                        Find out about the various stages of the production process, including
                                        copyediting and cover design. Learn about
                                        legal issues, clearing permissions, and about using images in your book. You’ll
                                        also find information about sales and
                                        marketing at HBG, and how you can best partner with your HBG team to launch your
                                        book.</p>
                                    <a class="btn btn-theme-blue btn-view-author-guide my-2 my-md-0" role="button"
                                        [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'guide']">View
                                        HBG Author Guide</a>
                                </div>
                                <div class="col-12 mb-4">
                                    <a href="https://www.hbgauthorresources.com/" class="mb-1" target="_blank">HBG
                                        Resources for Our Authors</a>
                                    <p class="lh-base">Compiled by our experts at Hachette, this
                                        Marketing/Publicity/Subrights FAQ site offers
                                        background information, tips, and resources to help you through the publishing
                                        process.
                                        We’re delighted to partner with you on this journey.</p>
                                </div>
                                <div class="col-12 mb-4">
                                    <h1 class="mb-1">Hachette Online Lending Library Form (HOLLI)</h1>
                                    <p class="lh-base">Visit the Hachette Online Lending Library (HOLLi) to download
                                        ebooks and audiobooks from HBG’s
                                        entire catalog to your computer, Android, or iOS device. To register for HOLLi,
                                        please email <a href="mailto:HOLLi@hbgusa.com">HOLLi@hbgusa.com</a>
                                        You will receive your library ID and instructions within 7 business days.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-6">
                        <!-- <div class="flex-shrink-0 text-md-start text-center mb-3" (click)="playVideo()">
                            <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                                <img alt="" class="fix-height" src="assets/images/news-recoureces-play-video.jpg">
                            </div>
                        </div>
                        <h6 class="mt-4 text-center">WELCOME VIDEO FROM
                            <br> MICHAEL PIETSCH, HBG'S CEO</h6> -->
                        <div class="col-12 mb-4">
                            <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'newsArchive']"
                                class="mb-1">HBG News</a>
                            <p class="lh-base">Stay up-to-date with the latest news, information, and acquisition
                                updates from Hachette Book Group.</p>
                        </div>
                        <div class="col-12 mb-4">
                            <h1 class="mb-1">Helpful Forms</h1>
                            <span><a href="javascript:void(0)"
                                    (click)="downloadDocument('text-or-illustrations-permission-for-authors.docx')"><b><i
                                            class="fas fa-download"></i> Download Sample Permission Form</b></a></span>
                            <p class="lh-base">Here’s a template you can use when seeking permission from copyright
                                holders for text or images you want to include in your book.</p>
                            <span><a href="javascript:void(0)"
                                    (click)="downloadDocument('getty-images-interior-image-permission-for-authors.docx')"><b><i
                                            class="fas fa-download"></i> Download the Getty Images Permission
                                        Form</b></a></span>
                            <p class="lh-base">HBG has a pre-negotiated rate with Getty that authors can use to save on
                                interior images. Download this form for details.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<app-footer></app-footer>