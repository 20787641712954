import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/service/banner.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { find as _find } from "lodash-es";
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/service/analytics.service';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.scss']
})
export class NewsArticleComponent implements OnInit {
  articleId;
  currentFeed: any;

  constructor(private bannerService: BannerService, private utilityService: UtilitiesService, private activatedRoute: ActivatedRoute,  private analyticsService: AnalyticsService) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.articleId = params.articleId;
      this.getRssFeeds();
    });
  }

  ngOnInit(): void {
    this.analyticsService.pageView('News Archive', 'Page Load');
    this.analyticsService.eventEmitter('News Archive', window.location.href, 'Page Load');
   }

  getRssFeeds() {
    this.utilityService.showLoader();
    this.bannerService.getRssFeeds().subscribe((result: any) => {
      this.currentFeed = _find(result, { 'articleId': this.articleId });
      this.utilityService.hideLoader();
    }, err => {
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
      this.utilityService.hideLoader();
    });
  }

}
