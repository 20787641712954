<section class="hbg-team my-5 py-5 border">
    <div *ngIf="!teamLoader" class="container">
        <div class="row text-center position-relative">
            <div class="col">
                <h1>Your HBG Team for <br> <em class="mb-4">{{currentBook?.title}}</em> <button type="button" *ngIf="workList.length > 1"
                        class="btn btn-theme-blue px-2 choose-diffrent my-2 my-md-0"
                        (click)="choose(); gaClick('Choose a Different Book', '')">
                        Choose a Different Book
                    </button>
                </h1>
                <!-- <h2 class="fs-5 mb-4 fst-italic">{{currentBook?.title}}</h2> -->
            </div>
        </div>
        <div class="row text-center d-flex justify-content-center">
            <div class="col-md-3 text-center" *ngFor="let result of teamList ; let i = index">
                <div class="member-info-wrapper">
                    <span class="fst-italic">{{result?.fullName}}</span>
                    <!-- <p *ngFor="let data of result.roleList" class="mb-2">{{data}}</p> -->
                    <p class="mb-2" title="{{result.roleList.join(', ')}}">Role: {{result.roleList.join(", ") | truncate : 27}}</p>
                    <a href="{{'mailto:' + result?.emailAddress}}" class="d-inline">{{result?.emailAddress}}</a>
                </div>
                <div [routerLink]="['/navigation', auth.getSelectedAuthor(), 'work', currentBook.isbn,'member', result.userId ]" class="card bg-white rounded-0 cursor-pointer">
                    <img [routerLink]="['/navigation', auth.getSelectedAuthor(), 'work', currentBook.isbn,'member', result.userId ]" [src]="getUserPhoto(result.emailAddress)" class="card-img-top" alt="...">
                    <div class="card-body">
                        <a click="gaClick('View Team Profile', '')" [routerLink]="['/navigation', auth.getSelectedAuthor(), 'work', currentBook.isbn,'member', result.userId ]"
                            class="m-0">View Profile
                            <i class="fa fa-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="teamLoader" class="container">
        <div class="row text-center position-relative">
            <div class="col">
                <!-- <h1 class="mb-1 fs-4">Your HBG Team for <button type="button"
                    class="btn pull-right btn-theme-blue fs-6 px-2 end-0 lh-1 choose-diffrent my-2 my-md-0"
                    (click)="choose()">
                    Choose a Different Book
                </button>
            </h1> -->
                <div class="spinner-border text-muted" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</section>