<span *ngIf="showText" class="alert-msg w-100 fw-bolder mb-4 d-block">Please remember to use the “save”
    button at the bottom of this form to save your changes before leaving this section of the QA
    !!!</span>
<form #f="ngForm" novalidate>
    <div class="contacts-ui">
        <label class="px-4 control-label" type="floating">
            <span class="alert-msg">*</span>{{nameHeading}}
        </label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="name"
                [(ngModel)]="contactSection.contactName" requialert-msg>
        </mat-form-field>

        <label class="px-4 control-label" type="floating"><span class="alert-msg">*</span>Email Address: </label>
        <span *ngIf="f.controls['email'] && f.controls['email']?.invalid && f.controls['email']?.dirty"
            class="alert-msg">Please enter a valid email address.</span>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="email"
            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" 
                [(ngModel)]="contactSection.emailAddress" requialert-msg>
        </mat-form-field>

        <label class="px-4 control-label" type="floating">Social Media URL:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="socialMedia"
                [(ngModel)]="contactSection.socialMediaUrl">
        </mat-form-field>

        <label class="px-4 control-label" type="floating">Company Name:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="company"
                [(ngModel)]="contactSection.companyName">
        </mat-form-field>

        <label class="px-4 control-label" type="floating">Address Line 1:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()"  matInput [disabled]="!canEdit" ngx-google-places-autocomplete
            value="{{contactSection.mailingAddress}}"
                [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" />
        </mat-form-field>

        <label class="px-4 control-label" type="floating">Address Line 2:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="AddressLine2"
                [(ngModel)]="contactSection.apartmentDetails">
        </mat-form-field>

        <label class="px-4 control-label" type="floating">City / Town:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="city"
                [(ngModel)]="contactSection.city">
        </mat-form-field>

        <label class="px-4 control-label" type="floating">State / Province / Region:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="State"
                [(ngModel)]="contactSection.state">
        </mat-form-field>
        <label class="px-4 control-label" type="floating">Zip / Postal Code:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="Zip" [(ngModel)]="contactSection.zip">
        </mat-form-field>
        <label class="px-4 control-label" type="floating">Country: </label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="Country"
                [(ngModel)]="contactSection.country">
        </mat-form-field>        
        <label class="px-4 control-label" type="floating">Phone Number:</label>
        <span *ngIf="f.controls['phone'] && f.controls['phone']?.invalid && f.controls['phone']?.dirty"
            class="alert-msg">Please enter a phone number without formatting. Example: 5552121234.</span>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="phone"
            pattern="^\d+$"  maxlength="13"
                [(ngModel)]="contactSection.officePhone">
        </mat-form-field>

        <label class="px-4 control-label" type="floating">Relationship to you:</label>
        <mat-form-field class="w-100 border-0" appearance="legacy">
            <input (input)="textKeyPress()" matInput [disabled]="!canEdit" name="relation"
                [(ngModel)]="contactSection.relationship">
        </mat-form-field>
    </div>
    <div class="mb-0"><button type="button" (click)="save()"
        [disabled]="!canEdit || !contactSection.emailAddress || (contactSection.contactName.trim().length == 0) || f.controls['email']?.invalid 
        || f.controls['phone']?.invalid"
            class="btn btn-save btn-theme-blue my-2 my-md-0">
            {{ contactSection.isNew ? "Save" : "Update "+  contactSection.contactType + " Contact"  }}
        </button>
            <span class="alert-msg ml-3"
            *ngIf="!contactSection.emailAddress || (contactSection.contactName.trim().length == 0)">*Please fill out all required fields with valid data.</span>
        <p class="last-saved ml-3 d-inline-block" *ngIf="contactSection.modifiedDate">Last saved at
            {{ contactSection.modifiedDate | date : 'shortTime'}} on {{ contactSection.modifiedDate | date :
            'fullDate'}}
        </p>
    </div>

</form>