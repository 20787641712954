import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthorGuideComponent } from './author-guide/author-guide.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { NewsComponent } from './news/news.component';
import { AgentComponent } from './login/agent/agent.component';
import { AuthorComponent } from './login/author/author.component';
import { SalesComponent } from './sales/sales.component';
import { PermissionResolveService } from './service/resolver/permission-resolve.service';
import { LiferayResolveService } from './service/resolver/liferay-resolve.service';
import { NewsArchiveComponent } from './news/news-archive/news-archive.component';
import { NewsArticleComponent } from './news/news-article/news-article.component';
import { TeamMemberProfileComponent } from './dashboard/team-member-profile/team-member-profile.component';
import { BookDetailsComponent } from './questionnaire/book-details/book-details/book-details.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component'; 
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FaqComponent } from './faq/faq.component';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  {
    path: '403',
    component: PageNotFoundComponent,
    pathMatch: 'full'
  },
  {
    path: 'login',
    children: [
      {
        path: 'author',
        component: AuthorComponent
      },
      {
        path: 'agent',
        component: AgentComponent
      }]
  },
  {
    path: 'navigation/:id',
    resolve: {
      permission: PermissionResolveService
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'questionnaire/:type',
        component: QuestionnaireComponent
      },
      {
        path: 'sales',
        redirectTo : 'sales/',
        pathMatch : 'full'
      },
      {
        path: 'salesDashboard',
        component: SalesDashboardComponent
      },
      {
        path: 'salesDashboard/:isbn',
        component: SalesDashboardComponent
      },
      {
        path: 'sales/:isbn',
        component: SalesComponent
      },
      {
        path: 'guide',
        component: AuthorGuideComponent
      },
      {
        path: 'news',
        component: NewsComponent
      },
      {
        path: 'newsArchive',
        component: NewsArchiveComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'faq/:type',
        component: FaqComponent
      },
      {
        path: 'newsArticle/:articleId',
        component: NewsArticleComponent
      },
      {
        path: 'work/:workIsbn/member/:memberId',
        component: TeamMemberProfileComponent
      },
      {
        path: 'book/:isbn',
        component: BookDetailsComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login/author'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
