import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BannerService } from 'src/app/service/banner.service';
import { UtilitiesService } from 'src/app/service/utilities.service';

@Component({
  selector: 'app-welcome-banner',
  templateUrl: './welcome-banner.component.html',
  styleUrls: ['./welcome-banner.component.scss']
})
export class WelcomeBannerComponent implements OnInit {
  slideInterval;
  constructor(private sanitizer: DomSanitizer, private bannerService: BannerService, private utilityService: UtilitiesService) { }
  banners = [];
  ngOnInit(): void {
    this.getBanners();
    this.slideInterval = 3000;
  }

  getBanners() {
    this.utilityService.showLoader();
    this.bannerService.getBannerMessages().subscribe((result: any) => {
      this.utilityService.hideLoader();
      this.banners = result;
      this.banners.forEach(element => {
        element.secureUrl = this.setUrl(element.sourceURL);
      });
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  setUrl(url) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // return this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin+'/widget/web/guest/hbg-news/-/56_INSTANCE_U69dU6Va2hzB');
  }

  collapseBanner() {
    this.utilityService.showLoader();
    this.bannerService.collapseBanner().subscribe((result: any) => {
      this.utilityService.hideLoader();
      this.banners = [];
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });

  }
}
