<div class="modal-body border-0 choose-auther px-md-5 px-4 py-0">
    <h1 class="text-center border-0 mb-4 mt-4 fs-4">Choose an Author</h1>

    <div class="inner-container">
        <div id="carouselExampleIndicators" *ngIf="showSlider" class="carousel slide" data-bs-ride="carousel">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                (beforeChange)="beforeChange($event)">

                <ng-container ngxSlickItem *ngFor="let slide of slides;let indexOfElement=index">
                    <div class="slide text-center px-md-3 px-2 align-top" title="{{slide.fullName}}">
                        <div (click)="selectedAuthor(slide.authorProfileId)" class="slide">
                            <div class="zoom-effect border p-1">
                                <!-- {{getAuthorPhoto(slide, indexOfElement)}} -->
                                <img src="{{slide.imageUrl}}" alt="" width="100%" class="image fix-height">
                                <span class="preview-only" *ngIf="slide.isPublished == 0">
                                    <img src="assets/images/author-preview-flag.svg">
                                </span>
                            </div>
                            <div class="slide-text">
                                <a href="javascript:void(0)" class="d-inline-block">{{slide.fullName}}</a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ngx-slick-carousel>
            <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true">
                <i class="fas fa-angle-left" (click)="previous()"></i>
            </span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true">
                <i class="fas fa-angle-right" (click)="next()"></i>
            </span>
            <span class="visually-hidden">Next</span>
        </button> -->

        </div>
    </div>

    <div class="border-0 mb-4 mt-2">
        <div class="row">
            <div class="col-md-7">
                <div class="form-group">
                    <input class="form-control search-icon border mb-2 mb-md-0" type="text" placeholder="Search by Name"
                        [(ngModel)]="filterTerm" (keyup)="onKeyUp(filterTerm)" >
                        <!-- (keyup)="onFilterChange(slickModal)" -->
                </div>
            </div>
            <div class="col-md-5 text-center">
                <button class="btn btn-default btn-cancel rounded w-100 border-0 btn-theme-gray"
                    (click)="activeModal.close()">Cancel</button>
            </div>
        </div>
    </div>
</div>