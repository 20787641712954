import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionService } from 'src/app/service/session.service';
import {
  find as _find,
  isPlainObject as _isPlainObject
} from "lodash-es";
import { UtilitiesService } from 'src/app/service/utilities.service';
import { BookSelectorService } from 'src/app/service/book-selector.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-mstr-view',
  templateUrl: './mstr-view.component.html',
  styleUrls: ['./mstr-view.component.scss']
})
export class MstrViewComponent implements OnInit {
  display: any;
  isbn: any;
  lastToken: any;
  checkForToken: boolean;
  previousWork: any;
  subscription: any;
  currentBookFuture: any;
  currentWork: any;
  newToken: any;
  url: string;
  // msUrl: any;

  constructor(private sanitizer: DomSanitizer, private session: SessionService, private utilityService: UtilitiesService, private bookSelectorService: BookSelectorService) {



  }

  displayUrl: any;
  mstrAvailable: any = true;
  oldLoading: any;
  iframe: any;
  loaderStart: any;

  @Input() msURL;
  @Input() dashboard;
  @Input() loading;

  ngOnChanges(changes: SimpleChanges) {
    this.loaderStart = true;
  }

  ngOnInit(): void {
    // this.url = environment.msSalesGlanceUrl + environment.auth;
    this.url = this.dashboard == 'sales-glance' ? environment.msSalesGlanceUrl + environment.auth : environment.msSubrightsUrl + environment.auth;

    if (this.dashboard == 'sales-glance') {
      this.getUrl();
    }

    this.loaderStart = true;

    setTimeout(() => {
      let id = 'mstrIframe_' + this.dashboard;
      this.iframe = document.getElementById(id);
      this.iframe.onload = () => {
        this.loaderStart = false;
      }
    }, 100);

  }

  showIframe = function () {
    this.loading = false;
    this.showLoader = false;
    // $rootScope.$broadcast(this.salesConstants.events.loaderHide);

    // $scope.$apply();
  };

  showLoader = function () {
    this.loading = true;
    this.showLoader = true;
    // $rootScope.$broadcast(this.salesConstants.events.loaderShow);
  };

  setUrl(token) {
    var token = this.session.getMSTRSessionId();
    this.display = true;
    this.msURL = this.display ?
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.url.replace(':primary', this.currentWork?.isbn).replace(':authToken', token)) : '';
  };

  getUrl() {
    // bookSelectorService.selectedIsbns.dashboard[0],
    this.currentWork = this.bookSelectorService.selectedIsbns.dashboard[0];
    this.newToken = this.session.getMSTRSessionId();
    this.setUrl(this.newToken);

    // this.msUrl = this.display ?
    //   this.sanitizer.bypassSecurityTrustResourceUrl(
    //     this.msURL.replace(':primary', currentWork.isbn).replace(':authToken', token)) : '';

    if (this.previousWork !== this.currentWork && _isPlainObject(this.currentWork)) {
      this.previousWork = this.currentWork;

      this.display = true;
      // make sure author ids match before displaying dashboard
      // this.display =
      //   _.some(currentWork.products, [salesConstants.hasSales, 'Y']) && $state.params.authorId ===
      //   currentWork.authorId;

      // _.some(currentWork.products, [salesConstants.hasSales, 'Y']) && $state.params.authorId ===
      // currentWork.authorId && salesService.checkPermission({
      //   isbn: currentWork.isbn,
      //   action: 'sales_data',
      //   items: this.myPermissions.permissionItems,
      //   actions: this.resourceActions
      // });

      this.isbn = this.currentWork.isbn;

      if (!this.checkForToken) {
        this.setUrl(this.lastToken);
      }
    }

    if (this.checkForToken) {
      if (_isPlainObject(this.currentWork) && this.lastToken !== this.newToken) {

        this.setUrl(this.newToken);

        this.lastToken = this.newToken;
        this.checkForToken = false;
      }
    }
  };

  //   this.showLoader();

  //   ngOnInit(): void {
  //     var mstrFrame = {},
  //       iframeSelector = '.dashboard.' + this.dashboard + ' iframe';

  //     // account for different iframes on sales & royalties dashboard
  //     if (this.dashboard === 'sales-glance') {
  //       mstrFrame[this.dashboard] = angular.element('iframe.col-xs-12')[0];
  //     }
  //     else {
  //       mstrFrame[this.dashboard] = angular.element(iframeSelector)[0];
  //     }

  //     mstrFrame[this.dashboard].onload = function () {

  //       loading = false;

  //       showIframe();
  //     };

  //     if (!Session.checkMstrSessionCreation()) {
  //       $rootScope.$on('mstrSessionCreationCompleted', function () {
  //         mstrAvailable = false;
  //       });
  //     }
  //     else {
  //       mstrAvailable = true;
  //     }
  //   };

  // this.$doCheck = function checkIframeUrl() {
  //   var newUrl = this.msUrl,
  //     newLoading = loading;

  //   if (displayUrl !== newUrl) {
  //     if (!Session.checkMstrSessionCreation()) {
  //       $rootScope.$on('mstrSessionCreationCompleted', function () {
  //         mstrAvailable = false;
  //       });
  //     }
  //     else {
  //       mstrAvailable = true;
  //     }
  //     displayUrl = newUrl;
  //   }

  //   if (newLoading !== oldLoading) {
  //     oldLoading = newLoading;
  //     this.showLoader = oldLoading;
  //   }

  //   this.loadMessage = mstrAvailable ? 'Loading Latest Sales Data' : 'There was an error loading the sales data';
  // };

}


