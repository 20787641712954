import { Component, DoCheck, OnInit } from '@angular/core';
import { SelectAuthorComponent } from 'src/app/shared/select-author/select-author.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from 'src/app/service/auth.service';
import { AuthorService } from 'src/app/service/author.service';
import { FormsModule } from '@angular/forms';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from 'src/app/service/session.service';
// import { MstrService } from 'src/app/service/mstr.service';
import {
  find as _find
} from "lodash-es";
import { ActivatedRoute, Router } from '@angular/router';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { AnalyticsService } from 'src/app/service/analytics.service';
// import { ActivatedRouteSnapshot } from '@angular/router';
// import { HTMLElement } from 'node-html-parser';
// import { HtmlParser } from '@angular/compiler';
// import { parse } from 'node-html-parser';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss']
})
export class AuthorComponent implements OnInit, DoCheck {
  loginError: string = "";
  currentUser: any;
  currentYear: number = new Date().getFullYear();
  urlMsg: any;
  constructor(public modalService: NgbModal, public authService: AuthService, public utilityService: UtilitiesService,
    private session: SessionService,
    // private mstrSessionService: MstrService,
    private analyticsService: AnalyticsService,
    private authorService: AuthorService, private activatedRoute: ActivatedRoute, private router: Router) {
    if (!this.errorMessage) {
      this.activatedRoute.queryParams.subscribe(params => {
        let token = params['token'];
        if (token) {
          this.showView('setPassword');
          this.forgotPasswordObject.emailAddress = atob(token);
        }
      })
    }
  }

  credentials = { userName: "", password: "" }
  loader: boolean = false;
  error: boolean = false;
  userRole: any = "";
  errorMessage = "";
  forgotPasswordErrorMessage = "";
  forgotPasswordObject: any = {};
  responseHeading = "";
  responseMessage = "";
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  displayView: { [key: string]: any } = {
    login: true,
    forgotPassword: false,
    responseView: false,
    setPassword: false,
};
  ngOnInit(): void {
    this.getUrlMsg();
    this.analyticsService.pageView('Author Login', 'Page Load');
    this.analyticsService.eventEmitter('Author Login', window.location.href, 'Page Load');
    this.userRole = "author";
    if ( window.location === window.parent.location ) {
      this.session.logout(); 
    }
  }
  getUrlMsg() {
    let url = this.router.url;
    let urlSplitData = url.split("?").length>1 ? url.split("?")[1] : "";
    this.urlMsg  = urlSplitData ? urlSplitData.split("=") : [];

    if (this.urlMsg.length > 0) {
      if (this.urlMsg[0].toString().toLowerCase() == 'success') {
        let messsage = "Password has been updated successfully."
        this.utilityService.showInformation(messsage, 'Info');
      }
    }
  }

  ngDoCheck() {
    this.userRole = "author";
  }

  login() {
    // $ctrl.inProg = true;
    this.loginError = '';
    this.currentUser = null;

    this.utilityService.showLoader();
    this.authService.login(this.credentials).subscribe((res: any) => {
      this.utilityService.hideLoader();
      this.loader = false;
      this.session.setUserRole('author')

      if (!res.isError) {
        let pAuth = res.message;
        this.error = false;
        this.utilityService.showLoader();
        this.analyticsService.eventEmitter('Log In', window.location.href, 'User Login Successful');
        this.authService.getUserInfo(pAuth).subscribe((result: any) => {
          this.utilityService.hideLoader();
          this.currentUser = result;
          this.analyticsService.eventEmitter('Log In User', window.location.href, result.userId);
          localStorage.setItem("userId", result.userId);
          localStorage.setItem("screenName", result.screenName);
          localStorage.setItem("userInfo", JSON.stringify(result));

          this.analyticsService.setUserId(result.userId);

          this.session.create(pAuth);
          this.session.setCurrentUser(JSON.stringify(this.currentUser));

          this.utilityService.showLoader();
          this.authorService.getAuthors().subscribe((result: any) => {
            this.utilityService.hideLoader();
            let authors = result;
            if (authors.length === 0) {
              this.loginError = res.message;
            }
            else {
              let defaultSelection = this.defaultAuthorSelection(authors);
              if (defaultSelection) {
                let userDetail = {
                  screenName: this.currentUser.screenName,
                  password: this.credentials.password
                }

                let selectedAuthor = _find(result, { 'authorProfileId': parseInt(defaultSelection.authorProfileId) });
                if (selectedAuthor) {
                  localStorage.setItem('selectedAuthorData', JSON.stringify(selectedAuthor));
                }

                this.utilityService.showLoader();
                this.utilityService.hideLoader();
                this.router.navigate(['navigation', defaultSelection.authorProfileId, 'dashboard']);

              }
              else {
                const modalRef = this.modalService.open(SelectAuthorComponent, {
                  centered: true,
                  size: "md",
                  windowClass: "animated fadeIn"
                });
                modalRef.componentInstance.userDetail = {
                  screenName: this.currentUser.screenName,
                  password: this.credentials.password,
                }
                modalRef.componentInstance.authors = authors;
                
                modalRef.result.then((result) => {
                  if (!result) {
                    this.session.logout();
                  }
                }, err => {
                  // this.session.logout();
                });
              }
            }

            this.utilityService.hideLoader();
          }, err => {
            this.utilityService.hideLoader();
            this.error = true;
            this.loginError = err.error.message;
          });
          localStorage.setItem( 'adf-auth', 'Basic ' + btoa( this.currentUser.screenName + ':' + this.credentials.password ) );
          localStorage.setItem( 'ACS_USERNAME', this.currentUser.screenName );
          localStorage.setItem( 'USER_PROFILE', this.currentUser.screenName );
          // $cookies.put( 'ALFRESCO_REMEMBER_ME', '1', { path: '/', 'expires': new Date().getDate + 10 } );

        }, err => {
          this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

        });
      } else {
        this.utilityService.hideLoader();
        this.error = true;
        this.loginError = res.message;
      }

      // this.agencyAdmins = result;
    }, err => {
      this.utilityService.hideLoader();
      // this.loading = false;
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
    });
  }

  defaultAuthorSelection(authors) {
    let selection = null;
    if (authors.length === 1) {
      return authors[0];
    }
    else if (authors.length > 1) {
      selection = _find(authors, (item) => {
        return item.id === this.currentUser.userId;
      });
    }

    return selection;
  };

  

  LoginConstants = {
    errorMessageElementSelector: '#authenticationfail > div',
    errorMessageElementSelectorNew: 'id="authenticationfail"> <div',
    loginFormPath: '/web/guest/home?p_p_id=58&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&p_p_col_id=column-1&p_p_col_count=1&_58_struts_action=/login/login',
    resetPasswordFormPath: '/web/guest/home?p_p_id=58&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&p_p_col_id=column-1&p_p_col_count=1&_58_struts_action=/login/forgot_password',
    oneSecond: 1000,
    loginErrorNoEmailOrPassword: 'Please enter both your email address and password.',
    authorForgotPwdEmail: 'HBG-AuthorPortalSupport@hbgusa.com',
    agentForgotPwdEmail: 'HBG-AgentPortalSupport@hbgusa.com',
    agentRole: 'agent',
    networkErrorMessage: 'Network Error.  Please contact HBG-AuthorPortalSupport@hbgusa.com'
  };
  onKeyUp(event: any){
    if(event.key == "Enter" && this.validateLogin()){
      this.login();
    }
  }
  validateLogin(){
    return this.credentials.userName && this.credentials.password;
  }

  showView(type) {
    this.errorMessage = "";
    this.forgotPasswordErrorMessage = "";
    this.forgotPasswordObject.emailAddress = "";

    Object.keys(this.displayView).forEach(key => {
      let view = key === type ? true : false;
      this.displayView[key] = view;
    });
  }

  public validateEmailAddress() {
    this.forgotPasswordErrorMessage = "";
    if (!this.forgotPasswordObject.emailAddress) {
      this.forgotPasswordErrorMessage = "Email Address is required";
    } else if (!this.verifyEmailPattern()) {
      this.forgotPasswordErrorMessage = "Please enter a valid email address.";
    }
    return this.forgotPasswordErrorMessage == "" ? true : false;
  }

  public verifyEmailPattern() {
    return this.emailPattern.test(this.forgotPasswordObject.emailAddress)
  }

  public submitResetPassword() {
    if (this.validateEmailAddress()) {
      this.utilityService.showLoader();
      this.authorService.submitResetRequest(this.forgotPasswordObject.emailAddress).subscribe((result: any) => {
        this.utilityService.hideLoader();
        this.responseHeading = "Success!";
        let successString = this.displayView['setPassword'] ? "setting" : "resetting";
        this.responseMessage = "Please check your email at " + this.forgotPasswordObject.emailAddress + " for more information on " + successString + " your password."
        this.showView('responseView');

      }, err => {
        this.utilityService.hideLoader();
      });
    }
  }

  public loadLogin() {
    this.showView('login');
    this.router.navigate(['/']);
  }

  getTermOfUser() {
    const modalRef = this.modalService.open(TermsOfUseComponent, {
      centered: true,
      size: "md",
      windowClass: "animated fadeIn"
    });
  }
}
