
export const authorGuideConstants: any = {

        authorGuideURL1: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s029%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter29%5D%2F2%5Btitle-block29%5D%2F2%5Bh128%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL2: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s030%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter30%5D%2F2%5Btitle-block30%5D%2F2%5Bh129%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL3: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s006%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter8%5D%2F2%5Btitle-block8%5D%2F2%5Bh15%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL4: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s008%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter10%5D%2F2%5Btitle-block10%5D%2F2%5Bh17%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL5: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s010%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter12%5D%2F2%5Btitle-block12%5D%2F2%5Bh19%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',


        authorGuideURL6: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s030%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter30%5D%2F2%5Btitle-block30%5D%2F2%5Bh129%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL7: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s031%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter31%5D%2F2%5Btitle-block31%5D%2F2%5Bh130%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL8: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s031%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter31%5D%2F2%5Btitle-block31%5D%2F2%5Bh130%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL9: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s031%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter31%5D%2F2%5Btitle-block31%5D%2F2%5Bh130%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL10: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s006%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter8%5D%2F2%5Btitle-block8%5D%2F2%5Bh15%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL11: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s008%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter10%5D%2F2%5Btitle-block10%5D%2F2%5Bh17%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL12: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s010%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter12%5D%2F2%5Btitle-block12%5D%2F2%5Bh19%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL13: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s010%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter12%5D%2F2%5Btitle-block12%5D%2F2%5Bh19%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',



        authorGuideURL14: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s031%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter31%5D%2F2%5Btitle-block31%5D%2F2%5Bh130%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL15: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s017%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter19%5D%2F2%5Btitle-block19%5D%2F2%5Bh116%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL16: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s031%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter31%5D%2F2%5Btitle-block31%5D%2F2%5Bh130%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL17: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s014%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter16%5D%2F2%5Btitle-block16%5D%2F2%5Bh113%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL18: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s016%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter18%5D%2F2%5Btitle-block18%5D%2F2%5Bh115%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',


        authorGuideURL19: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s010%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter12%5D%2F2%5Btitle-block12%5D%2F2%5Bh19%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL20: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s016%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter18%5D%2F36%5Bp46%5D%2C%2F1%3A60%2C%2F1%3A61%22%7D&',


        authorGuideURL21: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s017%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter19%5D%2F2%5Btitle-block19%5D%2F2%5Bh116%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL22: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s032%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter32%5D%2F2%5Btitle-block32%5D%2F2%5Bh131%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',



        authorGuideURL23: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s018%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter20%5D%2F2%5Btitle-block20%5D%2F2%5Bh117%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL24: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s016%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter18%5D%2F2%5Btitle-block18%5D%2F2%5Bh115%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL25: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s017%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter19%5D%2F2%5Btitle-block19%5D%2F2%5Bh116%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL26: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s015%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter17%5D%2F2%5Btitle-block17%5D%2F2%5Bh114%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',

        authorGuideURL27: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s015%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter17%5D%2F2%5Btitle-block17%5D%2F2%5Bh114%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL28: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s016%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter18%5D%2F2%5Btitle-block18%5D%2F2%5Bh115%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',


        authorGuideURL29: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s018%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter20%5D%2F2%5Btitle-block20%5D%2F2%5Bh117%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',
        authorGuideURL30: location.protocol + '//' + location.host + '/epub/?epub=' + location.protocol + '//' + location.host + '/epub/HBG-Author-Guide&goto=%7B%22idref%22%3A%22s018%22%2C%22elementCfi%22%3A%22%2F4%2F2%2F2%5BChapter20%5D%2F2%5Btitle-block20%5D%2F2%5Bh117%5D%2C%2F1%3A0%2C%2F1%3A1%22%7D&',

}