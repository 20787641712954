<!-- {{systemMessage}} 
-->
<div class="system-banner p-3 align-items-center d-flex w-100" *ngIf="systemMessage">
    <div class="col-md-12">
        <h5 class="w-100 text-center mb-0 position-relative">{{systemMessage}}
            <a class=" position-absolute" (click)="closeSystemMessage()">
                <i class="fas fa-times"></i>
            </a>
        </h5>
    </div>
</div>
<header class="headertop">
    <div class="container">
        <nav class="navbar header-top navbar-expand-lg navbar-dark p-lg-0">
            <a class="navbar-brand d-flex mr-lg-0" [routerLink]="['/navigation', selectedAuthorId,'dashboard']">
                <img src="assets/images/site-logo.svg" width="150" alt="LOGO">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-lg-auto d-lg-table w-100">
                    <li routerLinkActive="active" class="nav-item d-table-cell" (click)="headerClick('Dashboard')">
                        <a class="nav-link" [routerLink]="['/navigation', selectedAuthorId,'dashboard']">Dashboard</a>
                    </li>

                    <li routerLinkActive="active" (click)="headerClick('Author and Illustrator QA');"
                        [class.disabled]="!pagePermission?.canViewAq" class="nav-item d-table-cell">
                        <a class="nav-link" [routerLink]="['/navigation', selectedAuthorId,'questionnaire', 'aboutYou']">Author and <br>Illustrator QA</a>
                    </li>

                    <li routerLinkActive="active" class="nav-item d-table-cell" (click)="headerClick('Sales Data');">
                        <a class="nav-link" [routerLink]="['/navigation', selectedAuthorId,'salesDashboard']">Sales Data</a>
                    </li>

                    <li routerLinkActive="active" class="nav-item d-table-cell" *ngIf="pagePermission?.canViewRoyalties">
                        <a class="nav-link" [routerLink]="['/navigation', selectedAuthorId,'sales']"
                            (click)="headerClick('Royalties and Unit Ledgers');">Royalties and Unit Ledgers</a>
                    </li>

                    <li routerLinkActive="active" class="nav-item d-table-cell" (click)="headerClick('News and Resources');">
                        <a class="nav-link px-4" [routerLink]="['/navigation', selectedAuthorId,'news']">News and <br>Resources</a>
                    </li>
                    <li class="nav-item d-table-cell current-author">
                        <a class="nav-link dropdown-toggle justify-content-start ps-4" data-toggle="dropdown" href="#"
                            role="button" aria-haspopup="true" aria-expanded="false">
                            <span *ngIf="userInitials" id="profile-settings" class="initials">{{userInitials |
                                uppercase}}</span>
                            My Account
                        </a>
                        <div class="dropdown-menu py-4 text-center">
                            <p>Welcome,
                                <br> {{loggedInUser?.firstName}} {{loggedInUser?.lastName}}
                            </p>
                            <button *ngIf="showChangePassword" type="button"
                                class="btn pull-right btn-theme-blue w-75 mb-2" (click)="changePassword(); headerClick('Change Password');"> Change
                                Password </button>
                            <button type="button" class="btn pull-right btn-theme-blue w-75" (click)="logout(); headerClick('Logout');"> Logout
                            </button>
                        </div>
                    </li>

                </ul>
            </div>
        </nav>
    </div>
</header>


<app-view-author></app-view-author>