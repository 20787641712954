import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorService } from 'src/app/service/author.service';
import { ChooseDiffBookModalComponent } from 'src/app/shared/choose-diff-book-modal/choose-diff-book-modal.component';
import { slice, isEmpty, remove, forEach, findIndex, find, pull, uniq, cloneDeep, sortBy, clone, isEqual, filter } from 'lodash-es';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { AuthService } from 'src/app/service/auth.service';
import { AnalyticsService } from 'src/app/service/analytics.service';

@Component({
  selector: 'app-hbg-team',
  templateUrl: './hbg-team.component.html',
  styleUrls: ['./hbg-team.component.scss']
})
export class HbgTeamComponent implements OnInit {
  currentBook: any;
  base64Image: any;
  teamList: any = [];
  defaultImage: any = "assets/images/author-user-image-default.svg";
  teamMemberPhotos: any = [];
  bookSubscriber: any;
  teamLoader = false;
  @Input()
  workList: any = [];


  constructor(public modalService: NgbModal, public auth: AuthService, private analyticsService: AnalyticsService, 
    public utilityService: UtilitiesService,
    private authorService: AuthorService) {
    // this.bookSubscriber = this.utilityService.getSelectedBook().subscribe(message => {
    //   if (message) {
    //     this.currentBook = message;
    //     this.getTeamDetails();
    //   } 
    // });
  }

  ngOnInit(): void {
    if (this.workList && this.workList.length > 0) {
      this.currentBook = this.workList[0];
      this.getTeamDetails();
    } else {
      this.teamList = [];
    }
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.bookSubscriber.unsubscribe();
  }
  // Choose different book
  choose() {
    const modalRef = this.modalService.open(ChooseDiffBookModalComponent, {
      centered: true,
      size: "xl",
      windowClass: "animated fadeIn"
    });
    modalRef.componentInstance.authorWorks = this.workList;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (this.currentBook.isbn !== receivedEntry.isbn) {
        this.currentBook = receivedEntry;
        this.getTeamDetails();
      }

    })
  }

  getTeamDetails() {
    this.teamList = [];
    this.teamMemberPhotos = [];
    this.teamLoader = true;
    this.utilityService.showLoader();
    this.authorService.getTeamMemberInfo(this.currentBook.isbn).subscribe((data: any) => {
      this.utilityService.hideLoader();
      if (data) {
        this.teamLoader = false;
        this.teamList = data;
        this.teamList.forEach(element => {
          this.loadUserPhoto(element.emailAddress);
        });
      }
    }, err => {
      this.utilityService.hideLoader();
      this.teamLoader = false;
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }
  loadUserPhoto(emailAddress?: any) {
    if (!emailAddress) {
      return this.defaultImage;
    }
    this.authorService.getUserPhoto(emailAddress).subscribe((result: any) => {
      if (result.data != "null") {
        this.teamMemberPhotos.push({ 'emailAddress': emailAddress, 'photo': 'data:image/jpg;base64,' + result.data })
      }
    }, err => {
    });
  }

  getUserPhoto(emailAddress?: any) {
    const results = filter(this.teamMemberPhotos, function (item) {
      return item.emailAddress === emailAddress;
    });
    return results && results.length > 0 ? results[0].photo : this.defaultImage
  }

  loadTeamDetails(workList: any) {
    this.workList = workList;
    if (this.workList && this.workList.length > 0) {
      this.currentBook = this.workList[0];
      this.getTeamDetails();
    }
  }

  public gaClick(eventAction: string, event?: any) {
    
    eventAction = 'Dashboard - ' + eventAction;
    this.analyticsService.eventEmitter(eventAction, window.location.href, event);
  }

}





