<h4 class="back-line-txt text-center my-3"><span class="">YOUR BOOK SALES</span></h4>
<div class="row">
    <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
        <div class="d-md-flex d-block">
            <div class="flex-shrink-0 text-md-start text-center mb-3">
                <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                    <img alt="" class="fix-height" src="">
                </div>
            </div>
            <div class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                <h1 class=" mb-3 extra-size">The Day That Went Missing</h1>
                <h4 class="lh-base">by Richard Beard</h4>
                <p class="lh-base">On Sale November 6, 2018</p>

            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xl-6 text-md-start text-center">
        <p class="lh-base">Here you can see weekly and monthly sales for your book(s) by channel (the type of retailer)
            or by format (e.g. hardcover, eBook, etc.). On the Lifetime Performance page, you can see overall gross and
            net sales, as well as information about printings and copies shipped. Sales figures in the Portal are
            refreshed each Wednesday. Note that the weekly and monthly data is “point of sale” (POS) —info reported to
            us by participating accounts and does not include certain sales, such as those to libraries or special bulk
            purchases. If you have questions about the figures displayed here (including why certain numbers may appear
            lower or higher than you expected), please read our Frequently-Asked-Questions (FAQ) page by clicking the
            link below. As always, please consult your royalty statement for the most comprehensive information on your
            sales.</p>
            <a href="#">Read our Sales FAQ <i class="fas fa-chevron-right ms-2"></i></a>
    </div>
</div>