<div class="row d-block">
    <div class="col">
        <div id="loadcontainer" class="w-100" *ngIf="loaderStart">
            <div class="d-flex align-items-center h-100">
                <div class="d-flex flex-column w-100">
                    <!-- Loader -->
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <!-- Loading text -->
                    <div class="loadtext mt-2">Loading Latest Sales Data...</div>
                </div>
            </div>

        </div>
        <!-- <ng-container *ngIf="!msURL"> -->
        <iframe id="mstrIframe_{{dashboard}}" class="col-md-12 mstrIframe" width="100%" height="100%" frameBorder="0" [src]="msURL"></iframe>
        <!-- </ng-container> -->
    </div>
</div>