import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-royalties-tab',
  templateUrl: './royalties-tab.component.html',
  styleUrls: ['./royalties-tab.component.scss']
})
export class RoyaltiesTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
