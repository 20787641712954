import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { salesConstants } from '../shared/constants/salesConstants';
import { AuthService } from './auth.service';
import * as _ from "lodash-es";
import { bookSelectorConstants } from '../shared/constants/bookSelectorConstants';

@Injectable({
  providedIn: 'root'
})
export class BookSelectorService {
  royaltiesIsbns: any[];
  subrightsIsbns: any;
  selectedBookIsbn: any;
  config: any;
  isbns: any;
  allowMultiSelect: any;
  clearThese = ['allIsbns', 'selectedIsbns'];

  constructor(private auth: AuthService) {

    // this.auth.selectedAuthor.subscribe(result => {
    //   this.bindAuthorData();
    //   this.getBooks();
    // });
  }

  selectSparseIsbns(works, flag, mode) {
    var sparse = {},
      array;

    // array = _.chain(works).

    //   forEach((work) => {
    //     sparse[work.isbn] = _.reduce(work.products, (sum, product) => {
    //       return Boolean(sum || product[flag] === 'Y');
    //     }, work.products[0][flag] === 'Y');
    //   }).

    //   filter(work => {
    //     work.authorId = this.auth.getSelectedAuthor();

    //     return sparse[work.isbn];
    //   }).
    //   value();

    works = _.forEach(works, (work) => {
      sparse[work.isbn] = _.reduce(work.products, (sum, product) => {
        return Boolean(sum || product[flag] === 'Y');
      }, work.products[0][flag] === 'Y');
    });

    array = _.filter(works, work => {
      work.authorId = this.auth.getSelectedAuthor();

      return sparse[work.isbn];
    })
    
      // set initial book
    this.selectedIsbns[mode] = array[0];

    return array;
  };

  setInitialIsbns(isbns) {
    var validIsbns = [],
    
    isbns = _.map(isbns, (work, key) => {
      work.products = _.remove( work.products, (product) => {
            return product[salesConstants.hasSales] === 'Y';
          });
      work.products = _.sortBy( work.products,['onSaleDate']);
      work.idx = key;

      return work;
    });
    isbns = _.filter(isbns, (w) => {
      if (w.products.length > 0) {
        w.onSaleDate = w.products[0].onSaleDate;

        return moment(w.products[0].onSaleDate).isBefore(
          moment());
      }
    });
    isbns = _.sortBy(isbns,['onSaleDate']);
    validIsbns = _.reverse(isbns);
    
    this.allIsbns = validIsbns;
    this.royaltiesIsbns = [];

    // determine royalties availability
    this.royaltiesIsbns = this.selectSparseIsbns(this.allIsbns, 'royaltyFlag', 'royalties');

    // determine subrights availability
    this.subrightsIsbns = this.selectSparseIsbns(this.allIsbns, 'subRightsFlag', 'subrights');

    if (this.selectedBookIsbn && _.find(validIsbns, { isbn: this.selectedBookIsbn })) {
      _.forEach(this.selectedIsbns, (val, key) => {
        this.selectedIsbns[key] = [_.find(validIsbns, { isbn: this.selectedBookIsbn })];
      });
    }
    else {
      // set all books to initial book
      _.forEach(this.selectedIsbns, (val, key) => {
        this.selectedIsbns[key] = [validIsbns[0]];
      });
    }

    // $rootScope.$broadcast('new author');
  };

  resetIsbn() {
    this.selectedBookIsbn = null;
    this.setInitialIsbns(this.allIsbns);
  };

  allIsbns = [];

  selectedIsbns = bookSelectorConstants.default_isbns;

  selectIsbn(isbn, key) {
    this.selectedBookIsbn = isbn;
    this.selectedIsbns[key] = _.find(this.allIsbns, { isbn: isbn }) ?
      [_.find(this.allIsbns, { isbn: isbn })] :
      this.selectedIsbns[key];
  };

  openSelector(init) {
    this.config = init;
    this.isbns = _.isArray(init.isbns) ? init.isbns : this.allIsbns;

    this.allowMultiSelect = init.allowMultiSelect;
    this.openComponentModal();
  };

  openSelectorPermissions(init) {
    this.config = init;
    this.isbns = _.isArray(init.isbns) ? init.isbns : this.allIsbns;
    this.allowMultiSelect = init.allowMultiSelect;

    // return $uibModal.open({
    //   animation: true,
    //   component: 'bookListComponent',
    //   size: this.isbns.length < bookSelectorConstants.large_selector_threshold ? 'md' : 'lg',
    //   resolve: {
    //     isbns: function () {
    //       return this.isbns;
    //     },
    //     isbnsTeam: function () {
    //       return init.isbnsTeam;
    //     },
    //     allowMultiSelect: this.allowMultiSelect
    //   }
    // });
  };

  // getProducts(demo) {
  //   if (demo) {
  //     this.allIsbns = _.take(ENV.Environmentthis.config.works, ENV.Environmentthis.config.debug_item_limit);
  //     this.setInitialIsbns(this.allIsbns);
  //   }
  //   else {
  //     this.getProductWorks();
  //   }
  // };

  openComponentModal = function () {
    // var selection = $uibModal.open( {
    //   animation: true,
    //   component: 'bookListComponent',
    //   size: this.isbns.length < bookSelectorConstants.large_selector_threshold ? 'md' : 'lg',
    //   resolve: {
    //     isbns: function () {
    //       return this.isbns;
    //     },
    //     allowMultiSelect: this.allowMultiSelect
    //   }
    // } );

    // selection.result.then( function ( selected ) {
    //   this.selectedIsbns[ this.config.caller ] = selected;
    // } );
  };

  // $rootScope.$on( bookSelectorConstants.events.ISBNS_LOADED, function () {
  //   setInitialIsbns( this.allIsbns );
  // } );

  // $rootScope.$on( 'changed author', function () {
  //   currentAuthorId = $state.params.authorId;

  //   _.forEach( clearThese, function ( key ) {
  //     $ctrl[ key ] = [];
  //   } );

  //   this.selectedIsbns = bookSelectorConstants.default_isbns;

  //   this.getProducts( demo );
  // } );

  getProductWorks = function () {
    // return $http( {
    //   method: 'GET',
    //   url: ENV.API_BASE_URL + '/ods-portlet.work/works',
    //   cache: true,
    //   params: {
    //     authorId: $state.params.authorId
    //   }
    // } ).then( function ( products ) {
    //   this.allIsbns = products.data;

    //   setInitialIsbns( this.allIsbns );
    //   $rootScope.$broadcast( bookSelectorConstants.events.ISBNS_LOADED );
    // }, function () {
    //   this.allIsbns.push( bookSelectorConstants.empty_isbn );
    //   setInitialIsbns( this.allIsbns );
    // } );

    this.authService.getWorks().subscribe((result: any) => {
      this.allIsbns = result;

      this.setInitialIsbns(this.allIsbns);
      // $rootScope.$broadcast( bookSelectorConstants.events.ISBNS_LOADED );
    }, err => {
      this.allIsbns.push(bookSelectorConstants.empty_isbn);
      this.setInitialIsbns(this.allIsbns);
    });
  };

  // getProducts( demo );
}
