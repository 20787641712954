import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Subject } from 'rxjs';
import httpStatusConstants from '../constants/httpStatusConstants';
import { AuthService } from './auth.service';
import { AuthorService } from './author.service';
import { BannerService } from './banner.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  store = localStorage;
  showSystemMessage: boolean = true;
  private questionnaireChanges = new Subject;
  questionnaireObs = this.questionnaireChanges.asObservable();

  constructor(private authorService: AuthorService, private router: Router,
    private authService: AuthService, private utilityService: UtilitiesService) { }

  create(pAuth) {
    this.store.pAuth = pAuth;
  };

  destroyGoogleAnalyticsSession() {
    // $analytics.settings.ga = { userId: '' };
    // ga('set', 'userId', '');
    // ga('send', 'pageview');
  };

  getPAuth() {
    return this.store.pAuth;
  };

  getCurrentUserId() {
    let userInfo: any = JSON.parse(this.getCurrentUser())
    return userInfo ? userInfo.userId : null;
  }

  setCurrentUser(userInfo) {
    this.store.currentUserInfo = userInfo;
  };

  getCurrentUser() {
    return this.store.currentUserInfo;
  };

  getCurrentAuthor() {
    return this.store.currentAuthor;
  }

  setCurrentAuthor(author: any) {
    return this.store.currentAuthor = author;
  }

  setMSTRSessionId(sessionId) {
    this.store.mstrSessionID = sessionId;
    this.store.mstrSessionCreationCompleted = true;
  };

  setMstrSessionCreation(mstrSessionCreationCompleted) {
    this.store.mstrSessionCreationCompleted = mstrSessionCreationCompleted;
  };

  checkMstrSessionCreation() {
    return this.store.mstrSessionCreationCompleted;
  };

  getMSTRSessionId() {
    return this.store.mstrSessionID;
  };

  setUserRole(role) {
    this.store.userRole = role;
  };

  getUserRole() {
    return this.store.userRole || 'author';
  };

  isLoggedIn() {
    var loggedIn = false;
    if (this.getPAuth()) {
      loggedIn = true;
    }

    return loggedIn;
  };

  setSelectedIsbns(caller, selections) {
    this.store.selectedIsbns[caller] = selections;
  };

  getSelectedIsbnsgetSelectedIsbns(widget) {
    return this.store.selectedIsbns[widget] || {};
  };



  destroySession() {
    this.utilityService.hideLoader(true);
    this.router.navigate(['']);
    this.destroyGoogleAnalyticsSession();
    this.authorService.clearCache();
    this.authService.clearCache();
    localStorage.clear();
  }

  destroy() {
    this.destroyLiferaySession();

    return false;

    var mstrSession = this.getMSTRSessionId();

    if (mstrSession) {
      this.authService.destroyMSTRsession(this.getMSTRSessionId()).subscribe((result: any) => {
        this.destroyLiferaySession();
      }, err => {
        this.destroyLiferaySession();
      });
    } else {
      this.destroyLiferaySession();
    }
  }

  destroyLiferaySession() {
    this.authService.destroyLiferaySession(true).subscribe((result: any) => {
      this.destroySession();
    }, err => {
      this.destroySession();
    });
  }

  logout() {
    this.destroy();
    // $state.go('login.' + this.getUserRole());
  };

  checkLiferaySession() {

  }

  public validateSession(resolve, reject, state): Promise<boolean> {
    this.utilityService.showLoader();
    return this.authService.checkLiferaySession().toPromise()
      .then(res => {
        this.utilityService.hideLoader();
        return resolve(true);
      }).catch(err => {
        this.utilityService.hideLoader();
        if (err.status === httpStatusConstants.FORBIDDEN) {
          this.router.navigate(['']);
          return reject(false);
        }
        return reject(false);
      });
  }
  questionnaireChangesOnLogout() {
    this.questionnaireChanges.next();
  }
}
