import { HttpClient, HttpHeaders } from '@angular/common/http';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
// import { filter } from 'lodash';
import { Observable, Subject, forkJoin, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { UtilitiesService } from 'src/app/service/utilities.service';
import { environment } from 'src/environments/environment';
import httpStatusConstants from '../constants/httpStatusConstants';
// import * as CryptoJS from 'crypto-js';
// import { AnalyticsService } from './analytics.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  

  private P_AUTH = 'pauth';
  private USER_ID = 'userId';
  private Screen_Name = 'screenName';
  private Selected_Author = 'selectedAuthorData';
  authorsList: any;
  settings: any;
  userPreferences: any;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) {
    router.events.subscribe((event) => {

    });
  }

  public getPauth() { return localStorage.getItem(this.P_AUTH); }
  public getUserId() { return localStorage.getItem(this.USER_ID); }
  public getScreenName() { return localStorage.getItem(this.Screen_Name); }
  public getSelectedAuthor() { 
    if(!localStorage.getItem(this.Selected_Author)) {
      return null;
    }
    return JSON.parse(localStorage.getItem(this.Selected_Author))['authorProfileId']; 
  }
  
  public getSelectedAuthorData() { return JSON.parse(localStorage.getItem(this.Selected_Author)); }

  login(credentials: any) {
    const formData = new FormData();
    formData.append('_58_login', credentials.userName.trim());
    formData.append('_58_password', credentials.password.trim());

    // var headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   responseType: 'text'
    // };

    var httpOptions = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With' : 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*'
      },
      withCredentials: true,
      responseType: 'text'
    };

    var url = environment.pluginUrl + "/web/guest/home?p_p_id=58&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&p_p_col_id=column-1&p_p_col_count=1&_58_struts_action=/login/login";

    // return this.http.post(url, formData, {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'X-Requested-With' : 'XMLHttpRequest'
    //   },
    //   responseType: 'text',
    //   withCredentials: true
    // }).pipe(map((res) => {
    //   return this.loginResponse({ 'data': res });
    //   // return res;
    // }));

    // return this.http.post<any>(url, formData, ({ responseType: 'text' } as any));

    return this.http.post<any>(url, formData, ({ responseType: 'text' } as any)).pipe(map((res) => {
      return this.loginResponse({ 'data': res });
      return res;
    }));;
  }

  loginResponse(res: any) {
    var pAuth,
      error,
      pAuthString;

    var parser = new DOMParser();
    var doc = parser.parseFromString(res.data.toString(), 'text/html');
    var errorCount = doc.body.getElementsByClassName('alert alert-error');

    if (errorCount && errorCount.length > 0) {
      var result = errorCount[0]['innerText'];
     
      return {
        isError: true,
        message: result
      }
      // return false;
    }
    else {
      pAuthString = res.data.match(/\?p_auth=[^&]*/) ? res.data.match(/\?p_auth=[^&]*/)[0] : res.data.match(/\p_auth=[^&]*/)[0].split(' ')[0];
      pAuth = pAuthString.split('p_auth=')[1];
      localStorage.setItem("pauth", pAuth);
      return {
        isError: false,
        message: pAuth
      }
    }
  }



  private selectedAuthorChange: any = new Subject();
  selectedAuthor = this.selectedAuthorChange.asObservable();

  changeSelectedAuthor() {
    this.selectedAuthorChange.next(true);
  }

  private defaultAuthorChange: any = new Subject();
  defaultselectedAuthor = this.defaultAuthorChange.asObservable();

  defaultChangeSelectedAuthor() {
    this.defaultAuthorChange.next(true);
  }

  serialize(obj: any): URLSearchParams {
    let params: URLSearchParams = new URLSearchParams();

    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        var element = obj[key];

        params.set(key, element);
      }
    }
    return params;
  }

  getUserInfo(pAuth) {
    var url = environment.pluginUrl + "/api/jsonws/user/get-current-user";
    return this.http.get(url);
  }

  // getAuthors() {
  //   var auth = this.getPauth();
  //   var url = environment.pluginUrl + "/api/jsonws/AuthorSelect-portlet.userdetail/get-all-authors";
  //   if (this.authorsList) {
  //     return of(this.authorsList);
  //   }
  //   return this.http.get(url, {
  //     params: {
  //       'authorId': this.getUserId()
  //     }
  //   }).pipe(map((res) => {
  //     this.authorsList = res;
  //     return res;
  //   }));
  // }

  // session(credentials: any) {
  //   const formData = new FormData();
  //   formData.append('userId', credentials.screenName);
  //   formData.append('password', credentials.password);
  //   var url = environment.pluginUrl + "/api/jsonws/Microstrategy-portlet.micros/session";
  //   return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  // }

  // getWorks() {
  //   var url = environment.pluginUrl + "/api/jsonws/ods-portlet.work/works?authorId=" + this.getSelectedAuthor();
  //   return this.http.get(url);
  // }

  getQuestionares(questionnaireId: any) {
    var url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.category/get-metadata?authorId=" + this.getSelectedAuthor()+ "&questionnaireId=" + questionnaireId;
    return this.http.get(url);
  }

  getBookQuestionares(params: any) {
    var url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.category/get-metadata?authorId=" + this.getSelectedAuthor()+ "&questionnaireId=" + params.questionnaireId +"&scopeId=" + params.scopeId + "&scopeType=" + params.scopeType;
    return this.http.get(url);
  }

  getContactsQuestionares(params: any) {
    var url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.contact/get-contacts?authorId=" + this.getSelectedAuthor()+ "&scopeId=" + params.scopeId + "&scopeType=" + params.scopeType;
    return this.http.get(url, params);
  }

  // saveContactsQuestionares(params: any) {
  //   // var url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.contact/save-contact";
  //   // return this.http.get(url, params);

  //   const formData = new FormData();
  //   formData.append('userId', params.screenName);
  //   formData.append('password', params.password);
  //   var url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.contact/save-contact";
  //   return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  // }

  public saveContactsQuestionares(formContact: any) {
    formContact['authorId'] = this.getSelectedAuthor();
    const formData = new FormData();
    formData.append('json', JSON.stringify(formContact));
    var url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.contact/save-contact";
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  public deleteContact(contactId) {
    const formData = new FormData();
    formData.append('contactId', contactId);
    formData.append('authorId', this.getSelectedAuthor());
    var url = environment.pluginUrl + "/api/jsonws/QuestionnairePortlet-portlet.contact/delete-contact";
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  getSettings() {
    var url = environment.pluginUrl + "/api/jsonws/happi-common-service-portlet.happisetting/setting";
    if (this.settings && this.settings.length > 0) {
      return of(this.settings);
    }
    return this.http.get(url).pipe(map((res) => {
      this.settings = res;
      return res;
    }));
  }
  getAllUserPreferences() {
    var url = environment.pluginUrl + "/api/jsonws/happi-common-service-portlet.happidisplaypreferences/get-display-Preferences?userId="+ this.getUserId();
    
    return this.http.get(url).pipe(map((res) => {
      this.userPreferences = res;
      return res;
    }));
  }
  saveUserPreferences(formData : any) {  
    var url = environment.pluginUrl + "/api/jsonws/happi-common-service-portlet.happidisplaypreferences/add-display-Preferences";
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }
  
  public loadAuthorHttpOptions() {
    return {
      'headers': new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',       
      }),
    };
  }
  clearCache() {
    this.settings = [];
  }

  destroyMSTRsession(sessionId: any) {
    // const formData = new FormData();
    // formData.append('userId', credentials.screenName);
    // formData.append('password', credentials.password);
    var url = environment.pluginUrl + "/api/jsonws/Microstrategy-portlet.micros/session-destroy";
    return this.http.get(url, {
      params: {
        'sessionId': sessionId
      }
    });
    return this.http.get<any>(url, ({ responseType: 'text' } as any));
  }


  destroyLiferaySession(ignoreLoadingBar) {
    var url = environment.pluginUrl + '/c/portal/logout';
    return this.http.get<any>(url, ({ responseType: 'text' } as any));

    // return $http( {
    //   method: 'GET',
    //   ignoreLoadingBar: ignoreLoadingBar,
    //   url: ENV.APP_SERVER_BASE_URL + '/c/portal/logout'
    // } );
  };

  checkLiferaySession() {
    var url = environment.pluginUrl + '/api/jsonws/portal/get-build-number';
    return this.http.get(url);

    // $http( {
    //   method: 'GET',
    //   url: ENV.API_BASE_URL + '/portal/get-build-number'
    // } ).then( function () {
    //   deferred.resolve();
    //   // No requirement
    // }, function ( res ) {
    //   if ( res.status === httpStatusConstants.FORBIDDEN ) {
    //     Session.logout();
    //     deferred.reject();
    //   }
    //   else {
    //     deferred.resolve();
    //   }
    // } );
  };

  getBookDetails(isbn) {
    var url = environment.pluginUrl + "/api/jsonws/ods-portlet.work/product?authorId=" + this.getSelectedAuthor() + "&isbn=" + isbn;
    return this.http.get(url);
  }

  getFormats(isbn) {
    var url = environment.pluginUrl + "/api/jsonws/ods-portlet.work/work/isbn/"+ isbn+ "?p_auth=" + this.getPauth() + "&check=true";
    return this.http.get(url);
  }
 

}

