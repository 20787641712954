export const salesConstants = {
    tabs: [
        {
          title: 'Your Book Sales',
          header: 'Sales: Introduction',
          FAQLink: 'Read our Sales FAQ',
          FAQSection: 'sales',
          text: 'Here you can see weekly and monthly sales for your book(s) by channel (the type of retailer) or ' +
                'by format (e.g. hardcover, eBook, etc.). On the Lifetime Performance page, you can see overall ' +
                'gross and net sales, as well as information about printings and copies shipped. Sales figures ' +
                'in the Portal are refreshed each Wednesday. Note that the weekly and monthly data is ' +
                '“point of sale” (POS) —info reported to us by participating accounts and does not include ' +
                'certain sales, such as those to libraries or special bulk purchases. If you have questions ' +
                'about the figures displayed here (including why certain numbers may appear lower or higher ' +
                'than you expected), please read our Frequently-Asked-Questions (FAQ) page by clicking the ' +
                'link below. As always, please consult your royalty statement for the most comprehensive ' +
                'information on your sales.'
        },
        {
          title: 'Royalties',
          header: 'Royalties: Introduction',
          text: 'A standard royalty statement is produced semi-annually and sent out on March 31st (royalty statement' +
          ' covering the period starting July 1st to December 31st) and September 30th (royalty statement covering the' +
          ' period January 1st to June 30th), unless terms dictate otherwise within the individual publishers contract.' +
          ' Royalty statements will be posted within 48 hours of the mailed statement. Your statements from within the ' +
          'past 2 years are displayed below.'
        },
        {
          title: 'Subsidiary Rights',
          header: 'Subrights: Introduction',
          FAQLink: 'Read our Subrights FAQ',
          FAQSection: 'subrights',
          text: 'Below you’ll find a list of finalized subsidiary rights licenses for your book. Please contact Hachette’s' +
          ' subsidiary rights department via your editor for more detail about terms and payments. Please note that' +
          ' subsidiary rights payments are subject to deductions for co-agent commissions and international taxes.'
        },
        {
          title: 'compare',
          header: '',
          text: ''
        }
      ],
      resources: {
        subrights: 'subrights_data'
      },
      statementsByWorkUrl: '/ods-portlet.work/statements-by-work/isbn/:isbn/authorId/:authorId',
      allStatementsUrl: '/ods-portlet.work/statements-by-author/authorId/:authorId',
      pdfUrl:
      '/web/guest/home/-/statement/jaisbn/:jaISBN/contributor/:contributorId/contract/:contractId/payee/:payeeId/' +
      'period/:contractPeriodString/authorId/:authorId',
      events: {
        loaderHide: 'hiding loader',
        loaderShow: 'showing loader',
        hasMstrSession: 'mstrSessionCreationCompleted'
      },
    
      // eslint-disable-next-line no-magic-numbers
      statementListNum: [ 10, 20, 50, 'ALL' ],
      statementAttrs: [ 'isbn', 'authorId' ],
      pdfAttrs: [ 'jaISBN', 'authorId', 'contractId', 'payeeId', 'contractPeriodString', 'contributorId', 'isbn' ],
      tallListThreshold: 40,
      hasSales: 'posFlag',
      hasRoyalties: 'royaltyFlag',
      hasSubrights: 'subRightsFlag',
      updateInterval: 30000
};