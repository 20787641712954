import { Component, OnInit, Input } from '@angular/core';
import { BannerService } from 'src/app/service/banner.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { find as _find, cloneDeep as _cloneDeep, filter } from "lodash-es";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news-right-panel',
  templateUrl: './news-right-panel.component.html',
  styleUrls: ['./news-right-panel.component.scss']
})
export class NewsRightPanelComponent implements OnInit {
  @Input() articleId: string;

  allFeeds: any;
  selectedAuthorData: any;
  firstThreeFeeds: any;
  noOfItemsInRightPanel = 3;

  constructor(private bannerService: BannerService, private utilityService: UtilitiesService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.selectedAuthorData = this.utilityService.getAuthorData();
    this.getRssFeeds();
  }

  getRssFeeds() {
    this.utilityService.showLoader();
    this.bannerService.getRssFeeds().subscribe((result: any) => {
      this.allFeeds = result;
      this.getFirstThreeFeeds(this.articleId);
      this.utilityService.hideLoader();
    }, err => {
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
      this.utilityService.hideLoader();
    });
  }

  getFirstThreeFeeds(articleId) {
    const results = filter(this.allFeeds, function (item) {
      return item.articleId != articleId;
    });
    this.firstThreeFeeds = results.slice(0, this.noOfItemsInRightPanel);
  }

}
