
<div class="login-wrapper">
  <div class="container-fluid">
    <div class="row">
      <!-- Login form section -->
      <div class="login-form col justify-content-center align-self-center px-md-0 py-md-0 py-3">
        <div class="m-auto form-container">
          <div class="author-logo mx-auto">
            <img class="card-img-top" src="assets/images/author-logo.svg" alt="HBG Author Partner Portal logo">
          </div>
  
         
          <div >
            <h2 class="description font-size-25 text-center mb-3">Error!</h2>
            <p class="description font-size-16"> Oops, you don't have the permission to view this page.</p>
            <button  (click)="routeToLogin()" type="submit" class="btn btn-link mt-2 px-0 text-left">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" width="15px" height="15px" viewBox="0 0 477.862 477.862"
                style="enable-background:new 0 0 477.862 477.862;" xml:space="preserve">
                <g>
                  <g>
                    <path fill='#4e99c2' d="M187.722,102.856V17.062C187.719,7.636,180.076-0.003,170.65,0c-4.834,0.001-9.44,2.053-12.676,5.644L4.375,176.311
                c-5.617,6.256-5.842,15.67-0.529,22.187l153.6,187.733c5.968,7.295,16.72,8.371,24.016,2.403c3.952-3.233,6.249-8.066,6.26-13.172
                v-85.043c134.827,4.386,218.965,62.02,256.888,175.787c2.326,6.96,8.841,11.653,16.179,11.656c0.92,0.003,1.84-0.072,2.748-0.222
                c8.256-1.347,14.319-8.479,14.319-16.845C477.855,259.818,356.87,112.174,187.722,102.856z" />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g>
                </g>
              </svg> <span class="font-size-18 ml-2 mt-2">Back to Log in</span></button>
          </div>
      </div>

      <!-- author login banner -->
      <div class="col right-welcome d-md-block d-none p-0">
        <div class="author p-0 d-flex position-relative">
          <div class="banner-text">
            <div class="w-75 mx-auto px-5">
              <h1 class="mb-4">Welcome, Authors.</h1>
              <p class="lh-base">
                The Hachette Book Group Author Portal makes it easy to access the information you
                need as an HBG author. Here you’ll find publishing tips and resources to help you
                complete your manuscript and launch your book, as well as sales and royalty
                information. Complete your Author Questionnaire, connect with your HBG team, read
                HBG news, and much more. Log in to begin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>