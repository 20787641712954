import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'AuthorPortal';

  constructor(private gtmService: GoogleTagManagerService, private router: Router) {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
    this.gtmService.addGtmToDom();

  }

  ngOnInit() {
    if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/") > -1) {
      document.querySelector('body').addEventListener('click', function (evt) {
        // Do some check on target
        let ele: any = evt.srcElement;
        if (ele.classList.contains('accordion-button')) {
          // DO CODE
          if (ele.classList.contains('collapsed')) {
            ele.classList.remove('collapsed');
          } else {
            ele.classList.add('collapsed');
          }
          if (ele.parentElement.parentElement.getElementsByClassName('accordion-collapse')[0].classList.contains('show')) {
            ele.parentElement.parentElement.getElementsByClassName('accordion-collapse')[0].classList.remove('show')
          } else {
            ele.parentElement.parentElement.getElementsByClassName('accordion-collapse')[0].classList.add('show');
          }
        }
      }, true);
    }
  }
}
