<div class="modal-body border-0 choose-diff-book px-md-5 px-4 py-0 pb-4">
    <h1 class="text-center border-0 mb-4 mt-4 fs-4">Choose a Different Book</h1>
    <div class="cards-wrapper">
        <div class="d-flex justify-content-center mx-auto">
            <div class="card" *ngFor="let work of authorWorks | searchFilter: searchBook">
                <ng-container>
                    <div (click)="selectBook(work)" class="zoom-effect p-3 pb-1">
                        <img alt="" width="100%" class="image fix-height" src="{{work.coverImgURL}}"
                            onerror="this.src='./assets/images/default-book.svg';" alt="...">
                    </div>
                    <a (click)="selectBook(work)" class="slide-text d-inline-block pb-2" tabindex="0">{{work.title |
                        truncate : 33}}</a>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row py-2 mt-4">
        <div class="col-md-4">
            <div class="form-group" *ngIf="!hideSearch">
                <input class="form-control search-icon border mb-2 mb-md-0" type="text" [(ngModel)]="searchBook"
                    placeholder="Search by Book">
            </div>
        </div>
        <div class="col-md-8 text-end">
            <button class="btn btn-default btn-cancel rounded border-0 btn-theme-gray px-3 px-md-5"
                (click)="activeModal.close()">Cancel</button>
        </div>
    </div>