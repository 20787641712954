import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../service/analytics.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  salesFaqUrl: any;
  subrightsFaqUrl: any;
  iframe: any;
  iframe1: any;
  url: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, private analyticsService : AnalyticsService, private router: ActivatedRoute) { }

  checkSubrights() {
    this.router.paramMap.subscribe(params => {
      console.log();
      if (params.get('type') === 'subrights') {
        setTimeout(() => {
          document.getElementById('subrights').scrollIntoView();
        }, 4000);
      }
    });
  }

  ngOnInit(): void {
    this.analyticsService.eventEmitter('FAQ page load', window.location.href, "FAQ");
    this.salesFaqUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(environment.salesFaqUrl);
    this.subrightsFaqUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(environment.subrightsFaqUrl);    
    setTimeout(() => {
      this.iframe = document.getElementById("SubrightFaq"); 
      this.iframe.onload = () => {
        if (this.iframe.style.height != this.iframe.contentWindow.document.body.scrollHeight  + 'px') {
          this.iframe.style.height = this.iframe.contentWindow.document.body.scrollHeight + 'px';
        }
      }
      this.iframe1 = document.getElementById("SalesFaq"); 
      this.iframe1.onload = () => {
        if (this.iframe1.style.height != this.iframe1.contentWindow.document.body.scrollHeight  + 'px') {
          this.iframe1.style.height = this.iframe1.contentWindow.document.body.scrollHeight + 'px';
        }
      }
      this.checkSubrights();
    }, 100);
        
  }

}
