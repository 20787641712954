import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-tab',
  templateUrl: './sales-tab.component.html',
  styleUrls: ['./sales-tab.component.scss']
})
export class SalesTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
