<form [formGroup]="changePasswordForm">
  <div class="modal-body ml-auto mr-auto my-3">
    <div class="login-form">
      <h2 class="text-center mb-3">Change Password</h2>
      <div class="pl-4">
        <p class="description password-description font-size-16">Your password
          <u>must</u> contain the following:
        </p>
        <ul class="password-contain">
          <li>10 characters</li>
          <li>1 symbol</li>
          <li>1 number</li>
          <li>1 uppercase letter</li>
          <li>1 lowercase letter</li>
        </ul>
      </div>

      <div class="form-group">
        <input type="password" id="oldPassword" class="form-control current-password" formControlName="oldPassword"
          (focusin)="emptyErrorMessage()" required>
        <label class="form-control-placeholder" for="currentPassword">
          <i class="fa fa-key"></i> Current Password
        </label>
      </div>
      <div class="form-group">
        <input type="password" class="form-control" formControlName="newPassword" (focusin)="emptyErrorMessage()"
          (focusout)="checkValidationError('newPassword')" required>
        <label class="form-control-placeholder" for="newPassword">
          <i class="fa fa-key"></i> New Password
        </label>

        <p class="text-danger text-center font-weight-bold mt-1" *ngIf="this.errorObject.newPassword">
          {{errorValidPassword}}
        </p>
      </div>
      <div class="form-group mb-0">
        <input type="password" class="form-control" formControlName="confirmPassword" (focusin)="emptyErrorMessage()"
          (focusout)="checkValidationError('confirmPassword')" required>
        <label class="form-control-placeholder" for="confirmPassword">
          <i class="fa fa-key"></i> Confirm Password
        </label>
        <p class="text-danger text-center font-weight-bold mt-1" *ngIf="this.errorObject.confirmPassword">
          {{errorValidPassword}}
        </p>
        <p class="text-danger text-center font-weight-bold mt-1" *ngIf="this.errorObject.passwordMismatch">
          {{errorPasswordMismatch}}
        </p>
      </div>
      <p class="text-danger text-center mb-0 font-weight-bold change-password-error" *ngIf="errorMessage">
        {{errorMessage}}
      </p>

      <div class="row mt-4">
        <div class="col">
          <button class="btn btn-theme-gray d-block w-100" (click)="closeModal()">Cancel</button>
        </div>
        <div class="col">
          <button class="btn btn-theme-blue d-block w-100 btn-done" type="submit" (click)="changePassword()"
            [ngClass]="(!changePasswordForm.valid || this.errorObject.passwordMismatch) ? 'disabled' : ''">
            Done</button>
        </div>
      </div>
    </div>

  </div>
</form>
<div *ngIf="loading" id="loader-wrapper">
  <div id="loader"></div>
</div>