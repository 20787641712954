<app-header></app-header>
<div class="container mt-4">
    <div class="publishing-groups">
        <div class="header">
            <h5>HBG NEWS</h5>
        </div>
        <div class="row">
            <div class="col-md-9 col-sm-12 col-xs-12">
                <div class="panel-body">
                    <div class="image-box">
                        <img src="{{currentFeed?.featuredImage}}" class="img-fluid">
                    </div>
                    <div class="title">{{currentFeed?.title}}</div>
                    <div class="pdf-link" *ngIf="currentFeed?.link">
                        <a target="__blank" href="{{currentFeed?.link}}"> View Original Source</a>
                    </div>
                    <div *ngIf="currentFeed" class="description mt-2" [innerHTML]="currentFeed[ 'content:encoded' ]"></div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 border-start ps-md-5">
                <app-news-right-panel *ngIf="currentFeed" [articleId]="currentFeed?.articleId"></app-news-right-panel>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>