import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthorService } from 'src/app/service/author.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, isEmpty, orderBy as _orderBy, isNumber, isArray, reduce, cloneDeep, findIndex, isPlainObject, forEach, isString, mapValues, keys, orderBy, uniq, sortBy } from 'lodash-es';
import { ChooseDiffBookModalComponent } from 'src/app/shared/choose-diff-book-modal/choose-diff-book-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { PermissionService } from '../service/permission.service';
import { AuthService } from 'src/app/service/auth.service';
import { BookSelectorService } from '../service/book-selector.service';
import { environment } from '../../environments/environment';
import { SessionService } from '../service/session.service';
import { salesConstants } from './sales-constant';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { AnalyticsService } from '../service/analytics.service';
import * as _ from "lodash-es";
import { SalesDashboardService } from '../service/sales-dashboard.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  salesIsbn: string = '';
  auth: string = "&usrSmgr=:authToken";
  authorWorks: any;
  selectedMode: any = {};
  bookInfo;
  compareBookInfo: any = {};
  coverImgURL = environment.coverImgURL;
  pagePermission: any;
  authorProfileId;
  showChooseADifferentBook = false;
  validIsbnList;
  dashUrl: any;
  disable: any = {};
  protoDash: any;
  oldMode = '';
  oldStartDate: any;
  oldEndDate: any;
  subrightsUrl: any = '';
  salesUrl: any = '';
  compareUrl: any = '';
  sourceCollection: any;
  modes = [
    // {
    //   name: 'sales',
    //   compare: false,
    //   url: environment.msDashboardUrl,
    //   flag: salesConstants.hasSales,
    //   isbnListName: 'allIsbns'
    // },
    {
      name: 'royalties',
      flag: salesConstants.hasRoyalties,
      isbnListName: 'royaltiesIsbns'
    },
    {
      name: 'subrights',
      flag: salesConstants.hasSubrights,
      url: environment.msSubrightsUrl,
      isbnListName: 'subrightsIsbns'
    },
    {
      name: 'compare',
      header: '',
      text: ''
    }
  ];
  //  = this.selectedMode.url +
  // auth.replace( /:authToken/, Session.getMSTRSessionId() || '' ),
  protoComp: any;
  oldCompare: any;
  oldProd: any;
  wholeCollection: any = [];
  displayCollection: any = [];
  listOptions: any = [];
  listNum: string;
  newAuthor: boolean;
  loadingRoyalties: boolean;

  @ViewChild('comapreTabBtn') comapreTabBtn: ElementRef;
  compareBookChanged: boolean = false;
  //  environment.msCompareUrl +
  //   auth.replace( /:authToken/, Session.getMSTRSessionId() || '' ),
  
  selectedAuthorData: any;

  pages = [];
  currentPage = 0;
  totalNoOfPages = 0;

  unitLedgerOptionsData: any;
  unitLedgerProductData: any;
  unitLedgerSelectedProduct: any;
  unitLedgerTitles: any = {};
  unitLedgerData: any;
  duplicateUnitLedgerData: any = [];
  selectedCumulativePeriod = '';
  selectedStatementPeriod = '';
  selectedUnitLedgerIsbn: any;

  excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  excelExtension = '.xlsx';

  unitLedgerCumulativeType = 'cumulative';
  unitLedgerStatementPeriodType = 'statement';
  unitLedgerType = '';
  unitLedgerExportObject = {};
  unitLedgerFileName = 'unitLedgerFileName';
  statementPeriodEndDate = 'statementPeriodEndDate';

  royaltiesWorkIsbn = '';
  productsIdsList = [];
  jointAccountContractIds = [];
  selectedContractId ='';
  joinAccountIsbns = [];

  constructor(private authorService: AuthorService, private activatedRoute: ActivatedRoute, public modalService: NgbModal,
    private utilitiesService: UtilitiesService, private authService: AuthService, private permissionService: PermissionService, private analyticsService: AnalyticsService,
    private bookSelectorService: BookSelectorService, private sessionService: SessionService, private sanitizer: DomSanitizer, private router: Router,
    private salesDashboardService: SalesDashboardService) {
    this.pagePermission = this.permissionService.getPagePermission();
    this.activatedRoute.params.subscribe((params: any) => {
      this.salesIsbn = params.isbn;
    });

    if (!isEmpty(this.utilitiesService.getBookInfo())) {
      this.bookInfo = this.utilitiesService.getBookInfo();
      this.onEveryCheckCycle();
    } else {
      this.getSalesBookDetails();
    }
    this.selectedMode = this.modes[0];
  }

  ngOnInit(): void {
    this.authorProfileId = this.authService.getSelectedAuthor();
    if (this.pagePermission?.canViewRoyalties == 0) {
      this.router.navigate(['/navigation', this.authorProfileId, 'dashboard']);
    }
    this.analyticsService.pageView('Sales', 'Page Load');
    this.analyticsService.eventEmitter('Sales', window.location.href, 'Page Load');
    
    window.addEventListener('message', () => {
      if (!this.selectedMode.compare) {
        this.analyticsService.pageView('Compare Books', 'Page Load');
        this.analyticsService.eventEmitter('Choose different Book', window.location.href, 'Clicked');
        this.comapreTabBtn.nativeElement.click();
      }
    });
    this.selectedAuthorData = this.utilitiesService.getAuthorData();
  }

  ngOnDestroy() {
    this.authorService.clearWorkDetails();
    this.utilitiesService.saveBookInfo(null);
    window.removeEventListener('message', () => { });
  }

  getSalesBookDetails() {
    this.showChooseADifferentBook = true;
    this.authorService.clearWorkDetails();
    this.utilitiesService.showLoader();
    const isbn = this.salesIsbn;
    this.authorService.getProductWorks().subscribe((result: any) => {
      this.authorWorks = result;

      this.authorWorks.forEach(element => {
        element['coverImgURL'] = this.coverImgURL + element.isbn + '/';
      });

      this.bookSelectorService.setInitialIsbns(cloneDeep(this.authorWorks));
      let royaltiesIsbns = this.bookSelectorService.royaltiesIsbns;

      if (isbn) {
        let isbnFilteredResults = filter(royaltiesIsbns, function (item) {
          return item.isbn === isbn;
        });
        if (isbnFilteredResults && isbnFilteredResults.length == 0) {
          let exceptIsbn = isbn;
          isbnFilteredResults = filter(royaltiesIsbns, (item) => {
            return item.isbn !== exceptIsbn;
          });
        }
        this.bookInfo = isbnFilteredResults && isbnFilteredResults.length > 0 ? isbnFilteredResults[0] : {};
      } else {
        this.bookInfo = royaltiesIsbns && royaltiesIsbns.length > 0 ? royaltiesIsbns[0] : {};
      }

      if (isEmpty(this.bookInfo)) {
        this.utilitiesService.hideLoader();
        this.router.navigate(['navigation', this.authorProfileId, 'dashboard']);
      }

      this.bookSelectorService.selectedIsbns.royalties[0] = cloneDeep(this.bookInfo);
      this.checkRoyaltiesAndSubrightsTab(this.bookInfo.isbn);

      let selectorIsbnList = this.bookSelectorService.royaltiesIsbns;
      if (selectorIsbnList.length === 0 || selectorIsbnList.length === 1) {
        this.showChooseADifferentBook = false;
      }
      this.validIsbnList = selectorIsbnList;
      this.utilitiesService.hideLoader();
      this.onEveryCheckCycle();
    }, err => {
      this.utilitiesService.hideLoader();
      this.utilitiesService.showInformation(this.utilitiesService.getErrorMessage(), 'Error');
    });
  }

  selectIsbn(isbn, mode?: any) {

    if (!mode) {
      mode = this.selectedMode.name;
    }

    let idx = findIndex(this.bookSelectorService.royaltiesIsbns, { 'isbn': isbn });
    forEach(this.bookSelectorService.royaltiesIsbns, function (product) {
      product.selected = false;
    });

    this.bookSelectorService.royaltiesIsbns[idx].selected = !this.bookSelectorService.royaltiesIsbns[idx].selected;

    // assign selected isbns
    let selected = filter(this.bookSelectorService.royaltiesIsbns, 'selected');

    this.bookSelectorService.selectedIsbns[mode] = cloneDeep(selected);
    this.onEveryCheckCycle();
  };

  chooseDifferentBook(mode) {
    this.analyticsService.eventEmitter('Choose different Book', window.location.href, mode);
    const modalRef = this.modalService.open(ChooseDiffBookModalComponent, {
      centered: true,
      size: "xl",
      windowClass: "animated fadeIn"
    });

    this.validIsbnList = mode === 'subrights' || mode === 'royalties' ? this.bookSelectorService[mode + 'Isbns'] : this.bookSelectorService.royaltiesIsbns

    if (this.selectedMode.compare) {
      let filterList = this.validIsbnList;
      let exceptIsbn = this.compareBookInfo.isbn;
      if (mode == 'compare') {
        exceptIsbn = this.bookInfo.isbn;
      }
      filterList = filter(filterList, (item) => {
        return item.isbn !== exceptIsbn;
      });
      modalRef.componentInstance.authorWorks = cloneDeep(filterList);
    } else {
      modalRef.componentInstance.authorWorks = this.validIsbnList;
    }


    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (this.salesIsbn !== receivedEntry.isbn) {
        if (mode == 'compare') {
          this.compareBookChanged = true;
          this.compareBookInfo = cloneDeep(receivedEntry);
        } else if (mode === 'royalties') {
          this.bookInfo = cloneDeep(receivedEntry);
          this.loadRoyalties(this.bookInfo.isbn);
        } else {
          this.bookInfo = cloneDeep(receivedEntry);
        }


        this.checkRoyaltiesAndSubrightsTab(receivedEntry.isbn);
        this.utilitiesService.saveBookInfo(receivedEntry.isbn);
        //this.selectIsbn(receivedEntry.isbn, mode); RP-1282 - 
      }
    })
  }

  checkRoyaltiesAndSubrightsTab(isbn) {
    this.disable.royalties = findIndex(this.bookSelectorService.royaltiesIsbns, ['isbn', isbn]) === -1;
    this.disable.subrights = findIndex(this.bookSelectorService.subrightsIsbns, ['isbn', isbn]) === -1;
  }

  onEveryCheckCycle() {
    if (this.bookSelectorService.selectedIsbns.royaltiesIsbns && this.bookSelectorService.selectedIsbns.royaltiesIsbns.length == 0) {
      this.authorService.getWorks().subscribe((result: any) => {
        this.authorWorks = result;
        let imageUrl = this.coverImgURL;
        this.authorWorks.forEach(element => {
          element['coverImgURL'] = imageUrl + element.isbn + '/';
        });
        this.bookSelectorService.setInitialIsbns(this.authorWorks);
        this.bindData();
      });
    }
    else {
      this.bindData();
    }
  };

  getIsbnIdx(product) {
    let idx = findIndex(this.bookSelectorService.royaltiesIsbns, ['isbn', product.isbn]);

    return idx < this.bookSelectorService.royaltiesIsbns.length - 2 ? idx + 1 : 0;
  };

  setSelectedTab(selectedIndex) {
    this.selectedMode = this.modes[selectedIndex];
    this.selectedMode.compare = false;
    if (selectedIndex == 3) {

      this.selectedMode.compare = true;
    }

    this.analyticsService.pageView('Sales - ' + this.selectedMode.name + ' (tab)', 'Page Load');
    this.analyticsService.eventEmitter('Sales - ' + this.selectedMode.name + ' (tab)', window.location.href, 'Clicked');
    this.selectIsbn(this.bookInfo.isbn);
    // this.onEveryCheckCycle();
  }

  letsCompare() {
    this.comapreTabBtn.nativeElement.click();
  }

  bindData() {
    let newProd = this.bookSelectorService.selectedIsbns['royalties'][0];
    if (this.oldProd !== newProd) {
      this.oldProd = newProd;

      // TODO: DRY out with mode check soon
      if (isPlainObject(newProd) && this.selectedMode.name === 'royalties') {
        this.loadRoyalties(newProd.isbn);
      }
    }
  };

  buildPdfUrls(items) {
    return forEach(items, (contract) => {
      contract.authorId = this.authorProfileId;
      contract.isbn = this.oldProd.isbn;
      contract.pdfUrl = environment.baseUrl + salesConstants.pdfUrl;

      forEach(salesConstants.pdfAttrs, (attr) => {
        let value = attr === 'contractPeriodString' ? formatDate(contract.contractPeriodString, 'yyyyMM', 'en-US') :
          contract[attr];

        if (contract[attr]) {
          contract.pdfUrl = contract.pdfUrl.replace(':' + attr, value);
        }
      });
    });
  }

  loadRoyalties(isbn) {
    let royaltyIsbn = isbn,
    worksUrl = cloneDeep(salesConstants.statementsByWorkUrl),
    royaltyUrl = isString(worksUrl) ? worksUrl.replace(/:isbn/g, royaltyIsbn).replace(':authorId', this.authorProfileId) : '';
    this.royaltiesWorkIsbn = royaltyIsbn;

    this.displayCollection = [];
    this.initializeUnitLedgerData();
    if (royaltyIsbn) {
      this.utilitiesService.showLoader();
      this.authorService.getRoyaltyStatements(royaltyUrl).subscribe((result: any) => {
        this.utilitiesService.hideLoader();
        this.displayCollection = [];
        if (result && result.length > 0) {
          this.sourceCollection = this.wholeCollection = this.buildPdfUrls(result);
          this.displayCollection = cloneDeep(this.sourceCollection);

          this.listOptions = filter(salesConstants.statementListNum, (n) => {
            return n === 'ALL' || n < this.wholeCollection.length;
          });

          this.listNum = 'ALL';
          this.updatePage(this.listNum);
          this.loadingRoyalties = false;
          this.newAuthor = false;

          this.getUnitLedgerOptions();
        }
      });
    }
  }

  stringify(collection) {
    return isArray(collection) ?
      reduce(collection, (whole, part) => {
        return whole + ', ' + part;
      }) : collection;
  };

  updatePage(num) {
    let itemsPerPage = isNumber(num) ? num : this.wholeCollection.length;

    this.sourceCollection =

      this.wholeCollection = _.sortBy(this.wholeCollection, 'contractPeriodString');
    this.wholeCollection = _.reverse(this.wholeCollection);
    this.sourceCollection = _.take(this.wholeCollection, itemsPerPage);
    // value();

    // this.sourceCollection =
    //   chain(this.wholeCollection).
    //     sortBy('contractPeriodString').
    //     reverse().
    //     take(itemsPerPage).
    //     value();

    // salesService.listNum = num;
  };

  numStatements() {
    return this.wholeCollection ? this.wholeCollection.length : 0;
  };

  plural(num) {
    return num === 1 ? 'statement' : 'statements';
  };

  
  loadPagination(currentPage: number) {
    this.pages = [];
    this.currentPage = currentPage;

    // Show all the pagination elements if there are less than 8 pages total
    if (this.totalNoOfPages <= 7) {
      for (let p = 1; p <= this.totalNoOfPages; p++) {
        this.pages.push(p);
      }
    } else {
      this.getPaginationStartAndEnd();
    }
    this.initializeSortingCriteriaForUnitLedger();
    this.unitLedgerProductData = cloneDeep(this.duplicateUnitLedgerData[this.currentPage - 1]);
  }

  getPaginationStartAndEnd() {
    let start = this.currentPage - 1;
    let end = this.currentPage + 1;

    // Determine how many pages to show after the current page index
    if (this.currentPage === 1) {
      end += 3;
    } else if (this.currentPage === 2) {
      end += 2;
    } else if (this.currentPage === 3 || this.currentPage === (this.totalNoOfPages - 3)) {
      end += 1;
    }

    // Determine how many pages to show before the current page index
    if (this.currentPage === this.totalNoOfPages) {
      start -= 3;
    } else if (this.currentPage === (this.totalNoOfPages - 1)) {
      start -= 2;
    } else if (this.currentPage === 4 || this.currentPage === (this.totalNoOfPages - 2)) {
      start -= 1;
    }
    this.createPaginationWithStartAndEnd(start, end);
  }

  createPaginationWithStartAndEnd(start: number, end: number) {
    // Show the very first page followed by a "..." at the beginning of the pagination section (after the Previous button)
    if (this.currentPage >= 3) {
      this.pages.push(1);
      if (this.currentPage > 4) {
        this.pages.push('...');
      }
    }

    // Output the indexes for pages that fall inside the range of start and end
    if(start === 0) start += 1;
    for (let p = start; p <= end; p++) {
      if (p > this.totalNoOfPages || p === -1) {
        continue
      }
      this.pages.push(p);
    }
    // Show the very last page preceded by a "..." at the end of the pagination
    // section (before the Next button)
    if (this.currentPage < this.totalNoOfPages - 1) {
      if (this.currentPage < this.totalNoOfPages - 3) {
        this.pages.push('...');
      }
      this.pages.push(this.totalNoOfPages);
    }
  }

  initializeUnitLedgerData() {
    delete this.unitLedgerProductData;
    delete this.unitLedgerSelectedProduct;
    this.unitLedgerTitles = {};
    this.unitLedgerOptionsData = {};
    this.selectedCumulativePeriod = '';
    this.selectedStatementPeriod = '';
    this.productsIdsList = [];
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  getUnitLedgerOptions() {
    this.initializeUnitLedgerData();

    this.utilitiesService.showLoader();
    this.salesDashboardService.getUnitLedgerOptions(this.royaltiesWorkIsbn, this.selectedAuthorData.authorProfileId).subscribe((result: any) => {
      this.utilitiesService.hideLoader();
      let unitLedgerArray = [];
      let contractIdList=[];
      let productIsbn10List = [];
      if (!isEmpty(result)) {
        this.unitLedgerOptionsData = cloneDeep(result);
        let unitLedgerMappedValues = mapValues(this.unitLedgerOptionsData, 'index');
        productIsbn10List = keys(this.unitLedgerOptionsData);

        for(let i = 1; i<=productIsbn10List.length; i++) {
          this.productsIdsList.push(this.getKeyByValue(unitLedgerMappedValues, i));
        }

        this.initializeSortingCriteriaForUnitLedger();
        this.productsIdsList.forEach((isbn: any) => {
          let _obj = {
            "key": isbn,
            "value": this.unitLedgerOptionsData[isbn].format + " (" + isbn + ")"
          }
          this.unitLedgerTitles[isbn] = this.unitLedgerOptionsData[isbn].format + " (" + isbn + ")";
          unitLedgerArray.push(_obj);
        });
        contractIdList = [
          ...this.unitLedgerOptionsData[this.productsIdsList[0]]
            .jointAccountContractIds,
        ];
        this.jointAccountContractIds = cloneDeep(sortBy(contractIdList));
        this.selectedContractId = this.jointAccountContractIds[0];
        this.joinAccountIsbns =
        this.unitLedgerOptionsData[this.productsIdsList[0]]
        ?.jointAccountIsbns;
        this.updateUnitLedgerMetaData(this.productsIdsList[0]);
        this.unitLedgerTitles = unitLedgerArray;
      }
    },
      (err) => {
        this.utilitiesService.hideLoader(true);
        console.log(err);
      }
    );
  }

  updateUnitLedgerMetaData(isbn: string | number) {
    let contractIdList = [];
    this.selectedUnitLedgerIsbn = isbn;
    this.unitLedgerSelectedProduct = this.unitLedgerOptionsData[isbn];
    contractIdList = [...this.unitLedgerSelectedProduct.jointAccountContractIds];
    this.jointAccountContractIds = cloneDeep(sortBy(contractIdList));
    this.selectedContractId = this.jointAccountContractIds[0];
    let cumulativePeriods = this.unitLedgerSelectedProduct.cumulativePeriods;
    if (cumulativePeriods && cumulativePeriods.length > 0) {
      //call unit ledger data API by sending 0th cumulative date to API
      this.selectedCumulativePeriod = cumulativePeriods[0];
      this.getLedgerData(isbn, this.unitLedgerCumulativeType, cumulativePeriods[0], this.selectedContractId, this.joinAccountIsbns);
    } else {
      let statementPeriods = this.unitLedgerSelectedProduct.statementPeriods;
      this.selectedStatementPeriod = statementPeriods[0];
      //call unit ledger data API by sending 0th statement period date to API
      this.getLedgerData(isbn, this.unitLedgerStatementPeriodType, statementPeriods[0], this.selectedContractId, this.joinAccountIsbns);
    }
    this.analyticsService.eventEmitter('Royalties and Unit Ledgers - Unit Ledger - Per ISBN User DropDown Click', window.location.href, this.selectedUnitLedgerIsbn);

  }

  getLedgerData(isbn: string | number, type: string, date: any, jointAccountContractId:string, jointAccountIsbns:string[]) {
    this.utilitiesService.showLoader();
    this.unitLedgerType = type;
    let ledgerDataPayload={};
    ledgerDataPayload["isbn10"]=isbn;
    ledgerDataPayload["ledgerType"]=type;
    ledgerDataPayload["stmtPeriodEndDate"]=date;
    ledgerDataPayload["jointAccountContractId"]=jointAccountContractId;
    ledgerDataPayload["jointAccountIsbns"]=[...jointAccountIsbns];
    this.analyticsService.eventEmitter('Royalties and Unit Ledgers - Unit Ledger - Per ISBN', window.location.href, this.selectedUnitLedgerIsbn);
    this.salesDashboardService.getUnitLedgerData(ledgerDataPayload).subscribe((result: any) => {

      this.utilitiesService.hideLoader();
      if (!isEmpty(result)) {
        this.unitLedgerData = cloneDeep(result);
        delete this.unitLedgerProductData;
        this.duplicateUnitLedgerData = [];

        this.totalNoOfPages = this.unitLedgerData.length;
        this.unitLedgerProductData = this.unitLedgerData[0];
        this.duplicateUnitLedgerData = cloneDeep(this.unitLedgerData);
        this.loadPagination(1);//always defaults to first page

      } else {
        this.pages = []; // hide the pagination when there is no unit ledger data
        if (this.unitLedgerProductData && this.unitLedgerProductData.unitLedgerData.length > 0) {
          this.unitLedgerProductData.unitLedgerData = [];
        }
      }
    },
      (err) => {
        this.utilitiesService.hideLoader(true);
        console.log(err);
      }
    );
  }

  selectedIsbn10Change() {
    this.selectedStatementPeriod = '';
    this.selectedCumulativePeriod = '';
    this.updateUnitLedgerMetaData(this.selectedUnitLedgerIsbn);
  }

  selectedCumulativePeriodChange() {
    this.currentPage = 1;
    this.selectedStatementPeriod = '';
    this.getLedgerData(this.selectedUnitLedgerIsbn, this.unitLedgerCumulativeType, this.selectedCumulativePeriod, this.selectedContractId, this.joinAccountIsbns);
  }

  selectedStatementPeriodChange() {
    this.currentPage = 1;
    this.selectedCumulativePeriod = '';
    this.getLedgerData(this.selectedUnitLedgerIsbn, this.unitLedgerStatementPeriodType, this.selectedStatementPeriod, this.selectedContractId, this.joinAccountIsbns);
  }

  selectedContractChange() {
    this.currentPage = 1;
    this.unitLedgerSelectedProduct = this.unitLedgerOptionsData[this.selectedUnitLedgerIsbn];
    let cumulativePeriods = this.unitLedgerSelectedProduct.cumulativePeriods;
    this.selectedCumulativePeriod = cumulativePeriods[0];
    this.selectedStatementPeriod = '';
    this.getLedgerData(this.selectedUnitLedgerIsbn, this.unitLedgerCumulativeType, this.selectedCumulativePeriod, this.selectedContractId, this.joinAccountIsbns);
  }

  unitLedgerSortingMap = {};
  initializeSortingCriteriaForUnitLedger() {
    this.unitLedgerSortingMap = { 'grossUnits': 'asc', 'returnUnits': 'asc', 'reserveUnits': 'asc', 'adjustedNetUnits': 'asc', 'statementUnits': 'asc' };
  }

  sortUnitLedgerTableData(name: string | number) {
    let orderedUnitLedgerData = orderBy(this.unitLedgerProductData.unitLedgerData, [name], this.unitLedgerSortingMap[name]);
    this.unitLedgerProductData.unitLedgerData = orderedUnitLedgerData;
    this.unitLedgerSortingMap[name] = this.unitLedgerSortingMap[name] === 'asc' ? 'desc' : 'asc';
  }

  exportUnitLedgerXLSX(type: string) {
    this.utilitiesService.showLoader();
    this.prepareDataForUnitLedger(type);
    let requestData = this.prepareRequestDataForUnitLedger(type);

    this.salesDashboardService.exportUnitLedgerExcel(requestData).subscribe((data: any) => {
      this.utilitiesService.hideLoader();
      let headerData = data;
      const blob = this.converBase64toBlob(headerData.data, 'octet/stream');
      let url = window.URL.createObjectURL(blob);
      let a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = this.unitLedgerExportObject[this.unitLedgerFileName] + this.excelExtension;
      a.click();
      window.URL.revokeObjectURL(url);
    }, err => {
      this.handleError(err);
    });
  }

  exportUnitLedgerPdf(type: string) {
    this.utilitiesService.showLoader();
    this.prepareDataForUnitLedger(type);
    let requestData = this.prepareRequestDataForUnitLedger(type);
    requestData['fileType'] = 'pdf';
    this.salesDashboardService.exportUnitLedgerPdf(requestData).subscribe((data: any) => {
      this.utilitiesService.hideLoader();
      let headerData = data;
      const blob = this.converBase64toBlob(headerData.data, 'octet/stream');
      let url = window.URL.createObjectURL(blob);
      let a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = this.unitLedgerExportObject[this.unitLedgerFileName] + ".pdf";
      a.click();
      window.URL.revokeObjectURL(url);
    }, err => {
      this.handleError(err);
    });
  }

  prepareDataForUnitLedger(type: string) {
    let excelFileNameLabel = 'UnitLedger';
    let statementPeriodEndDate = this.selectedCumulativePeriod;
    if (this.unitLedgerType == this.unitLedgerStatementPeriodType) {
      statementPeriodEndDate = this.selectedStatementPeriod;
    }
    this.unitLedgerExportObject[this.statementPeriodEndDate] = statementPeriodEndDate;

    let unitLedgerFileName = this.utilitiesService.replaceSpecialCharatersWithUnderscore(this.selectedAuthorData.lastName).trim();
    if (this.selectedAuthorData.firstName != null) {
      unitLedgerFileName = unitLedgerFileName+ "_" + this.utilitiesService.replaceSpecialCharatersWithUnderscore(this.selectedAuthorData.firstName).trim();
    }
    unitLedgerFileName = unitLedgerFileName.replace(/\s+/g, '');
    let date = new Date();
    let todayDate =
      ("00" + (date.getMonth() + 1)).slice(-2) + "_" +
      ("00" + date.getDate()).slice(-2) + "_" +
      date.getFullYear() + "_" +
      ("00" + date.getHours()).slice(-2) + "_" +
      ("00" + date.getMinutes()).slice(-2) + "_" +
      ("00" + date.getSeconds()).slice(-2);
    
    let isbnAndContract = type === 'selectedFormat' ? this.selectedUnitLedgerIsbn + "_" + this.selectedContractId : this.royaltiesWorkIsbn;
    unitLedgerFileName = unitLedgerFileName + "_" + isbnAndContract + "_" + excelFileNameLabel + "_" + todayDate;
    this.unitLedgerExportObject[this.unitLedgerFileName] = unitLedgerFileName;
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content);
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    });
    return blob;
  }

  prepareRequestDataForUnitLedger(type: string) {
    let requestData = {};
    if (type === 'selectedFormat') {
      let selectedIsbnList = [];
      selectedIsbnList.push(this.selectedUnitLedgerIsbn);
      let data = this.prepareDataForIsbnJointAccounts(type, selectedIsbnList);
      requestData['isbnJointAccounts'] = data['jointAccountContractIds'];
      requestData['jointAccountIsbns'] = data['jointAccountIsbns'];
    } else {
      let data = this.prepareDataForIsbnJointAccounts(type, this.productsIdsList);
      requestData['isbnJointAccounts'] = data['jointAccountContractIds'];
      requestData['jointAccountIsbns'] = data['jointAccountIsbns'];
    }
    requestData['stmtPeriodEndDate'] = this.unitLedgerExportObject[this.statementPeriodEndDate];
    return requestData;
  }

  prepareDataForIsbnJointAccounts(type: string, isbn10List: any[]) {
    let jointAccountContractIdsAndIsbnObject = {};
    let jointAccountContractIdsArray = [];
    let jointAccountIsbnsArray = [];
    let selectedContractIds = [];
    selectedContractIds.push(this.selectedContractId);

    isbn10List.forEach((isbn10: any) => {
      let isbnJointAccountsObject = {};
      isbnJointAccountsObject['isbn10'] = isbn10;
      isbnJointAccountsObject['jointAccountContractIds'] = type === 'selectedFormat' ? selectedContractIds : this.unitLedgerOptionsData[isbn10].jointAccountContractIds;
      jointAccountContractIdsArray.push(isbnJointAccountsObject);
      
      jointAccountIsbnsArray.push(...this.unitLedgerOptionsData[isbn10].jointAccountIsbns);
    });
    jointAccountContractIdsAndIsbnObject['jointAccountContractIds'] = jointAccountContractIdsArray;
    jointAccountContractIdsAndIsbnObject['jointAccountIsbns'] = uniq(jointAccountIsbnsArray);

    return jointAccountContractIdsAndIsbnObject;
  }

  exportUnitLedgerPdfForAllFormats(type: string) {
    this.utilitiesService.showLoader();
    this.prepareDataForUnitLedger(type);
    let requestData = this.prepareRequestDataForUnitLedger(type);
    requestData['fileType'] = 'zip';

    this.salesDashboardService.exportUnitLedgerPdf(requestData).subscribe((data: any) => {
      this.utilitiesService.hideLoader();
      let headerData = data;
      const blob = this.converBase64toBlob(headerData.data, 'octet/stream');
      let url = window.URL.createObjectURL(blob);
      let a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = this.unitLedgerExportObject[this.unitLedgerFileName] + ".zip";
      a.click();
      window.URL.revokeObjectURL(url);
    }, err => {
      this.handleError(err);
    });
  }

  addGoogleAnalytics(description: string, type: string) {
    let unitLedgerIsbn = type === 'selectedFormat' ? this.selectedUnitLedgerIsbn : this.royaltiesWorkIsbn;
    this.analyticsService.eventEmitter('Royalties and Unit Ledgers - Export and Download ' + description, window.location.href, unitLedgerIsbn);
  }

  handleError(err: any) {
    this.utilitiesService.hideLoader(true);
    console.log(err);
    this.utilitiesService.showInformation(this.utilitiesService.getErrorMessage(), 'Error');
  }
  
  onKeydown(event) {
    if (event.key == ("ArrowUp") || event.key == ("ArrowDown")|| event.key == ("ArrowRight") || event.key == ("ArrowLeft")) {
      event.preventDefault();
    }
  }

}
