<h4 class="back-line-txt text-center my-3"><span class="">ROYALTIES</span></h4>
<div class="row">
    <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
        <div class="d-md-flex d-block">
            <div class="flex-shrink-0 text-md-start text-center mb-3">
                <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                    <img alt="" class="fix-height" src="">
                </div>
            </div>
            <div class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                <h1 class=" mb-3 extra-size">The Day That Went Missing</h1>
                <h4 class="lh-base">by Richard Beard</h4>
                <p class="lh-base">On Sale November 6, 2018</p>

            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xl-6 text-md-start text-center">
        <p class="lh-base">A standard royalty statement is produced semi-annually and sent out on March 31st (royalty
            statement covering the period starting July 1st to December 31st) and September 30th (royalty statement
            covering the period January 1st to June 30th), unless terms dictate otherwise within the individual
            publishers contract. Royalty statements will be posted within 48 hours of the mailed statement. Your
            statements from within the past 2 years are displayed below.</p>
    </div>
</div>