<div class="sales-glance container text-center">
  <h5 class="background">
    <span class="bg-white">Latest Sales Info at a Glance</span>
  </h5>
  <a [class.link-disabled]="!pagePermission?.canViewSales" (click)="gaClick('View Sales ', authorProfileId)" [routerLink]="['/navigation', authorProfileId, 'sales']"
    class="view-sales">View Sales
    <img class="ms-1" src="assets/images/arrow-right.png" alt=""> </a>
  <app-mstr-view msURL="SALES" [dashboard]="'sales-glance'" [loading]="true"></app-mstr-view>
  <!-- <mstr-load-manager ms-url="$ctrl.msUrl" url-function="'getUrl'" dashboard="'sales-glance'" loading="true"> -->
  <!-- </mstr-load-manager>  pagePermission?.canViewSales-->
</div>