<footer class="footer">
    <div class="footer-top-border"></div>
    <div class="container text-center">
        <div class="row links">
            <div class="col-sm-3 col-xs-6 h-100">
                <a (click)="gaClick('HBG Logo')" href="javascript:clickMenuItem('Recent Activity')" class="d-flex align-items-center h-100"
                    rel="noreferrer">
                    <img src="assets/images/hbg-ap-footer-logo.svg" alt="LOGO" class="m-auto">
                </a>
            </div>
            <div class="col-sm-3 col-xs-6">
                <h5>Portal</h5>

                <ul class="portal mt-sm-4 mt-0">
                    <li>
                        <a href="javascript:void(0)" (click)="gaClick('Dashboard')" [routerLink]="['/navigation', selectedAuthorId,'dashboard']" rel="noreferrer">Dashboard</a>
                    </li>
                    <li>
                        <a [class.link-disabled]="!pagePermission?.canViewAq" (click)="gaClick('Author and Illustrator QA')"
                            href="javascript:void(0)" rel="noreferrer" [routerLink]="['/navigation', selectedAuthorId,'questionnaire', 'aboutYou']">Author and Illustrator QA</a>
                    </li>
                    <li>
                        <a [routerLink]="['/navigation', selectedAuthorId,'salesDashboard']" (click)="gaClick('Sales Data')" href="javascript:void(0)" rel="noreferrer">Sales Data</a>
                    </li>
                    <li *ngIf="pagePermission?.canViewRoyalties">
                        <a [routerLink]="['/navigation', selectedAuthorId,'sales']" (click)="gaClick('Royalties')" href="javascript:void(0)"  rel="noreferrer">Royalties and Unit Ledgers</a>
                    </li>
                    <li>
                        <a (click)="gaClick('News and Resources')" href="javascript:void(0" rel="noreferrer" [routerLink]="['/navigation', selectedAuthorId,'news']">News and Resources</a>
                    </li>
                </ul>

            </div>
            <div class="col-sm-3 col-xs-6">
                <h5>Contact</h5>
                <ul class="contact mt-sm-4 mt-0">
                    <li>
                        <p>Please contact us with any questions and concerns at
                            <a (click)="gaClick('HBG-AuthorPortalSupport@hbgusa.com')" href="mailto:HBG-AuthorPortalSupport@hbgusa.com">HBG-AuthorPortalSupport@hbgusa.com</a>
                        </p>
                    </li>
                    <li>
                        <a (click)="gaClick('FAQs')" href="javascript:void(0)" [routerLink]="['/navigation', selectedAuthorId,'faq']" rel="noreferrer">
                            <img src="assets/images/faq.png" class="me-1" style="width:15px !important;" alt=""> FAQs</a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-3 col-xs-6">
                <h5>follow</h5>
                <div class="d-flex">
                    <ul class="d-flex social m-auto">
                        <li>
                            <a (click)="gaClick('twitter')" href="https://twitter.com/HachetteUS" rel="noreferrer" target="_blank">
                                <img src="assets/images/twitter.svg">
                            </a>
                        </li>
                        <li>
                            <a (click)="gaClick('facebook')" href="https://www.facebook.com/HachetteUS/" rel="noreferrer" target="_blank">
                                <img src="assets/images/facebook.svg">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="legal">
        <div class="container">
            <div class="row text-center">
                <p class="col-xs-12 ng-binding"><a (click)="getTermOfUser()"  class="text-decoration-none">Terms of
                        Use</a> | <a class="text-decoration-none"
                        href="https://www.hbgauthorresources.com/landing-page/info-for-authors-author-privacy-policy/"
                        target="_blank">Privacy Policy</a> | © {{ currentYear }} Hachette Book Group <span
                        class="version ng-binding"> - v3.9.0</span></p>
            </div>
        </div>
    </div>
</footer>
