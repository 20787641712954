<div class="dashboard-wrapper">
    <section id="SubHeaderSection">
        <div class="container py-3">
            <div class="row text-center align-items-center"  *ngIf="authors && authors.length > 1">
                <div class="col-md-auto w-md-100 mb-md-0 mb-2">
                    <label>Viewing Author :</label>
                </div>
                <div class="col-md-3 d-flex flex-row bd-highlight">
                    <select [(ngModel)]="selectedAuthor" (click)="authorClick()" (change)="authorChange($event)"
                        class="form-select rounded-1 me-3" name="deviceText" (keydown)="onKeydown($event)" >
                        <option [value]="i.authorProfileId" *ngFor="let i of authors">{{i.fullName}}</option>
                    </select>
                    <button type="button" (click)="selectAuthor()"
                        class="btn btn-primary bg-transparent border-0 p-0"><img src="assets/images/author-selector.svg"
                            alt="">
                    </button>
                </div>
            </div>
        </div>
        <div class="happi-border"></div>
    </section>
</div>