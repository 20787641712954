import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private authService : AuthService,  private router: Router) { 
  }

  ngOnInit(): void {
  }

  routeToLogin() {
    this.router.navigate(['/']);
  }

}
