import { AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-publishing-groups',
  templateUrl: './publishing-groups.component.html',
  styleUrls: ['./publishing-groups.component.scss']
})
export class PublishingGroupsComponent implements OnInit, AfterViewInit {
  constructor(private sanitizer: DomSanitizer) { }
iframe: any;
url: SafeResourceUrl;
  ngOnInit(): void {
    // Selecting the iframe element
    
  }
  ngAfterViewInit () {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin+'/widget/web/guest/hbg-news/-/56_INSTANCE_U69dU6Va2hzB');
    setTimeout(() => {
      this.iframe = document.getElementById("myIframe"); 
      this.iframe.onload = () => {
        if (this.iframe.style.height != this.iframe.contentWindow.document.body.scrollHeight  + 'px') {
          this.iframe.style.height = this.iframe.contentWindow.document.body.scrollHeight + 'px';
        }
      }
    }, 100);
  }

  public setPublisherGroupUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin+'/widget/web/guest/hbg-news/-/56_INSTANCE_U69dU6Va2hzB');
  }
      

}
