<app-header></app-header>
<div class="about-you">
  <section class="about-you-sec my-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="tab-content" id="myTabContent">
            <!-- FIRST TAB-->
            <div class="tab-pane fade show active p-0" id="sales-tab" role="tabpanel"
              [ngClass]="{'active show': selectedMode.name === 'sales'}" aria-labelledby="about-you-tab">
              <div class="content">
                <h4 class="back-line-txt text-center my-3">
                  <span class="">YOUR BOOK SALES</span>
                </h4>
                <div class="row">
                  <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
                    <div class="d-md-flex d-block">
                      <div class="flex-shrink-0 text-md-start text-center mb-3">
                        <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                          <img href="#" alt="" class="fix-height cursor-pointer" src="{{bookInfo?.coverImgURL}}"
                            [routerLink]="['/navigation', authorProfileId,'book',bookInfo?.isbn]"
                            onerror="this.src='./assets/images/default-book.svg';">
                        </div>
                      </div>
                      <div
                        class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                        <h1 class=" mb-3 extra-size">{{bookInfo?.title}}</h1>
                        <h4 class="lh-base">by {{bookInfo?.products[0].byLine}}</h4>
                        <p class="lh-base">On Sale {{bookInfo?.products[0].onSaleDate |date :
                          'MMM dd, yyyy'}}</p>
                        <button *ngIf="showChooseADifferentBook" type="button"
                          class="btn pull-right btn-theme-blue px-4 end-0 lh-1 choose-diffrent my-2 my-md-0"
                          (click)="chooseDifferentBook('sales')">
                          Choose a Different Book
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-xl-6 text-md-start text-center">
                    <p class="lh-base">Here you can see weekly and monthly sales for your book(s) by
                      channel (the type of retailer)
                      or by format (e.g. hardcover, eBook, etc.). On the Lifetime Performance
                      page,
                      you
                      can see overall gross and net sales, as well as information about printings
                      and
                      copies
                      shipped. Sales figures in the Portal are refreshed each Wednesday. Note that
                      the
                      weekly and monthly data is “point of sale” (POS) —info reported to us by
                      participating
                      accounts and does not include certain sales, such as those to libraries or
                      special
                      bulk purchases. If you have questions about the figures displayed here
                      (including
                      why certain numbers may appear lower or higher than you expected), please
                      read
                      our
                      Frequently-Asked-Questions (FAQ) page by clicking the link below. As always,
                      please
                      consult your royalty statement for the most comprehensive information on
                      your
                      sales.
                    </p>
                    <a href="#" [routerLink]="['/navigation', authorProfileId ,'faq']">Read our
                      Sales FAQ
                      <i class="fas fa-chevron-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="content sales-dashboard p-0" *ngIf="salesUrl">
                <app-mstr-view [msURL]="salesUrl" [dashboard]="'sales'" [loading]="true">
                </app-mstr-view>
              </div>
            </div>
            <div class="tab-pane fade p-0" id="royalties-tab" role="tabpanel" *ngIf="selectedMode.name === 'royalties'"
              [ngClass]="{'active show': selectedMode.name === 'royalties'}" aria-labelledby="about-your-book-tab">
              <div class="content border-0 pb-0">
                <h4 class="back-line-txt text-center my-3">
                  <span class="">ROYALTIES</span>
                </h4>
                <div class="row">
                  <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
                    <div class="d-md-flex d-block">
                      <div class="flex-shrink-0 text-md-start text-center mb-3">
                        <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                          <img alt="" class="fix-height" src="{{bookInfo?.coverImgURL}}"
                            onerror="this.src='./assets/images/default-book.svg';">
                        </div>
                      </div>
                      <div
                        class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                        <h1 class=" mb-3 extra-size">{{bookInfo?.title}}</h1>
                        <h4 class="lh-base">by {{bookInfo?.products[0].byLine}}</h4>
                        <p class="lh-base">On Sale {{bookInfo?.products[0].onSaleDate |date :
                          'MMM dd, yyyy'}}</p>

                        <button *ngIf="showChooseADifferentBook" type="button"
                          class="btn pull-right btn-theme-blue px-4 end-0 lh-1 choose-diffrent my-2 my-md-0"
                          (click)="chooseDifferentBook('royalties')">
                          Choose a Different Book
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-xl-6 text-md-start text-center">
                    <p class="lh-base">A standard royalty statement is produced semi-annually and
                      sent
                      out on March 31st (royalty
                      statement covering the period starting July 1st to December 31st) and
                      September
                      30th
                      (royalty statement covering the period January 1st to June 30th), unless
                      terms
                      dictate
                      otherwise within the individual publishers contract. Royalty statements will
                      be
                      posted
                      within 48 hours of the mailed statement. Your statements from within the
                      past 2
                      years
                      are displayed below.


                    </p>
                    <p>For questions about your statements, please review our <a href="javascript:void(0)"
                        [routerLink]="['/navigation', authorProfileId,'faq']"
                        class="font-size-16 font-weight-bold">FAQs</a> or email <a href="mailto:royaltydept@hbgusa.com"
                        class="font-size-16 font-weight-bold">royaltydept@hbgusa.com</a>.
                    </p>
                  </div>
                </div>
              </div>
              <div class="content sales-dashboard p-4">
                <div class="sales-grid table-responsive">
                  <p class="text-end">
                    <span *ngIf="displayCollection.length != 0">
                      {{ numStatements() }} {{ plural( numStatements() )}} available</span>
                    <span *ngIf="displayCollection.length == 0">
                      0 statement available</span>

                  </p>
                  <table aria-describedby="royalty-statement" class="table table-striped table-condensed">
                    <thead>
                      <tr>
                        <th scope="col">Period Ending</th>
                        <th scope="col">Contract ID</th>
                        <th scope="col">Titles</th>
                        <th scope="col">Imprints</th>
                        <th scope="col">Download PDF</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of displayCollection">
                        <td>{{item.contractPeriodString | date: 'MM.dd.yy' }}
                        </td>
                        <td>{{stringify( item.contractId )}}</td>
                        <td>{{stringify( item.titles )}}</td>
                        <td>{{stringify( item.imprints )}}</td>
                        <td class="pdf-download">
                          <a href="{{ item.pdfUrl }}" rel="noopener" target="_blank"><strong><i class="fas fa-download"></i>
                              Download
                              PDF</strong></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane fade p-0" id="subrights-tab" role="tabpanel" *ngIf="selectedMode.name === 'subrights'"
              [ngClass]="{'active show': selectedMode.name === 'subrights'}" aria-labelledby="about-your-book-tab">
              <div class="content">
                <h4 class="back-line-txt text-center my-3">
                  <span class="">SUBRIGHTS</span>
                </h4>
                <div class="row">
                  <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
                    <div class="d-md-flex d-block">
                      <div class="flex-shrink-0 text-md-start text-center mb-3">
                        <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                          <img alt="" class="fix-height" src="{{bookInfo?.coverImgURL}}">
                        </div>
                      </div>
                      <div
                        class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                        <h1 class=" mb-3 extra-size">{{bookInfo?.title}}</h1>
                        <h4 class="lh-base">by {{bookInfo?.products[0].byLine}}</h4>
                        <p class="lh-base">On Sale {{bookInfo?.products[0].onSaleDate |date :
                          'MMM dd, yyyy'}}</p>

                        <button *ngIf="showChooseADifferentBook" type="button"
                          class="btn pull-right btn-theme-blue px-4 end-0 lh-1 choose-diffrent my-2 my-md-0"
                          (click)="chooseDifferentBook('subrights')">
                          Choose a Different Book
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-xl-6 text-md-start text-center">
                    <p class="lh-base">Below you’ll find a list of finalized subsidiary rights
                      licenses
                      for your book. Please
                      contact Hachette’s subsidiary rights department via your editor for more
                      detail
                      about
                      terms and payments. Please note that subsidiary rights payments are subject
                      to
                      deductions
                      for co-agent commissions and international taxes.</p>
                    <a href="#" [routerLink]="['/navigation', authorProfileId ,'faq' , 'subrights']">Read our
                      Subrights FAQ
                      <i class="fas fa-chevron-right ms-2"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="content sales-dashboard p-0" *ngIf="subrightsUrl">
                <app-mstr-view [msURL]="subrightsUrl" [dashboard]="'subrights'" [loading]="true">
                </app-mstr-view>
              </div>

            </div>
            <div class="tab-pane fade p-0" id="compare-tab" role="tabpanel" *ngIf="selectedMode.name === 'compare'"
              [ngClass]="{'active show': selectedMode.name === 'compare'}" aria-labelledby="about-you-tab">
              <div class="content">
                <h4 class="back-line-txt text-center my-3">
                  <span class="">Compare Books</span>
                </h4>
                <div class="row">
                  <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
                    <div class="d-md-flex d-block">
                      <div class="flex-shrink-0 text-md-start text-center mb-3">
                        <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                          <img href="#" alt="" class="fix-height cursor-pointer" src="{{bookInfo?.coverImgURL}}"
                            [routerLink]="['/navigation', authorProfileId,'book',bookInfo?.isbn]"
                            onerror="this.src='./assets/images/default-book.svg';">
                        </div>
                      </div>
                      <div
                        class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                        <h1 class=" mb-3 extra-size">{{bookInfo?.title}}</h1>
                        <h4 class="lh-base">by {{bookInfo?.products[0].byLine}}</h4>
                        <p class="lh-base">On Sale {{bookInfo?.products[0].onSaleDate |date :
                          'MMM dd, yyyy'}}</p>
                        <button *ngIf="showChooseADifferentBook" type="button"
                          class="btn pull-right btn-theme-blue px-4 end-0 lh-1 choose-diffrent my-2 my-md-0"
                          (click)="chooseDifferentBook('sales')">
                          Choose a Different Book
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
                    <div class="d-md-flex d-block">
                      <div class="flex-shrink-0 text-md-start text-center mb-3">
                        <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                          <img href="#" alt="" class="fix-height cursor-pointer" src="{{compareBookInfo?.coverImgURL}}"
                            [routerLink]="['/navigation', authorProfileId,'book',compareBookInfo?.isbn]"
                            onerror="this.src='./assets/images/default-book.svg';">
                        </div>
                      </div>
                      <div *ngIf="compareBookInfo?.products && compareBookInfo?.products.length > 0"
                        class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                        <h1 class=" mb-3 extra-size">{{compareBookInfo?.title}}</h1>
                        <h4 class="lh-base">by {{compareBookInfo?.products[0].byLine}}</h4>
                        <p class="lh-base">On Sale {{compareBookInfo?.products[0].onSaleDate |date :
                          'MMM dd, yyyy'}}</p>
                        <button *ngIf="showChooseADifferentBook" type="button"
                          class="btn pull-right btn-theme-blue px-4 end-0 lh-1 choose-diffrent my-2 my-md-0"
                          (click)="chooseDifferentBook('compare')">
                          Choose Book to Compare
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content sales-dashboard p-0" *ngIf="salesUrl">
                <app-mstr-view [msURL]="compareUrl" [dashboard]="'sales'" [loading]="true">
                </app-mstr-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container>
    </ng-container>
  </section>

  <section class="unit-ledger" *ngIf="unitLedgerSelectedProduct && pagePermission?.canViewRoyalties">
    <div class="container">
      <h4 _ngcontent-elu-c173="" class="back-line-txt text-center my-3"><span>UNIT LEDGERS</span></h4>
      <p class="font-size-14">A unit ledger is a supplemental document that corresponds to your royalty statement. It
        breaks out the units on your statement into gross sales, returns, and reserves for each ISBN and price point.
      </p>
      <p class="font-size-14">For questions about your unit ledgers, please review our <a href="javascript:void(0)"
          [routerLink]="['/navigation', authorProfileId,'faq']" class="font-size-16 font-weight-bold">FAQs</a> or email
        <a href="mailto:royaltydept@hbgusa.com" class="font-size-16 font-weight-bold">royaltydept@hbgusa.com</a>.</p>
      <div class="row mt-md-4 mt-2 mb-md-2 mb-1">
            <div class="col pr-0">
              <div class="input-group slect-author">
                <select class="form-control rounded py-0" [(ngModel)]="selectedUnitLedgerIsbn" (keydown)="onKeydown($event)"
                  (change)="selectedIsbn10Change()">
                  <ng-container *ngFor="let unitLedgerTitle of unitLedgerTitles">
                    <option [ngValue]="unitLedgerTitle.key">
                      {{unitLedgerTitle.value}}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col pr-0">
              <div class="input-group slect-author">
                  <select class="form-control rounded py-0" [(ngModel)]="selectedContractId"
                      (change)="selectedContractChange()">
                      <ng-container *ngFor="let jointAccountContractId of jointAccountContractIds">
                          <option [ngValue]="jointAccountContractId">
                              Statement ({{jointAccountContractId}})
                          </option>
                      </ng-container>
                  </select>
              </div>
          </div>
            <div class="col pr-0">
              <div class="input-group slect-author">
                <select class="form-control rounded py-0" [(ngModel)]="selectedCumulativePeriod"
                  (change)="selectedCumulativePeriodChange()" (keydown)="onKeydown($event)">
                  <option hidden value="" selected="selectedCumulativePeriod == ''">Cumulative to</option>
                  <option *ngFor="let cumulativePeriods of unitLedgerSelectedProduct.cumulativePeriods"
                    [value]="cumulativePeriods">
                    Cumulative to {{cumulativePeriods}}
                  </option>
                </select>
              </div>
            </div>
            <div class="or-width px-2 pt-2 text-center">or</div>
            <div class="col mb-2 pl-0">
              <div class="input-group slect-author">
                <select class="form-control rounded py-0" [(ngModel)]="selectedStatementPeriod"
                  (change)="selectedStatementPeriodChange()" (keydown)="onKeydown($event)">
                  <option hidden value="" selected="selectedStatementPeriod == ''">Select a Current Period</option>
                  <option *ngFor="let statementPeriods of unitLedgerSelectedProduct.statementPeriods"
                    [value]="statementPeriods">
                    Current Period Ending {{statementPeriods}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-right export-pdf">
              <div class="dropdown" *ngIf="unitLedgerProductData && unitLedgerProductData.unitLedgerData.length > 0">
                <button class="btn btn-primary export-download dropdown-toggle" type="button" data-toggle="dropdown"
                  aria-expanded="false">
                  Export and Download
                </button>
                <div class="dropdown-menu">
                  <button class="dropdown-item" type="button"
                    (click)="exportUnitLedgerPdf('selectedFormat');addGoogleAnalytics('Selected Format PDF', 'selectedFormat')">Selected
                    Format PDF</button>
                  <button class="dropdown-item" type="button"
                    (click)="exportUnitLedgerXLSX('selectedFormat');addGoogleAnalytics('Selected Format XLS', 'selectedFormat')">Selected
                    Format XLS</button>
                  <button class="dropdown-item" type="button"
                    (click)="exportUnitLedgerPdfForAllFormats('allFormats');addGoogleAnalytics('All Format PDFs', 'allFormats')">ALL Formats PDFs</button>
                  <button class="dropdown-item" type="button"
                    (click)="exportUnitLedgerXLSX('allFormats');addGoogleAnalytics('All Format XLS', 'allFormats')">ALL Formats XLS</button>
                </div>
              </div>
            </div>
          </div>

      <div class="row isbn-list mt-3" *ngIf="unitLedgerProductData && unitLedgerProductData.titleData">
        <div class="col-7">
          <div class="row">
            <div class="col-md-auto text-left">
              <ul class="pl-0 mb-0">
                <li>ISBN: <strong>{{unitLedgerProductData.titleData.productIsbn10}}</strong></li>
                <div *ngIf="unitLedgerProductData && unitLedgerProductData.unitLedgerData.length > 0">
                  <li>Price Pub Date: <strong>{{unitLedgerProductData.titleData.pricePubDate}}</strong></li>
                  <li *ngIf="unitLedgerProductData.titleData.ukPrice > 0">UK Price:
                    <strong>{{unitLedgerProductData.titleData.ukPrice | currency:'GBP'}}</strong>
                  </li>
                  <li *ngIf="unitLedgerProductData.titleData.ukPrice === 0">USA Price:
                    <strong>{{unitLedgerProductData.titleData.usaPrice | currency:'USD'}}</strong>
                  </li>
                  <li *ngIf="unitLedgerProductData.titleData.ukPrice === 0">Canada Price:
                    <strong>{{unitLedgerProductData.titleData.canadaPrice | currency:'USD'}}</strong>
                  </li>
                </div>
              </ul>
            </div>
            <div class="col-md-auto">
              <ul class="pl-md-4 pl-0 mb-0 list-overflow">
                <li class="text-truncate">Title: <strong data-tooltip
                    title="{{unitLedgerProductData.titleData.title}}">{{unitLedgerProductData.titleData.title}}</strong>
                </li>
                <li class="text-truncate">Format: <strong data-tooltip
                    title="{{unitLedgerProductData.titleData.productDesc}}">{{unitLedgerProductData.titleData.productDesc}}</strong>
                </li>
                <li class="text-truncate">Author: <strong data-tooltip
                    title="{{selectedAuthorData.firstName}} {{selectedAuthorData.lastName}}">{{selectedAuthorData.firstName}}
                    {{selectedAuthorData.lastName}}</strong></li>
                <li class="text-truncate"
                  *ngIf="unitLedgerProductData && unitLedgerProductData.unitLedgerData.length > 0">Original Pub Date:
                  <strong data-tooltip
                    title="{{unitLedgerProductData.titleData.originalPubDate}}">{{unitLedgerProductData.titleData.originalPubDate}}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-5 pl-0 h-100 text-right">
          <!-- Pagination -->
          <nav aria-label="Page navigation">
            <ul class="custom-pagination pagination justify-content-end mt-4 mb-0 pt-3" *ngIf="pages && pages.length">
              <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                <a (click)="loadPagination(1)" class="page-link" (keyup.enter)="loadPagination(1)" [tabIndex]="currentPage == 1 ? -1 : 0">First</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                <a (click)="loadPagination(currentPage - 1)" class="page-link" (keyup.enter)="loadPagination(currentPage - 1)" [tabIndex]="currentPage == 1 ? -1 : 0">Previous</a>
              </li>
              <li *ngFor="let page of pages; let i = index" [ngClass]="{active:currentPage === page}"
                class="page-item number">
                <a *ngIf="page != '...'" (click)="loadPagination(page)" class="page-link" tabindex="0" (keyup.enter)="loadPagination(page)">{{page}}</a>
                <span *ngIf="page == '...'" class="page-link">{{page}}</span>
              </li>
              <li class="page-item next" [ngClass]="{disabled:currentPage === totalNoOfPages}">
                <a (click)="loadPagination(currentPage + 1)" class="page-link" [tabIndex]="currentPage == totalNoOfPages ? -1 : 0 " (keyup.enter)="loadPagination(currentPage + 1)">Next</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:currentPage === totalNoOfPages}">
                <a (click)="loadPagination(totalNoOfPages)" class="page-link" [tabIndex]="currentPage == totalNoOfPages ? -1 : 0 " (keyup.enter)="loadPagination(totalNoOfPages)">Last</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="row mt-4" *ngIf="unitLedgerProductData && unitLedgerProductData.unitLedgerData.length > 0">
        <div class="col-md-12 table-perview">
          <table aria-describedby="printing" class="unit-ledger-table table table-bordered text-center">
            <thead class="">
              <tr>
                <th scope="col" [width]="70">Sale Type</th>
                <th scope="col">Description</th>
                <th scope="col" [width]="120">Royalty Type</th>
                <th scope="col" [width]="120" (click)="sortUnitLedgerTableData('grossUnits')" class="cursor-pointer"><strong>Gross <br>
                    Units</strong></th>
                <th scope="col" [width]="120" (click)="sortUnitLedgerTableData('returnUnits')" class="cursor-pointer"><strong>Return <br>
                    Units</strong></th>
                <th scope="col" [width]="120" (click)="sortUnitLedgerTableData('reserveUnits')" class="cursor-pointer"><strong>Reserve
                    <br> Units </strong>
                </th>
                <th scope="col" [width]="120" (click)="sortUnitLedgerTableData('adjustedNetUnits')" class="cursor-pointer">
                  <strong>Adjusted Net <br>
                    Units</strong></th>
                <th scope="col" [width]="120" (click)="sortUnitLedgerTableData('statementUnits')" class="cursor-pointer">
                  <strong>Statement <br> Units</strong>
                </th>
              </tr>
            </thead>
            <tbody class="border-0">
              <tr *ngFor="let unitLedgerData of unitLedgerProductData.unitLedgerData; let i = index">
                <td>{{unitLedgerData.saleType}}</td>
                <td>{{unitLedgerData.description}}</td>
                <td>{{unitLedgerData.royaltyType}}</td>
                <td>{{unitLedgerData.grossUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerData.returnUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerData.reserveUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerData.adjustedNetUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerData.statementUnits | number | minusSignToParens}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td>Total</td>
                <td></td>
                <td>{{unitLedgerProductData.ledgerTotalData.totalGrossUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerProductData.ledgerTotalData.totalReturnUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerProductData.ledgerTotalData.totalReverseUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerProductData.ledgerTotalData.totalAdjustedNetUnits | number | minusSignToParens}}</td>
                <td>{{unitLedgerProductData.ledgerTotalData.totalStatementUnits | number | minusSignToParens}}</td>
              </tr>
            </tfoot>
          </table>
          <!-- Pagination -->
          <nav aria-label="Page navigation">
            <ul class="custom-pagination pagination justify-content-end" *ngIf="pages && pages.length">
              <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                <a (click)="loadPagination(1)" class="page-link" (keyup.enter)="loadPagination(1)" [tabIndex]="currentPage == 1 ? -1 : 0">First</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                <a (click)="loadPagination(currentPage - 1)" class="page-link"  (keyup.enter)="loadPagination(currentPage - 1)" [tabIndex]="currentPage == 1 ? -1 : 0">Previous</a>
              </li>
              <li *ngFor="let page of pages; let i = index" [ngClass]="{active:currentPage === page}"
                class="page-item number">
                <a *ngIf="page != '...'" (click)="loadPagination(page)" class="page-link" tabindex="0" (keyup.enter)="loadPagination(page)">{{page}}</a>
                <span *ngIf="page == '...'" class="page-link">{{page}}</span>
              </li>
              <li class="page-item next" [ngClass]="{disabled:currentPage === totalNoOfPages}">
                <a (click)="loadPagination(currentPage + 1)" class="page-link" [tabIndex]="currentPage == totalNoOfPages ? -1 : 0 " (keyup.enter)="loadPagination(currentPage + 1)">Next</a>
              </li>
              <li class="page-item" [ngClass]="{disabled:currentPage === totalNoOfPages}">
                <a (click)="loadPagination(totalNoOfPages)" class="page-link" [tabIndex]="currentPage == totalNoOfPages ? -1 : 0 " (keyup.enter)="loadPagination(totalNoOfPages)">Last</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="text-center"
        *ngIf="unitLedgerProductData && unitLedgerProductData.unitLedgerData.length === 0 && unitLedgerType === 'statement'">
        <div class="mt-3">
          <div class="col extra-light-blue-color rounded px-5 py-3">
            <h5 class="font-size-18 mb-1">No Current Period Activity</h5>
          </div>
        </div>
      </div>

    </div>
  </section>

</div>


<app-footer></app-footer>