import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { PermissionService } from '../permission.service';
import { SessionService } from '../session.service';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})

export class PermissionResolveService implements Resolve<any> {
  constructor(private http: HttpClient, private auth: AuthService, private utilityService : UtilitiesService,
     private permissionService: PermissionService, private router: Router, private sessionService: SessionService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!this.auth.getUserId()) {
      this.sessionService.logout();
      return false;
    }

    this.utilityService.showLoader();
    return this.permissionService.getPermission().toPromise()
      .then(res => {
        this.utilityService.hideLoader();
        let data : any = {};
        data = res;
        if(state.url.indexOf('questionnaire') > 0 &&  data && (data?.canViewAq == 0)) {
          this.router.navigate(['']);
        }    
        if(state.url.indexOf('sales') > 0 &&  data &&  (data?.canViewSales == 0)) {
          this.router.navigate(['']);
        }       
        return res;
      }).catch(err => {
        this.utilityService.hideLoader();
        this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

        return null;
      });


  }

  public loadAuthorPortalHttpOptions = {
    headers: new HttpHeaders({
      'cache-control': 'no-cache',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': '7a6b9fd4d2c34584a9a4f6555873e48d'
    }),
  };
}
