export const environment = {
production: true,
"pluginUrl": "https://authorportal.hbgusa.com",
"api": "/api/jsonws",
"baseUrl": "",
"TERMS_OF_USE_CONTENT": "/widget/web/guest/banners/-/56_INSTANCE_LryVpsXUzK1O",
"multiSelectSeparator":"|",
"PRIVACY_POLICY_CONTENT": "/widget/web/guest/banners/-/56_INSTANCE_LryVpsXUzK1O",
"msDashboardUrl": "https://hbganalytics.hbgusa.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-APP00.PRD.HBGUSA.COM&Project=Author+Portal&Port=0&evt=2048001&src=mstrWeb.2048001&documentID=98AF363211E77DCF4E770080EFD5E2C0&currentViewMedia=1&visMode=0&elementsPromptAnswers=10208D094BA0ED5F9DCEB0906564ACAA;10208D094BA0ED5F9DCEB0906564ACAA::primary,040617D94718D8D7EC6162AEE2268D25;040617D94718D8D7EC6162AEE2268D25::secondary&valuePromptAnswers=:count&hiddenSections=path,dockTop",
"msCompareUrl": "https://hbganalytics.hbgusa.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-APP00.PRD.HBGUSA.COM&Project=Author+Portal&Port=0&evt=2048001&src=mstrWeb.rwd.2048001&documentID=98AF363211E77DCF4E770080EFD5E2C0&currentViewMedia=1&visMode=0&elementsPromptAnswers=10208D094BA0ED5F9DCEB0906564ACAA;10208D094BA0ED5F9DCEB0906564ACAA::primary,040617D94718D8D7EC6162AEE2268D25;040617D94718D8D7EC6162AEE2268D25::compare&valuePromptAnswers=3&evt=2048076&src=mstrWeb.oivm.rwb.2048076&psKey=W51&pKey=W987&evtorder=2048001%2c2048076&2048001=1&2048076=1&hiddenSections=path,dockTop&evtwait=true",
"msSalesGlanceUrl": "https://hbganalytics.hbgusa.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-APP00.PRD.HBGUSA.COM&Project=Author+Portal&Port=0&evt=2048001&src=mstrWeb.2048001&documentID=073E595A11E782B400000080EF75088F&currentViewMedia=1&visMode=0&elementsPromptAnswers=10208D094BA0ED5F9DCEB0906564ACAA;10208D094BA0ED5F9DCEB0906564ACAA::primary&hiddenSections=path,dockTop",
"msSubrightsUrl": "https://hbganalytics.hbgusa.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-APP00.PRD.HBGUSA.COM&Project=Author+Portal&Port=0&evt=2048001&src=mstrWeb.2048001&documentID=36E0C79311E7A9DD00000080EF65465B&currentViewMedia=1&visMode=0&elementsPromptAnswers=10208D094BA0ED5F9DCEB0906564ACAA;10208D094BA0ED5F9DCEB0906564ACAA::primary&hiddenSections=path,dockTop",
"mslogoutUrl": "https://hbganalytics.hbgusa.com/MicroStrategy/servlet/taskProc",
"auth": "&usrSmgr=:authToken",
"msLoading": "loading mstr data",
"msLoadingComplete": "mstr loading complete",
"mstrPassthrough": false,
"publishingGroups": "/widget/web/guest/hbg-news/-/56_INSTANCE_AqcPb9dyvkU4",
"hbgInTheNews": "https://www.hachettebookgroup.com/tag/hbginthenews",
"salesFaqUrl": "/widget/web/guest/faq/-/56_INSTANCE_eH4bdIrnNTe4",
"subrightsFaqUrl": "/widget/web/guest/faq/-/56_INSTANCE_vScQ5NllLmOB",
"googleAnalyticsKey": "G-RNE5K2ZZBY",
"googleTagManagerKey": "GTM-W5HHKJN",
"alfrescoUrl": "https://authorportal.hbgusa.com/alfresco-adf",
"coverImgURL": "https://coverimages.hbgusa.com/coverimages/",
"Ocp-Apim-Subscription-Key": "5b66d282d5bc4fe1b117973bdbaccc04",
"d3AuthorApiUrl" :"https://hbg-sitea-prd-api-mgmt.azure-api.net/hbg-authorportal-api/api/v1/",
"utilApiUrl" : "https://hbg-sitea-prd-api-mgmt.azure-api.net/hbg-util-api",
apiParams: {
'Ocp-Apim-Trace': 'true',
'Ocp-author-Apim-Subscription-Key': '5b66d282d5bc4fe1b117973bdbaccc04',
'Ocp-util-Apim-Subscription-Key': '5b66d282d5bc4fe1b117973bdbaccc04',
'Origin': 'azure',
'clientId': 'APO'
},
"authorGuideUrl": '/epub/?epub=HBG-Author-Guide',
};
