<div *ngFor="let feed of firstThreeFeeds; let i = index">
    <div class="w-100">
        <div class="image-box w-100">
            <img src="{{feed.featuredImage}}" class="img-fluid" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'newsArticle', feed.articleId]"
                (click)="getFirstThreeFeeds(feed?.articleId)">
        </div>
        <div class="image-links" (click)="getFirstThreeFeeds(feed?.articleId)" [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'newsArticle', feed.articleId]">{{feed?.title}}
        </div>
    </div>
    <hr [ngClass]="i === firstThreeFeeds.length -1 ? 'd-none' : ''" />
</div>
<div class="w-100 image-links border-top border-bottom py-3 text-center">
    <a [routerLink]="['/navigation', selectedAuthorData?.authorProfileId,'newsArchive']">MORE NEWS
    </a>
</div>