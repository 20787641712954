import { HttpClient, HttpHeaders } from '@angular/common/http';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
// import { REFUSED } from 'dns';
// import { filter } from 'lodash';
import { Observable, Subject, forkJoin, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { UtilitiesService } from 'src/app/service/utilities.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
// import * as CryptoJS from 'crypto-js';
// import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  httpOptions: { headers: HttpHeaders; };
  public permission: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, private auth: AuthService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    };
  }
  getPermission() {
    let url = environment.d3AuthorApiUrl + "user/permissions?userId=" + this.auth.getUserId() + "&authorProfileId=" + this.auth.getSelectedAuthor();
    return this.http.get(url, this.loadAuthorHttpOptions()).pipe(map((res) => {
      this.permission = res;
      return this.permission;
    }));
  }

  public loadAuthorHttpOptions() {
    return {
      'headers': new HttpHeaders({
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'Ocp-Apim-Subscription-Key':environment.apiParams['Ocp-author-Apim-Subscription-Key'] ,
      }),
    };
  }
  public getPagePermission(){
    return this.permission;
  }
 
}
