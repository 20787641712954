import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsOfUseComponent } from '../login/terms-of-use/terms-of-use.component';
import { PermissionService } from '../service/permission.service';
import { UtilitiesService } from '../service/utilities.service';
import { BookSelectorService } from '../service/book-selector.service';
import { AnalyticsService } from '../service/analytics.service';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  selectedAuthorId: string;
  pagePermission: any;
  currentYear: number = new Date().getFullYear();
  activeInterval;
  
  constructor(public modalService: NgbModal, private permissionService: PermissionService, private router: Router, private utilityService: UtilitiesService, 
    private bookSelectorService: BookSelectorService, private analyticsService: AnalyticsService, private authService: AuthService) {
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          this.pagePermission = this.permissionService.getPagePermission();
        }
      });
    }
    
  ngOnInit(): void {
    this.selectedAuthorId = this.authService.getSelectedAuthor();
  }
  public routeToSales() {
    let sales_works = this.bookSelectorService.allIsbns;
    if (sales_works && sales_works.length == 0) {
      this.utilityService.showInformation("There is no data to display at this time.", 'NotAllowed');
    } else {
      this.router.navigate(['/navigation', this.selectedAuthorId, 'salesDashboard']);
    }
  }

  public routeToQA() {
    if (this.pagePermission.canViewAq == 1) {
      this.router.navigate(['/navigation', this.selectedAuthorId, 'questionnaire', 'aboutYou']);
    } else {
      // this.utilityService.showInformation("Sorry, You do not have permission to view this feature.", 'NotAllowed');
    }
  }
  getTermOfUser() {
    const modalRef = this.modalService.open(TermsOfUseComponent, {
      centered: true,
      size: "md",
      windowClass: "animated fadeIn"
    });
  }

  public gaClick(eventAction: string) {

    eventAction = 'Footer - ' + eventAction;
    this.analyticsService.eventEmitter(eventAction, window.location.href, 'clicked');
  }
}
