import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import {
    orderBy as _orderBy, filter
} from "lodash-es";
import * as moment from 'moment';
const ERROR_MESSAGE = "There was a problem in processing your request. Please try again later.";

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {

    private modals: any[] = [];
    private infoDialogModal = new Subject<any>();
    private showLoaderSubject: any = new Subject();
    loader = this.showLoaderSubject.asObservable();

    private selectedBookChange: any = new Subject();
    selectedBook = this.selectedBookChange.asObservable();
  currentPayeeData: any;
    constructor() { }

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        const modal: any = this.modals.filter(x => x.id === id)[0];
        modal.open();
    }

    close(id: string) {
        // close modal specified by id
        const modal: any = this.modals.filter(x => x.id === id)[0];
        modal.close();
    }

    showInformation(message?: any, type?: any, title?: any) {
        this.infoDialogModal.next({ message: message, type: type, title: title });
    }

    showInformationStatus(): Observable<any> {
        return this.infoDialogModal.asObservable();
    }


    getSelectedBook(): Observable<any> {
        return this.selectedBookChange.asObservable();
    }

    changeSelectedBook(selectedBook: any) {
        this.selectedBookChange.next(selectedBook);
    }

    count = 0;
    showLoader() {
        this.count += 1;
        this.showLoaderSubject.next(true)
    }

    hideLoader(forceHide?: boolean) {


        this.count -= 1;
        if (this.count <= 0 || forceHide) {
            this.count = 0
        }

        if (this.count == 0) {
            this.showLoaderSubject.next(false)
        }

    }

    getAuthorData() {
        return JSON.parse(localStorage.getItem('selectedAuthorData'));
    }

    /* This function returns book with OSD closest to today in the future.
    If no future book exists, shows most recent published book.
    This function assumes that the parameter works is already in decending order. */
    getCurrentBookFuture = function (works) {
        var currentBook;
        currentBook = _orderBy(works, function (work) {
            return Math.abs(moment().diff(work.onSaleDate, 'days'));
        }, 'asc')[0];

        return currentBook;
    };

    getAuthorQuestionnairePdfData = function (params) {
        const url = location.protocol + '//' + location.host + '/web/guest/questiond?p_p_id=APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_resource_id=downloadAuthorQuestionnaire&p_p_cacheability=cacheLevelPage&p_p_col_id=column-1&p_p_col_count=1&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_scopeType=' + params.scopeType + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_aboutYouQuestionnaireId=' + params.aboutYouQuestionnaireId + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_authorName=' + params.authorName + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_aboutYourBookQuestionnaireId=' + params.aboutYourBookQuestionnaireId + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_scopeId=' + params.scopeId + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_bookName=' + params.bookName + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_authorId=' + params.authorId + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_check=true';
        let a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
    };

    getContactData = function (params) {
        const url = location.protocol + '//' + location.host + '/web/guest/questiond?p_p_id=APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_resource_id=downloadAuthorContactData&p_p_cacheability=cacheLevelPage&p_p_col_id=column-1&p_p_col_count=2&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_scopeType=' + params.scopeType + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_authorName=' + params.authorName + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_contactType=' + params.contactType + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_scopeId=' + params.scopeId + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_bookName=' + params.bookName + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_authorId=' + params.authorId + '&_APQuestionnaireDownload_WAR_APQuestionnaireDownload10SNAPSHOT_check=true';
        let a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
    };

    public getErrorMessage() {
        return ERROR_MESSAGE;
    }


    filterProductWorkData(authorWorks) {     
        authorWorks.forEach((work, index) => {
            work.products = filter(work.products, { 'futureOnix': 0 });
        });
        authorWorks = authorWorks.filter(function (work) {
            return work.products.length > 0;
        });
        return authorWorks;
    }

    private selectedBookInfo: any = {};
    saveBookInfo(data: any) {
        this.selectedBookInfo = data;
    }
    public getBookInfo() { return this.selectedBookInfo; }

    public replaceSpecialCharatersWithUnderscore(value: string) {
        return value.replace(/[/\\?*:|"<>]/g, '_').replace(/\_+/g, '_');
    }
}
