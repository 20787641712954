import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesDashboardService {

  

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  portletApi: any =  environment.api + '/sales-data-dashboard-portlet.salesdata/';

  public loadUtilHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'clientId': environment.apiParams.clientId,
        'Ocp-Apim-Trace': environment.apiParams['Ocp-Apim-Trace'],
        'Ocp-Apim-Subscription-Key': environment.apiParams['Ocp-Apim-Subscription-Key'],
        // 'Authorization': 'Bearer ' + this.authService.getToken(),
      }),
    };
  }

  getRetailerChannels(requestData: any) {
    const formData = new FormData();
    formData.append('productIsbn', JSON.stringify(requestData));

    const url = this.portletApi + 'getRetailerChannels';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  getPOSWorks(workData : any) {
    let requestData = {
      "endOnSaleDate": "",
      "retailerChannelsCodes": [],
      "startOnSaleDate": "",
      "workIsbn": workData.isbn,
      "productIsbns" : workData.productIsbns
    }
    if(workData.unit){
      requestData["unit"]=workData.unit;
      requestData["count"]=workData.count;
    }
    
    const formData = new FormData();
    formData.append('json', JSON.stringify(requestData));
    const url = this.portletApi + 'getPosData';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }
  public loadAuthorHttpOptions() {
    return {
      'headers': new HttpHeaders({
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'Ocp-Apim-Subscription-Key':environment.apiParams['Ocp-author-Apim-Subscription-Key'] ,
      }),
    };
  }

  getPrintings(requestData: any) {

    const formData = new FormData();
    formData.append('productIsbn', JSON.stringify(requestData));
    const url = this.portletApi + 'getPrintings';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  getUnitLedgerOptions(workIsbn: any, authorProfileId: any) {
    const url = this.portletApi + `getUnitLedgerOptions?workIsbn=${workIsbn}&authorProfileId=${authorProfileId}`;
    return this.http.get(url, this.loadAuthorHttpOptions());
  }

  getUnitLedgerData(requestData) {
    const formData = new FormData();
    formData.append('requestJson', JSON.stringify(requestData));
    const url = this.portletApi + 'getUnitLedgerData';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  exportUnitLedgerExcel(requestData: any) {
    const formData = new FormData();
    formData.append('requestJson', JSON.stringify(requestData));
    const url = this.portletApi + `exportUnitLedgerExcel`;
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  exportUnitLedgerPdf(requestData: any) {
    const formData = new FormData();
    formData.append('requestJson', JSON.stringify(requestData));
    const url = this.portletApi + `exportUnitLedgerPdf`;
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  getLifetimePerformance(requestData: any) {
    const formData = new FormData();
    formData.append('productIsbn', JSON.stringify(requestData));
    const url = this.portletApi + 'getLifetimePerformance';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

}
