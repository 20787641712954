<div class="text-center">
    <h4 class="mb-4 mt-4 merriweather font-size-36 text-black-color">
        <i class="check-mark"></i>
        <span> Success! </span>
    </h4>
    <p>
        <span>
            Your password has been changed.
        </span>
    </p>
</div>
<div class="modal-footer border-white d-flex justify-content-center mb-3 pt-0">
    <button (click)="activeModal.close()" class="btn btn-theme-blue px-5">Done</button>
</div>