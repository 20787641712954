<app-header></app-header>
<div class="container">
    <div class="common-container mt-4 py-2 px-4">       
        <div class="faq-content">
            <h4 class="back-line-txt text-center my-3"><span>FAQ</span></h4>
            <div id="sales">
                <div class="faq-title">Sales FAQs</div>
                <div class="iframe-container">
                    <iframe  frameBorder="0" width="100%" class="sales-faq" id="SalesFaq" [src]="salesFaqUrl" scrolling="no"></iframe>
                </div>
            </div>
            <div id="subrights">
                <div class="faq-title">Unit Ledger FAQs</div>
                <div class="iframe-container">
                    <iframe frameBorder="0" width="100%" class="subright-faq" id="SubrightFaq" [src]="subrightsFaqUrl" scrolling="no"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>