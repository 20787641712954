import { Component, OnInit } from '@angular/core';
import { AuthorService } from 'src/app/service/author.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { slice, isEmpty, remove, forEach, findIndex, find, pull, uniq, cloneDeep, sortBy, clone, isEqual, filter } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-team-member-profile',
  templateUrl: './team-member-profile.component.html',
  styleUrls: ['./team-member-profile.component.scss']
})
export class TeamMemberProfileComponent implements OnInit {
  teamList: any = [];
  defaultImage: any = "assets/images/dummy-user.png";
  teamMemberPhotos: any = [];
  workIsbn : any;
  emailAddress: any;
  userBio: any;
  base64Image: any;
  memberId: any;
  constructor(private authorService: AuthorService, public auth: AuthService,
     public utilityService: UtilitiesService, private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.params.subscribe((params: any) => {
      this.workIsbn = params.workIsbn.toString();
      this.memberId = params.memberId;
      this.getTeamDetails();
    });
  }

  ngOnInit(): void {
    // this.getTeamDetails() 
  
  }


  getUserBio(emailAddress: any) {

    this.authorService.getUserBio(emailAddress).subscribe((data: any) => {
      if (data) {
        this.userBio.bioInformation = data.bio;
        document.getElementById("userBioInfo").innerHTML = this.userBio.bioInformation;
        this.utilityService.hideLoader();
      }
    }, err => {
      this.utilityService.hideLoader();
      // this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  getTeamDetails() {
    this.utilityService.showLoader();
    this.authorService.getTeamMemberInfo(this.workIsbn).subscribe((data: any) => {
      this.utilityService.hideLoader();
      if (data) {
      
        this.teamList = data;
        const index = findIndex(this.teamList, { 'userId': Number(this.memberId) });
        if (index !== -1) {
          // this.emailAddress = this.teamList[index];
          this.userBio = cloneDeep(this.teamList[index]);
          this.getUserBio(this.teamList[index].emailAddress);
          this.loadUserPhoto(this.teamList[index].emailAddress);
        
          this.teamList.splice(index, 1);
        }
      }
    }, err => {
      this.utilityService.hideLoader();
      this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');
    });
  }

  loadUserPhoto(emailAddress?: any) {
    if (!emailAddress) {
      return;
    }
    this.authorService.getUserPhoto(emailAddress).subscribe((result: any) => {
      if(result.data !="null"){
        this.base64Image = result.data ? 'data:image/jpg;base64,' + result.data : '' ;
        this.utilityService.hideLoader();
      }
    }, err => {
      this.utilityService.hideLoader();
      // this.utilityService.showInformation(this.utilityService.getErrorMessage(), 'Error');

    });
  }

  public getUserPhoto(){
    return this.base64Image ? this.base64Image:  this.defaultImage;
  }
}
