<app-header></app-header>
<section class="sales-format my-md-4 mt-md-5 pb-md-3">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="mb-md-0 mb-2">Sales Data by Format and Retail Channel</h1>
        </div>
        <div class="col-md">
          <div class="d-flex justify-content-end align-iteml-center text-md-start text-center">
            <div class="flex-shrink-0">
              <img src="assets/images/questin-icon.png" alt="...">
            </div>
            <div class="flex-grow-0 ml-2">
              <a class="text-decoration-none" href="javascript:void(0)" [routerLink]="['/navigation', selectedAuthorId,'faq']" class="text-decoration-none">
                Read our Sales Data Common Questions
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <p class="mb-0 font-size-16">The figures you can see in the chart below include all “point of sale” (POS) data
            reported to us
            by major
            retail accounts. Other accounts, including the wholesalers who supply libraries, bulk purchase made directly
            from HBG, and international export sales, are accounted for in the overall LTD (Life-to-date) Net in the table
            below Printings on this page.</p>
        </div>
      </div>
    </div>
  </section>
<div class="container">
  <div class="row">
    <div class="col">
        <div class="border-top w-100"></div>
        <div class="row my-2">
            <div class="col-8">            
          <div class="input-group slect-author align-iteml-center py-4 d-md-flex d-inline-block">   
            <label class="form-label mb-0 py-2">Select a Title:</label>       
               <select class="form-control ml-md-3 mb-md-0 mb-2 rounded py-0 mt-md-0 mt-2 mx-2" (keydown)="onKeydown($event)"
              [(ngModel)]="selectedWork"  (change)="selectedWorksChange()" id="type">
              <option  *ngFor="let works of selectedAuthor.works"   [ngValue]="works" [title]="works.title">
                {{works.title}}
                </option>
            </select>          
            <ng-container *ngIf="selectedWork && selectedWork.products">
              <ng-select [items]="selectedWork.uniqueProducts" [multiple]="true" bindLabel="productISBN"
                class="not-selectedshow form-control border-0 ml-md-3 mb-md-0 mb-2 rounded py-0 mt-md-0 mt-2 mx-2 px-0"
                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
                [(ngModel)]="selectedFormats" name="selectHeaderCol" (change)="selectedFormatChange($event)"
                [searchable]="false">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div *ngIf="index == 0 && selectedWork.uniqueProducts.length > 1" class="custom-control custom-checkbox" (click)="checkboxChecked(-1)"
                      style="margin-bottom: 5px;">
                      <input [checked]="selectAllFormat" id="item--1" class="custom-control-input" type="checkbox"
                        name="item-{{index}}" [ngModel]="selectAllFormat" />
                      <label class="custom-control-label">
                        Select All
                      </label>
                    </div>
                  <div class="custom-control custom-checkbox" (click)="checkboxChecked(index)">
                    <input [checked]="item.isVisible" id="item-{{index}}" class="custom-control-input" type="checkbox"
                      name="item-{{index}}" [ngModel]="item$.selected" />
                    <label class="custom-control-label"> 
                      {{item.productDesc == 'All Formats' ? item.productDesc : 'All ' + item.productDesc}}
                    </label>
                    <span class="float-right p-2" style="background-color:{{item.color}}"></span>
                  </div>

                </ng-template>
              </ng-select>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="border-bottom w-100 mt-1"></div>
    </div>
  </div>
</div>
<div class="container">
  <div *ngIf="selectedWork" class="search-panel mt-4">
    <div class="row">
      <div class="col align-self-end">
      </div>
      <div class="col">
        <h2 class="chart-title text-center mb-0 font-weight-bold">{{selectedWork.title}}</h2>
      </div>
      <div  class="col text-right">
        <div class="export-pdf"  [ngClass]="(chartPOSdata.length == 0 || !chartHasData) ? 'invert-color' : ''">
          <button type="button" class="btn p-0 mr-3 border-0 bg-transparent" (click)="exportToPDF()" [disabled]="(chartPOSdata.length == 0 || !chartHasData)"><img class="mr-1"
              width="16" src="assets/images/pdf-icon.png" alt=""> Export PDF</button>
          <button type="button" class="btn p-0 border-0 bg-transparent" (click)="exportCSV()" [disabled]="(chartPOSdata.length == 0 || !chartHasData)"><img class="mr-1"
              width="16" src="assets/images/export-icon.png" alt=""> Export XLS</button>
          <!-- <span *ngIf="dataLastUpdated && chartPOSdata.length > 0">
            <p class="m-0 font-weight-bold font-size-12px">{{dataLastUpdated}}</p>
          </span> -->

        </div>
      </div>
    </div>
  <div *ngIf="chartPOSdata.length > 0" id="line-chart" style="width: 100%; height: 450px;"> 
</div>

<!-- No-chart -->
<div *ngIf="chartPOSdata.length == 0" class="no-chart mb-4 mt-2"></div>
</div>
</div>

<section *ngIf="chartPOSdata.length > 0" class="land-of-ot">
  <div class="container">
    <div class="row my-4 ml-0 mr-1">
      <div class="col-md-3 mb-md-0 mb-2">
        <div class="input-group slect-author">
          <ng-select [items]="retailerChannels" [multiple]="true"
            class="not-selectedshow retailer-Channel form-control border-0 ml-md-3 mb-md-0 mb-2 rounded py-0 mt-md-0 mt-2 mx-2 px-0"
            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
            [(ngModel)]="selectedRetailerChannel" name="selectHeaderCol"
            (change)="selectedRetailerChannelChange($event)" [searchable]="false">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="custom-control custom-checkbox" (click)="ChangeRetailerChannelChange(item)">
                <input [checked]="item.isVisible" id="item-{{index}}" class="custom-control-input" type="checkbox"
                  name="item-{{index}}" [ngModel]="item$.selected" />
                <label class="custom-control-label">{{item}}</label>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-md mb-md-0 mb-2 px-0">
        <div class="input-group slect-author align-iteml-center">
          <span class=" mb-md-0 my-2">Custom Date Range:</span>
          <input [disabled] = "isWeekData" (focusout)="validateDate()" class="form-control ml-md-3 mr-md-2 rounded py-0 px-1" type="text"
            id="starttime" name="starttime" [(ngModel)]="starttime" (keypress)="inputValidation($event)" (paste)="onPasteStartDate($event)"
            placeholder="MM/DD/YYYY" [ngClass]="(validateDate() && !isWeekData )? 'border-danger' : ''" maxlength="10">
          <span class="my-2">to</span>
          <input [disabled] = "isWeekData" (focusout)="validateDate()" class="form-control ml-md-2 mr-md-2 rounded py-0 px-1" type="text" id="endtime"
            name="endtime" [(ngModel)]="endtime" placeholder="MM/DD/YYYY" (keypress)="inputValidation($event)" (paste)="onPasteEndDate($event)"
            [ngClass]="(validateDate() && !isWeekData ) ? 'border-danger' : ''" maxlength="10">
          <button type="button" class="btn btn-primary py-0 border-0 rounded-1" [disabled]="validateDate()"
            (click)="refresh()">Apply Date Range</button>
        </div>
      </div>
      <div class="col-md-auto text-md-start text-center mt-2 mt-md-0 pl-2">
        <div class="input-group slect-author align-iteml-center">
          <span class="my-2">or</span>
          <select class="form-control weeks-on-sale ml-md-2 rounded py-0" (keydown)="onKeydown($event)" [(ngModel)]="selectedDateValue"
            (change)="onSelectedWeekGroup()" placeholder="Weeks on sale">
            <option value="" disabled selected hidden>Weeks On-Sale</option>
            <option value="4_Week">4 Weeks On-Sale</option>
            <option value="8_Week">8 Weeks On-Sale</option>
            <option value="12_Week">12 Weeks On-Sale</option>
            <option value="6_Month">6 Months On-Sale</option>
            <option value="1_Year">1 Year On-Sale</option>
            <option value="2_Year">2 Years On-Sale</option>
            <option value="3_Year">3 Years On-Sale</option>
          </select>
          <button type="button" class="btn btn-secondary py-0 border-0 ml-2 rounded-1 btn-reset" (click)="reset()">Reset</button>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col p-0" *ngIf="selectedProductGroup && selectedProductGroup.length > 0">
        <div id="carouselExampleIndicators" class="carousel slide m-auto" data-bs-touch="false" data-interval="false" data-ride="carousel">
          <div class="carousel-inner px-5">
            <ng-container *ngFor="let ProductGroup of selectedProductGroup; let i = index">
              <div class="carousel-item" [ngClass]="i == 0 ? 'active' : ''">
                <div class="d-flex bd-highlight carousel-item-row">
                  <ng-container *ngFor="let products of ProductGroup; let pindex = index">
                    <div class="carousel-item-column flex-fill custom-item bg-gray position-relative border-left-yellow h-100">
                      <button type="button" class="position-absolute top-0 end-0 border-0 bg-transparent"
                        (click)="sliderSelectionChange(products)"><img class="close-box"
                          src="assets/images/close-icon.svg" alt="close"></button>
                      <ul class="pl-3 pr-1 pt-3 pb-2 mb-0" [style.border-left-color]="products.color">
                        <li data-tooltip title="{{products.productISBN != '9999999999' ? products.title : selectedWork.title}}"><b>
                            {{products.productISBN != '9999999999' ? products.title : selectedWork.title}}</b>
                        </li>
                        <li data-tooltip title="{{products.productISBN}}">
                          <span *ngIf="products.productISBN != '9999999999'">
                            ISBN: {{products.productISBN}}
                          </span>

                        </li>
                        <li data-tooltip title="{{products.productDesc === 'All Formats' ? products.productDesc: products.productDesc.slice(0, -1)}} {{products.subProductCodeDesc}}">
                          Format: <strong>{{products.productDesc === 'All Formats' ? products.productDesc: products.productDesc.slice(0, -1)}}
                          <span *ngIf="products.subProductCodeDesc">- {{products.subProductCodeDesc}}</span>
                          </strong>
                        </li>
                        <li *ngIf="products.productISBN != '9999999999'" data-tooltip title="{{products.workOnSaleDate}}">On-sale: {{products.workOnSaleDate | date: 'MM/dd/yyyy' }}</li>
                        <li data-tooltip title="{{salesPeriodDate}}">Selected Sales period: {{salesPeriodDate}}</li>
                        <li [ngClass]="products.productDesc === 'All Formats' ? 'total-sales' : '' " data-tooltip title="{{totalSales[products.productISBN] | number}}">Total Sales:<strong> {{totalSales[products.productISBN] | number}}</strong></li>
                        <li *ngIf="products.productISBN == '9999999999'" style="height: 15px;"></li>
                        <li *ngIf="products.productISBN == '9999999999'" style="height: 15px;"></li>

                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <button *ngIf="selectedProductGroup.length > 1" class="position-absolute h-100 ml-3 border pre-arrow px-1"
            type="button" data-target="#carouselExampleIndicators" data-slide="prev">
            <span class="carousel-control-prev-icon w-auto" aria-hidden="true"><img
                src="assets/images/left-slide-arrow.svg" alt=""></span>
          </button>
          <button *ngIf="selectedProductGroup.length > 1" class="position-absolute h-100 border mr-3 next-arrow px-1"
            type="button" data-target="#carouselExampleIndicators" data-slide="next">
            <span class="carousel-control-next-icon w-auto" aria-hidden="true"><img
                src="assets/images/right-slide-arrow.svg" alt=""></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container" *ngIf="printingData && printingData.length > 0">
  <div class="row mt-4">
    <div class="col table-perview">
      <h2 class="mt-5 mb-4">Printings</h2>
      <table class="table table-bordered text-center">
        <thead>
          <tr>
            <th scope="col" class="w-25">Format</th>
            <th scope="col" class="w-25">Total Copies in Print</th>
            <th scope="col" class="w-25">Number of Printings</th>
            <th scope="col" class="w-25">First Printing</th>
          </tr>
        </thead>
        <tbody class="border-0">
          <tr *ngFor="let printing of printingData; let i = index">
            <td class="text-start text-left">
              <strong>{{printing.productDesc}}<span *ngIf="printing.subProductDesc">: </span></strong>{{printing.subProductDesc}}
              <br />
              ISBN: {{printing.productISBN}}
            </td>
            <td class="align-middle"><strong>{{printing.totalCopiesInPrint == null ? 'N/A' : printing.totalCopiesInPrint |
                number}}</strong></td>
            <td class="align-middle"><strong>{{printing.numberOfPrintings == null ? 'N/A' : printing.numberOfPrintings |
                number}}</strong></td>
            <td class="align-middle"><strong>{{printing.firstPrinting == null ? 'N/A' : printing.firstPrinting | number}}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<section class="container table-perview lifetime-perfomance mt-5" *ngIf="lifetimePerformanceDataLength > 0">
  <div class="row">
    <div class="col">
      <h2>Lifetime Performance</h2>
    </div>
    <div class="col-md">
      <div class="d-flex justify-content-end align-iteml-center text-md-start text-center">
        <div class="flex-shrink-0">
          <img src="assets/images/questin-icon.png" alt="...">
        </div>
        <div class="flex-grow-0 ml-2">
          <a ref="javascript:void(0)" [routerLink]="['/navigation', selectedAuthorId,'faq']" class="text-decoration-none font-size-16 font-weight-bold">
            Read our Sales Data Common Questions
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <p class="font-size-16">The LTD (Life-to-Date) Net Units reflects other accounts, including the wholesalers
        who supply libraries, bulk
        purchase made directly from HBG, and international export sales. This will change over time as returns may be
        made.</p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <table aria-describedby="lifetime-perfomance" class="table table-bordered text-center">
        <thead>
          <tr>
            <th scope="col" width="20%">Format</th>
            <th scope="col">Gross Units</th>
            <th scope="col" class="font-weight-bold">LTD Net Units</th>
          </tr>
        </thead>
        <tbody class="border-0">
          <tr *ngFor="let lifetimePerformance of lifetimePerformanceData.lifetimePerformanceDtoList; let i = index">
            <td class="text-start text-left">
              <strong>{{lifetimePerformance?.productDesc}}<span *ngIf="lifetimePerformance.subProductDesc">:
                </span></strong>{{lifetimePerformance?.subProductDesc}}
              <br />
              ISBN: {{lifetimePerformance?.productISBN}}
            </td>
            <td class="align-middle"><strong>{{lifetimePerformance?.grossUnit == null ? 'N/A' : lifetimePerformance?.grossUnit |
                number}}</strong></td>
            <td class="align-middle"><strong>{{lifetimePerformance?.ltdUnit == null ? 'N/A' : lifetimePerformance?.ltdUnit |
                number}}</strong></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="font-weight-bold font-size-18 text-left">Totals</td>
            <td class="font-weight-bold font-size-18">{{lifetimePerformanceData?.grossUnitsTotal == null ? 'N/A' :
              lifetimePerformanceData?.grossUnitsTotal | number}}</td>
            <td class="font-weight-bold font-size-18 blue-color">{{lifetimePerformanceData?.ltdUnitTotal == null ? 'N/A' :
              lifetimePerformanceData?.ltdUnitTotal | number}} </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</section>
<!-- Lifetime Perfomance -->

<div *ngIf="pageLoader" id="loader-wrapper">
  <div id="loader"></div>
</div>

<app-footer></app-footer>