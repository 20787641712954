import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { Observable, of } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private http: HttpClient, private session: SessionService) { }

  BASE_URL: any = environment.api + '/ddl_data-portlet.ddldata';
  feedList: any;
  getBannerMessages() {

    var url = this.BASE_URL + '/get-banner-messages';

    return this.http.get(url, {
      params: {
        userLoginContext: this.session.getUserRole()
      }
    });
  }

  collapseBanner() {
    const formData = new FormData();
    formData.append('userLoginContext', this.session.getUserRole());
    formData.append('timeStamp', Date.now().toString());
    let url = this.BASE_URL + '/collapse-banner-callback';
    return this.http.post<any>(url, formData, ({ 'Content-Type': 'application/x-www-form-urlencoded' } as any));
  }

  getRssFeeds() {
    var url = environment.api + '/happi-common-service-portlet.happidisplaypreferences' + '/get-rss-feed';
    if (this.feedList && this.feedList.length > 0) {
      return of(this.feedList);
    }
    return this.http.get(url).pipe(map((res: any) => {
      var feeds = res;
      if (feeds.rss && feeds.rss.channel && feeds.rss.channel.item && feeds.rss.channel.item.length > 0) {


        feeds.rss.channel.item.forEach(function (feed) {

          var parser = new DOMParser();
          var description = parser.parseFromString('<div>' + feed['content:encoded'] + '</div>', 'text/html');
          // var errorCount = doc.body.getElementsByClassName('alert alert-error');

          // var description = angular.element('<div>' + feed['content:encoded'] + '</div>');
          feed.pubDateForOrdering = new Date(feed.pubDate);
          var searchImage = description.body.getElementsByClassName('attachment-post-thumbnail')
          if (searchImage.length > 0) {
            var add = '';
            feed.featuredImage = searchImage[0].getAttribute('src');
            description.body.getElementsByClassName('attachment-post-thumbnail')[0].remove();
            //  description.find('.attachment-post-thumbnail').attr('src');
            // description.find('.attachment-post-thumbnail').remove();
          }
          // description.find('.attachment-post-thumbnail').remove();
          feed['content:encoded'] = description.body.innerHTML;

          let url_string = feed.guid.content;
          feed['articleId'] = new URL(url_string).searchParams.get('p');

        });
        this.feedList = feeds.rss.channel.item;
        return feeds.rss.channel.item;
      }
      this.feedList = feeds.rss.channel.item;
      return feeds.rss.channel.item;
    }));
  }

  getSystemMessages() {
    // https://authorportalplugindev.hbgusa.com/api/jsonws/ddl_data-portlet.ddldata/get-system-messages/user-login-context/author?p_auth=5iPf3hCw
    var url = this.BASE_URL + '/get-system-messages';

    return this.http.get(url, {
      params: {
        userLoginContext: this.session.getUserRole()
      }
    });
  };

  clearCache() {
    this.feedList = [];
  }

  getAuthorGuideFileAsBase64(file: any):Observable<any> {
    return this.http.get(file, { responseType: 'blob' })
  }
  downloadAuthorGuideFile(file: any):Observable<any> {
    return this.http.get(file)
  }
}
