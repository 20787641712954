<div class="container">
  <div class="welcome-banner mt-4" *ngIf="banners && banners.length > 0">
    <!-- <div  ng-class="{'agent-banner' : $ctrl.isAuthorRole}" class="row welcome-banner" *ngIf="banners && banners.length"> -->
    <div class="close-btn" (click)="collapseBanner()">
      <i class="close-btn-img"></i>
    </div>
    <!-- <div uib-carousel active="$ctrl.active" interval="$ctrl.interval" ng-class="{'single-banner': banners.length === 1}"> -->
    <!-- <div uib-carousel active="active" interval="slideInterval">
    <div uib-slide *ngFor="let banner of banners;let track=index">
      <iframe class="col-md-12" width="100%" height="100%" frameBorder="0" [src]="banner.sourceURL"></iframe>
    </div>
  </div> -->

    <ngb-carousel class="welcome-carousel" *ngIf="banners" [showNavigationArrows]="false" [showNavigationIndicators]="true">
      <ng-template ngbSlide *ngFor="let banner of banners">
        <div class="picsum-img-wrapper">
          <iframe class="iframe-styling d-block w-100" width="100%" height="710" frameBorder="0" scrolling="no" [src]="banner.secureUrl"></iframe>

        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>