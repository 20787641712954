import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { SelectAuthorComponent } from '../shared/select-author/select-author.component';
import { ChooseDiffBookModalComponent } from '../shared/choose-diff-book-modal/choose-diff-book-modal.component'
import { find as _find, findIndex, filter,  cloneDeep as _cloneDeep } from "lodash-es";
import { UtilitiesService } from '../service/utilities.service';
import { environment } from 'src/environments/environment';
import { BookSelectorService } from '../service/book-selector.service';
import { PermissionService } from '../service/permission.service';
import { AuthorService } from '../service/author.service';
import { TypeScriptEmitter } from '@angular/compiler';
import { authordesc } from '../dashboard/about-author';
import { SessionService } from '../service/session.service';
import { HbgTeamComponent } from './hbg-team/hbg-team.component';
import * as moment from 'moment';
import { PageScrollService } from 'ngx-page-scroll-core';
import { AnalyticsService } from '../service/analytics.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  authorWorks: any = [];
  coverImgURL = environment.coverImgURL;
  currentBookFuture: any;
  loadDashboardData: any = false;
  description: string;
  authorBlurbTitlePostFix: string;
  isAuthorRole: boolean = true;
  showKeyDates: any = false;
  @ViewChild('hbgTeam')
  hbgTeamComponent: HbgTeamComponent;
  pagePermission: any;
  workList: any = [];
  authorImageUrl: string;
  showWelcomeBanner: boolean = false;
  hasPOSFLAG: boolean = true;

  constructor(public authService: AuthService, public modalService: NgbModal, private utilitiesService: UtilitiesService,
    public utilityService: UtilitiesService, private bookSelectorService: BookSelectorService, private sessionService: SessionService,
    private authorService: AuthorService, private activatedRoute: ActivatedRoute, private permissionService: PermissionService, private pageScrollService: PageScrollService,
    private router: Router, private analyticsService: AnalyticsService) {
    if(this.modalService.hasOpenModals()){
    this.modalService.dismissAll();
    // return;
    }

    this.pagePermission = this.permissionService.getPagePermission();
    if(this.pagePermission && (this.pagePermission.groupId == 2 || this.pagePermission.groupId == 3)) {
      this.showWelcomeBanner = true;
    }
    
    this.activatedRoute.params.subscribe((params: any) => {
      this.workList = [];
      this.authorWorks = [];
      var _authorId = params.id.toString().toLowerCase();
      var selectedAuthor = this.authService.getSelectedAuthor();

      if (selectedAuthor != _authorId) {
        this.loadDashboardData = false;
        this.authorService.getAuthors(true).subscribe((result: any) => {
          var selectedAuthor = _find(result, { 'authorProfileId': parseInt(_authorId) });
          if (selectedAuthor) {
            this.getAuthorImage(selectedAuthor);
            localStorage.setItem('selectedAuthorData', JSON.stringify(selectedAuthor));
          }
          this.loadDashboardData = true;
          this.authService.defaultChangeSelectedAuthor();
          this.loadAuthorsRelatedData();
        }, err => {
          this.utilitiesService.showInformation(this.utilitiesService.getErrorMessage(), 'Error');
          this.loadDashboardData = true;
        });
      } else {
        this.loadDashboardData = true;
        this.loadAuthorsRelatedData();
      }
    });

  }

  getAuthorImage(authorData: any) {
    var defaultUrl = "assets/images/author-user-image-default.svg";
    if (authorData.authorProfilePhotoId == null) {
      this.authorImageUrl = defaultUrl;
      return;
    }
    this.authorService.getAuthorPhoto(authorData.authorProfilePhotoId).subscribe((result: any) => {
      var imageUrl = "assets/images/author-user-image-default.svg";
      if (result.assetBase64 != null) {
        imageUrl = 'data:image/jpg;base64,' + result.assetBase64;
      }
      this.authorImageUrl = imageUrl;
      //this.selectedAuthorData.imageUrl = imageUrl;
      localStorage.setItem('selectedAuthorData', JSON.stringify(this.selectedAuthorData));
    }, err => {
      this.authorImageUrl = defaultUrl;
      return defaultUrl;
    });
  }

  loadAuthorsRelatedData() {
    this.bindAuthorData();
    this.getBooks();

  }

  subscription: Subscription;
  msUrl = environment.msSalesGlanceUrl + environment.auth;
  authors: any = [];
  selectedAuthor: any = '';
  selectedAuthorData: any = {};
  bookShelfConstant: any = {
    // eslint-disable-next-line no-magic-numbers
    pager: [5, 10, 20, 'All'],
    initialLimit: 5,
    bookTitleCharacterEllipsis: '...',
    bookTitleCharacter: 75
  };
  searchBook: string;
  settings: any = [];
  pageLimitPrefences: any = {};
  ngOnInit(): void {
    this.analyticsService.pageView('Dashboard', 'Page Load');
    this.analyticsService.eventEmitter('Dashboard', window.location.href, 'Page Load');
    this.getSettings();
  }

  bindAuthorData() {
    // this.selectedAuthor = localStorage.getItem('selectedAuthor');
    this.selectedAuthorData = this.utilityService.getAuthorData();
    this.selectedAuthor = this.selectedAuthorData.fullName;
    this.getAuthorImage(this.selectedAuthorData);

    if (this.sessionService.getUserRole() === authordesc.agentRole) {
      this.description = authordesc.agentDescription;
      this.isAuthorRole = false;
    }
    else {
      this.description = this.showKeyDates ? authordesc.shortDescription : authordesc.description;
      this.authorBlurbTitlePostFix = authordesc.authorBlurbTitlePostFix;
    }

  }

  // Choose different book
  choose() {
    const modalRef = this.modalService.open(ChooseDiffBookModalComponent, {
      centered: true,
      size: "xl",
      windowClass: "animated fadeIn"
    });

    modalRef.componentInstance.authorWorks = this.authorWorks;
    // this.activeModal.close();
  }



  selectAuthor() {
    const modalRef = this.modalService.open(SelectAuthorComponent, {
      centered: true,
      size: "md",
      windowClass: "animated fadeIn"
    });
    modalRef.result.then(
      result => {
        if (result) {
          // this.router.navigateByUrl("/admin/plans");
        }
      }
    );
  }




  getBooks() {
    this.utilityService.showLoader();
    this.authorService.getWorks().subscribe((result: any) => {
      this.utilityService.hideLoader();
      this.authorWorks = this.utilitiesService.filterProductWorkData(_cloneDeep(result));

      var imageUrl = this.coverImgURL;
      this.authorWorks.forEach(element => {
        element['coverImgURL'] = imageUrl + element.isbn + '/';

      });
 
      this.currentBookFuture = (_cloneDeep(result))[0];
      this.bookSelectorService.setInitialIsbns(_cloneDeep(this.authorWorks));
      
      this.bookSelectorService.allIsbns
      if(this.bookSelectorService && this.bookSelectorService.allIsbns && this.bookSelectorService.allIsbns.length ==0) {
        this.hasPOSFLAG = false;
      }
      
      // hbg team info
      result.forEach(element => {
        element['coverImgURL'] = imageUrl + element.isbn + '/';
      });
      this.workList = filter(result, function (item) {
        return item.hasTeam
      });
    
      this.getUserPreferences();
      this.pageScrollService.scroll({
        document: document,
        scrollTarget: '.dashboard-wrapper',
      });
    }, err => {
      this.utilitiesService.showInformation(this.utilitiesService.getErrorMessage(), 'Error');
    });
  }

  setBookLimit(length: number) {
    this.bookShelfConstant.initialLimit = length;
    this.saveUserPreferences();
  }



  selectEvent(item) {
    // do something with selected item
  }



  onFocused(e) {
    // do something
  }

  getSettings() {
    this.authService.getSettings().subscribe((result: any) => {
      this.settings = result;
    }, err => {
      this.utilitiesService.showInformation(this.utilitiesService.getErrorMessage(), 'Error');
    });
  }

  getUserPreferences() {
    this.authService.getAllUserPreferences().subscribe((result: any) => {
      if (result && result.displaypreferences && result.displaypreferences.bookShelfPageLimit) {
        this.bookShelfConstant.initialLimit = result.displaypreferences.bookShelfPageLimit;
        if (result.displaypreferences.bookShelfPageLimit > 10) {
          this.bookShelfConstant.initialLimit = this.authorWorks.length;
        }
      } else {
        this.bookShelfConstant.initialLimit = 5;
      }
    }, err => {
      this.utilitiesService.showInformation(this.utilitiesService.getErrorMessage(), 'Error');
    });
  }

  saveUserPreferences() {
    const formData = new FormData();
    let requestData: any = {};
    this.pageLimitPrefences.bookShelfPageLimit = this.bookShelfConstant.initialLimit;

    requestData.displaypreferences = this.pageLimitPrefences;

    formData.append('displayPreferences', JSON.stringify(requestData));
    formData.append('userId', this.authService.getUserId());

    this.authService.saveUserPreferences(formData).subscribe((result: any) => {

    }, err => {
      this.utilitiesService.showInformation(this.utilitiesService.getErrorMessage(), 'Error');
    });
  }

  showSalesLink(onSaleDate, workIsbn) {
    let sales_works = this.bookSelectorService.allIsbns;
    let isbnFilteredResults = filter(sales_works, function (item) {
      return item.isbn === workIsbn;
    });
    let displaySalesLink = isbnFilteredResults && isbnFilteredResults.length == 0;
    return moment() < moment(onSaleDate) || displaySalesLink ? false : true;
  }

  redirectToSales(e, isbn) {
    let sales_works = this.bookSelectorService.allIsbns;
    if (sales_works && sales_works.length == 0) {
      this.utilityService.showInformation("There is no data to display at this time.", 'NotAllowed');
    } else {
      if (e.ctrlKey) {
        const pathname: string = location.pathname;
        const url = pathname + "#" + this.router.serializeUrl(
          this.router.createUrlTree(['navigation/' + this.selectedAuthorData.authorProfileId + '/salesDashboard/' + isbn])
        );
        window.open(url, '_blank');
      } else {
        this.router.navigate(['navigation/' + this.selectedAuthorData.authorProfileId + '/salesDashboard/' + isbn]);
      }
    }
  }

  public gaClick(eventAction: string, event?: any) {
    
    eventAction = 'Dashboard - ' + eventAction;
    

    this.analyticsService.eventEmitter(eventAction, window.location.href, event);
  }
}