<modal id="modal-dialog-info">
    <div class="modal d-block">
        <div class="modal-body ml-auto mr-auto">
            <div class="text-center">
                <h4 class="mb-4 mt-3 merriweather font-size-36 text-black-color"
                    [ngClass]="errorType == 'Info' ? 'Information' : 'Error' ">
                    <i *ngIf="errorType != 'Warn' && errorType != 'NotAllowed' && errorType != 'SalesNotAllowed'"
                        [ngClass]="errorType == 'Info' ? 'fa-check success-check' : 'fa-exclamation-circle text-warning'"
                        class="fa fa-check success-check" aria-hidden="true"></i>
                    {{errorHeading}}  
                </h4>
                <p class="merriweather font-size-20 text-gray-color">
                    {{notificationMessage}} 
                </p>
            </div>
            <div *ngIf="errorType == 'Info'" class="modal-footer border-white d-flex justify-content-center mt-2">
                <button (click)="closeModal()" class="btn btn-theme-blue">Continue</button>
            </div>
            <div *ngIf="errorType == 'NotAllowed'" class="modal-footer border-white d-flex justify-content-center mt-2">
                <button (click)="closeModal()" class="btn btn-theme-blue">Continue</button>
            </div>
            <div *ngIf="errorType == 'Alert'" class="modal-footer border-white d-flex justify-content-center mt-2">
                <button (click)="closeModal()" class="btn btn-theme-blue">Ok</button>
            </div>
            <div *ngIf="errorType == 'Error'" class="modal-footer border-white d-flex justify-content-center mt-2">
                <button (click)="closeModal()" class="btn btn-theme-blue">Close</button>
            </div>
            <div *ngIf="errorType == 'SalesNotAllowed'" class="modal-footer border-white d-flex justify-content-center mt-2">
                <button (click)="redirectToDahsboard()" class="btn btn-theme-blue">Continue</button>
            </div>
        </div>
    </div>
    <div class="modal-background"></div>
</modal>