import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import {
  find as _find,
  orderBy as _orderBy,
  isEmpty as _isEmpty,
  cloneDeep as _cloneDeep,
} from 'lodash-es';
import { interval, queueScheduler, Subscription } from 'rxjs';
import { AuthorService } from 'src/app/service/author.service';
import { PermissionService } from 'src/app/service/permission.service';
import { questionnaireConstants } from '../questionnaire-constants';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { SessionService } from 'src/app/service/session.service';
import { QuestionnaireService } from 'src/app/service/questionnaire.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-you',
  templateUrl: './about-you.component.html',
  styleUrls: ['./about-you.component.scss'],
})
export class AboutYouComponent implements OnInit {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  aboutBookQuestionnaires: any = [];
  @Input() settings: [];
  @Output('saveSection') saveSection: EventEmitter<string> = new EventEmitter();
  selectedAuthor: string;
  selectedAuthorData: any;
  subscription: Subscription;
  years: any = [];
  months: any = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  days: any = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];
  isAboutYouFormChanged: any = false;
  canEdit: any = true;
  selectedvalue = '';
  showText: boolean = false;
  authorImageUrl: string;
  pagePermission: any = [];
  currentBookFuture: any;
  options: any = {
    types: ['geocode'],
    forceSelection: true,
  };
  contactSection: any;
  isContactFormChanged: any;
  oldaboutBookQuestionnaires: any[];
  saveTimeInterval: Subscription;
  multiSelectSeparator = environment.multiSelectSeparator;
  constructor(
    private questionnaireService: QuestionnaireService,
    public sessionService: SessionService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private utilityService: UtilitiesService,
    private analyticsService: AnalyticsService,
    private authorService: AuthorService,
    private permissionService: PermissionService
  ) {
    this.pagePermission = this.permissionService.getPagePermission();
    this.canEdit = this.pagePermission.canEditAq == 1 ? true : false;

    this.subscription = this.authService.selectedAuthor.subscribe((result) => {
      this.bindAuthorData();
    });
  }

  ngOnInit(): void {
    this.analyticsService.pageView('About You', 'Page Load');
    this.analyticsService.eventEmitter(
      'About You',
      window.location.href,
      'Page Load'
    );
    this.years = this.getYears();
    this.bindAuthorData();
    this.getQuestionnaires();
    this.autoSaveForm();
    this.formChangesOnLogout();
  }

  bindAuthorData() {
    this.selectedAuthor = localStorage.getItem('selectedAuthor');
    this.selectedAuthorData = this.utilityService.getAuthorData();
    this.getAuthorImage(this.selectedAuthorData);
  }

  getQuestionnaires() {
    //   aboutYouSettingKey: 'ABOUT_AUTHOR_QUESTIONNAIRE_ID',
    // aboutYourBookSettingKey: 'ABOUT_BOOK_QUESTIONNAIRE_ID',

    var questionnaireId = _find(this.settings, {
      key: 'ABOUT_AUTHOR_QUESTIONNAIRE_ID',
    }).value;

    this.utilityService.showLoader();
    this.authService.getQuestionares(questionnaireId).subscribe(
      (result: any) => {
        this.utilityService.hideLoader();
        this.aboutBookQuestionnaires = result;
        this.oldaboutBookQuestionnaires = _cloneDeep(result);
        this.bindQuestionnairesValue();
      },
      (err) => {
        this.utilityService.showInformation(
          this.utilityService.getErrorMessage(),
          'Error'
        );
      }
    );
  }

  getDate(question) {
    var date;
    question.answers = question.answers ? question.answers : {};
    if (question.answers.answerText) {
      date = question.answers.answerText.split('/');
      question.month = date[1];
      question.day = date[0];
      question.year = date[2];
    }
    return question;
  }

  getYears() {
    var maxYears = 110;
    var baseYear = 1900;

    var currentYear = new Date().getFullYear(),
      years = [],
      i;
    for (i = currentYear - maxYears; i <= currentYear; i++) {
      years.push(i);
    }

    return years;
  }

  checkError(pattrenErrors, field, i) {
    var hasError = false;
    if (!pattrenErrors) {
      return false;
    }

    pattrenErrors.forEach(function (error) {
      if (error[field + '_' + i]) {
        hasError = true;
      }
    });
    if (hasError) {
      return true;
    }

    return false;
  }

  selectChange(question) {
    this.aboutYouFormChanged();
    this.setDate(question);
    this.showText = true;
  }

  textKeyPress() {
    this.showText = true;
  }

  aboutYouFormChanged = function () {
    if (this.isAboutYouFormChanged) {
      this.isAboutYouFormChanged = true;
    }
  };

  setDate(question) {
    if (question.day && question.month && question.year) {
      question.answers.answerText =
        question.day + '/' + question.month + '/' + question.year;
    }
  }

  getContacts() {
    throw new Error('Method not implemented.');
  }

  save(quest: any, form?: any, autoSave = '', index?: any, saveType: any = '') {
    if (autoSave != 'autoSave') {
      if (this.EmailValidation(form, 'manualSave', index)) {
        return;
      }
    }
    this.gaClick(quest.name, 'Save - About You questionnaire');
    //this.oldaboutBookQuestionnaires = _cloneDeep(this.aboutBookQuestionnaires);
    this.showText = false;
    quest.selectedtab = 'aboutyou';
    quest.saveMode = autoSave;
    this.saveSection.emit(quest);
    this.bindQuestionnairesValue();
    this.questionnaireService.idleChangesQuestionnaire();
    if (saveType == 'saveAll') {
      this.checkFormChanges();
      return;
    }
  }

  getAuthorImage(authorData: any) {
    var defaultUrl = 'assets/images/author-user-image-default.svg';
    this.authorService
      .getAuthorPhoto(authorData.authorProfilePhotoId)
      .subscribe(
        (result: any) => {
          var imageUrl = 'assets/images/author-user-image-default.svg';
          if (result.assetBase64 != null) {
            imageUrl = 'data:image/jpg;base64,' + result.assetBase64;
          }
          this.authorImageUrl = imageUrl;
        },
        (err) => {
          this.authorImageUrl = defaultUrl;
          return defaultUrl;
        }
      );
  }

  setAddress(answer, index, section) {
    var addressFields: any = {},
      i,
      counter = 2,
      requiredFields = ['city', 'state', 'zip', 'country'],
      address = answer.answerText;
    if (address.formatted_address) {
      addressFields.city = this.getAddressComponent(
        questionnaireConstants.cityVar,
        address.address_components
      );
      addressFields.state = this.getAddressComponent(
        questionnaireConstants.stateVar,
        address.address_components
      );
      addressFields.country = this.getAddressComponent(
        questionnaireConstants.countryVar,
        address.address_components
      );
      addressFields.zip = this.getAddressComponent(
        questionnaireConstants.zipVar,
        address.address_components
      );
      answer.answerText = address.formatted_address;
      for (i = 0; i < requiredFields.length; i++) {
        if (
          section.questions[index + counter].questionText.search(
            new RegExp(requiredFields[i], 'i')
          ) > -1
        ) {
          section.questions[index + counter].answers.answerText =
            addressFields[requiredFields[i]];
        }
        counter++;
      }
    } else {
      answer.answerText = address;
    }
  }

  public handleAddressChange(address, answer) {
    if (address.formatted_address) {
      // this.contactSection.city = this.getAddressComponent(questionnaireConstants.cityVar, address.address_components);
      // this.contactSection.state = this.getAddressComponent(questionnaireConstants.stateVar, address.address_components);
      // this.contactSection.country = this.getAddressComponent(questionnaireConstants.countryVar, address.address_components);
      // this.contactSection.zip = this.getAddressComponent(questionnaireConstants.zipVar, address.address_components);
      answer.answerText = address.formatted_address;
    } else {
      answer.answerText = address?.target?.value;
    }
  }

  public getAddressComponent(field, addressComponents) {
    var addressField = _find(addressComponents, function (addressComponent) {
      return addressComponent.types.indexOf(field) >= 0;
    });

    return addressField ? addressField.long_name : '';
  }

  public contactFormChanged() {
    if (!this.isContactFormChanged) {
      this.isContactFormChanged = true;
    }
  }

  public gaClick(event: string, eventAction?: string) {
    if (!eventAction) {
      eventAction = 'About You questionnaire';
    }

    this.analyticsService.eventEmitter(
      eventAction,
      window.location.href,
      event
    );
  }

  private autoSaveForm() {
    if (this.saveTimeInterval) {
      this.saveTimeInterval?.unsubscribe();
    }

    this.saveTimeInterval = interval(180000).subscribe(() => {
      this.checkFormChanges();
    });
  }
  public checkFormChanges() {
    for (var i = 0; i < this.oldaboutBookQuestionnaires?.length; i++) {
      let oldData = this.oldaboutBookQuestionnaires[i]['questions'].map((x) =>
        x.answers.answerText
          ? x.answers.answerText.toString()
          : x.answers.answerText === false
          ? 'false'
          : null
      );
      let newData = this.aboutBookQuestionnaires[i]['questions'].map((x) =>
        x.answers.answerText
          ? x.answers.answerText.toString()
          : x.answers.answerText === false
          ? 'false'
          : null
      );
      if (JSON.stringify(oldData) != JSON.stringify(newData)) {
        if (
          this.EmailValidation(this.aboutBookQuestionnaires[i], 'onTabChange')
        ) {
          this.save(this.aboutBookQuestionnaires[i], undefined, 'autoSave');
        }
      }
    }
    this.oldaboutBookQuestionnaires = _cloneDeep(this.aboutBookQuestionnaires);
  }

  bindQuestionnairesValue() {
    this.aboutBookQuestionnaires.forEach((section) => {
      section.counter = 0;

      section.questions.forEach((ques) => {
        if (ques.questionType !== 'checkbox') {
          section.counter =
            ques.questionType === 'label'
              ? section.counter
              : section.counter + 1;
          ques.counter = ques.questionType === 'label' ? '' : section.counter;
          ques.answers = ques.answers ? ques.answers : {};
        }
        if (ques.questionType == 'checkbox') {
          if (
            (ques.answers?.answerText && ques.answers?.answerText == 'true') ||
            ques.answers?.answerText == true
          ) {
            ques.answers.answerText = true;
          } else if (
            ques.answers.answerText == 'false' ||
            ques.answers.answerText == false
          ) {
            ques.answers.answerText = false;
          } else {
            if (ques.answers == '') {
              ques.answers = {};
            }
          }
        }

        if (ques.questionType === 'date') {
          ques = this.getDate(ques);
        }

        if (ques.questionType == 'textarea') {
          let rowCount = 3;
          if (ques.answers.answerText) {
            rowCount = ques.answers.answerText.split('\n').length;
            if (rowCount <= 3) {
              ques.answers.rowCount =
                parseInt((ques.answers.answerText.length / 150).toString()) + 2;
            }
            if (rowCount > 20) {
              ques.answers.rowCount = 20;
            } else {
              ques.answers.rowCount = rowCount;
            }
          } else {
            ques.answers.rowCount = rowCount;
          }
        }

        ques.answerModifiedDate = !_isEmpty(ques.answers)
          ? new Date(ques.answers.modifiedDate)
          : '';
      });

      var _seactions = _orderBy(
        section.questions,
        'answerModifiedDate',
        'desc'
      );
      if (_seactions.length > 0 && _seactions[0].answerModifiedDate) {
        section.modifiedDate = new Date(_seactions[0].answerModifiedDate);
        if (section.modifiedDate == 'Invalid Date') {
          section.modifiedDate = false;
        }
      } else {
        section.modifiedDate = false;
      }
      this.cdr.detectChanges();
    });
  }

  EmailValidation(form, type, index?: any) {
    if (type == 'onTabChange') {
      let emailFiedlds = form.questions.filter(
        (x) => x.questionType == 'email'
      );
      let emailInvalid = false;
      let reg = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      emailFiedlds.forEach((element) => {
        if (
          element.answers.answerText &&
          element.answers.answerText != '' &&
          !reg.test(element.answers.answerText)
        ) {
          emailInvalid = true;
          return;
        }
      });

      if (emailInvalid) {
        return false;
      }

      return true;
    }

    if (type == 'manualSave') {
      try {
        if (form?.controls) {
          let emails = Object?.keys(form?.controls).filter((x) => {
            if (x.startsWith('email')) {
              return x;
            }
          });

          let emailInvalid = false;
          emails.forEach((element) => {
            if (
              form.controls[element].invalid &&
              form.controls[element].dirty
            ) {
              emailInvalid = true;
              return;
            }
          });

          if (emailInvalid) {
            return true;
          }
        }
      } catch {}
      this.oldaboutBookQuestionnaires[index] = _cloneDeep(
        this.aboutBookQuestionnaires[index]
      );
      this.saveTimeInterval?.unsubscribe();
      this.autoSaveForm();
      return false;
    }
  }
  formChangesOnLogout() {
    this.sessionService.questionnaireObs.subscribe((res) => {
      this.checkFormChanges();
    });
  }
  ngOnDestroy() {
    this.checkFormChanges();
    this.saveTimeInterval?.unsubscribe();
  }

  // Function to update the checkbox question type's answer based on the event triggered
  updateCheckBoxQuesType(ques, evt) {
    // Find the index of the category matching the question's category ID
    let categoryIndex = this.aboutBookQuestionnaires.findIndex(
      (category) => category.categoryId == ques.categoryId
    );

    // Find the index of the question within the found category
    let quesIndex = this.aboutBookQuestionnaires[
      categoryIndex
    ].questions.findIndex(
      (bookQuestionnaire) => bookQuestionnaire.questionId == ques.questionId
    );

    // Retrieve the current answer text, defaulting to an empty string if not found
    let ansStr =
      this.aboutBookQuestionnaires[categoryIndex].questions[quesIndex].answers
        .answerText || '';

    // If the current answer is 'true' or true, reset it to an empty string to handle checkbox behavior
    if (ansStr == 'true' || ansStr === true) {
      ansStr = '';
    }
    // Similarly, reset if the current answer is 'false' or false
    else if (ansStr == 'false' || ansStr === false) {
      ansStr = '';
    }

    // If the checkbox is checked, add the value to the answer string
    if (evt.checked) {
      ansStr.length
        ? (ansStr += this.multiSelectSeparator + evt.source.value)
        : (ansStr += evt.source.value);
    } else {
      // If the checkbox is unchecked, remove the value from the answer string
      let data = ansStr.split(this.multiSelectSeparator);
      // Find the index of the unchecked value
      let index = data.findIndex((res) => res === evt.source.value);
      // Remove the unchecked value
      data.splice(index, 1);
      ansStr = data.join(this.multiSelectSeparator);
    }

    // Update the answer text in the questionnaire with the modified answer string
    this.aboutBookQuestionnaires[categoryIndex].questions[
      quesIndex
    ].answers.answerText = ansStr;
  }

  isCheckBoxChecked(answer, option) {
    if (answer.split(this.multiSelectSeparator).length) {
      return answer.split(this.multiSelectSeparator).some((res) => {
        return res == option;
      });
    } else {
      return false;
    }
  }
  getAnswerTextAsBoolean(question: any): boolean {
    return (
      question.answers.answerText === 'true' ||
      question.answers.answerText === true
    );
  }

  setAnswerText(question: any, value: boolean) {
    question.answers.answerText = value.toString();
  }
}
