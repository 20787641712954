<app-header></app-header>
<section class="py-md-4">
    <div class="container">
        <div class="team-member-profile">
            <div class="row">
                <div class="col-md-9 border-end">
                    <div class="d-flex flex-wrap w-100 p-4" *ngIf="userBio && userBio.userId">
                        <div class="member-info-card">
                            <div class="member-info d-flex align-items-center justify-content-center w-100 h-100 text-center">
                                <div class="info">
                                    <h3 class="merriweather-font mb-0"><em>{{userBio?.fullName}}</em></h3>
                                    <p class="merriweather-font">Role: {{userBio?.roleList.join(", ")}} </p>
                                    <a href="{{'mailto:' + userBio?.emailAddress}}">{{userBio?.emailAddress}}</a>
                                    <p class="phobe-number">{{userBio?.phoneExtension}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="member-info-card"><img [src]="getUserPhoto()" class="w-100"></div>                        
                    </div>
                    <div class="d-flex flex-wrap w-100 px-4 py-3">
                            <p class= "description" id="userBioInfo"></p>
                        </div>
                </div>
                <div class="col-md-3 text-center" *ngIf="teamList.length > 0">
                    <div class="p-3 my-3 book-details">
                        <h3>HBG TEAM FOR {{userBio?.title | uppercase}}</h3>
                        <ul class="list-group">
                            <li class="list-group-item" *ngFor="let result of teamList ; let i = index">
                                <a
                                    [routerLink]="['/navigation', auth.getSelectedAuthor(), 'work', result.workIsbn,'member', result.userId ]">
                                    <em>{{result?.fullName}}</em>
                                    <br>
                                    <span>{{result?.empTitle}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>