<div class="tab-inner-content bootstrap-material-design p-4 pt-0">
    <!-- Book Info block start here -->
    <h4 class="back-line-txt text-center my-3"><span class="">ABOUT YOUR BOOK</span></h4>
    <div class="row author-info book-info">
        <div class="col-lg-12 col-xl-6 mb-3 mb-xl-0">
            <div class="d-md-flex d-block">
                <div class="flex-shrink-0 text-md-start text-center mb-3">
                    <div class="zoom-effect user-profile-pic m-md-0 m-auto">
                        <img alt="" class="fix-height" src="{{currentBookFuture?.coverImgURL}}"
                            onerror="this.src='./assets/images/default-book.svg';">
                    </div>
                </div>
                <div class="flex-grow-1 ms-md-3 ms-1 ps-0 ps-md-4 border-end pe-0 pe-md-3 text-md-start text-center">
                    <h2 class="book-title">{{currentBookFuture?.title}}</h2>
                    <p class="author-name mt-2 mb-4"><em>by</em> {{selectedAuthorData?.fullName}}</p>
                    <a (click)="downloadPdf(); gaClick('Download PDF')" class="download-pdf d-block"><b><i
                                class="fas fa-download"></i> Download
                            Author and
                            Illustrator Questionnaire responses (PDF)</b></a>
                    <div class="mt-4"><button type="button" (click)="chooseDifferentBook()"
                            class="btn btn-theme-blue px-4 my-2 my-md-0">Choose
                            a Different Book</button></div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-xl-6 text-md-start text-center ps-md-4">
            <div class="px-md-3">
                <h3 class="author-text-blur">Tell Us About This Book</h3>
                <p>Every book is different—and offers unique opportunities for
                    promotion. In this section of your AQ,
                    tell us what inspired your new book, share stories about your writing
                    experience,
                    and tell us about any specific marketing ideas you have.</p>
            </div>
        </div>
    </div>
    <!-- Book Info block end here -->
    <div class="row mt-5">
        <div class="accordion accordion-flush tab-info-details" id="accordionFlushExample">
            <div class="accordion-item border mb-4" *ngFor="let authque of aboutBookQuestionnaires; let i=index">
                <h2 class="accordion-header" id="base{{authque?.categoryId}}">
                    <button class="accordion-button  px-3 py-2 " type="button" data-bs-toggle="collapse"
                        (click)="gaClick(authque?.name)" [class.collapsed]="i != 0"
                        [attr.data-bs-target]="'#main' + authque?.categoryId" aria-expanded="false"
                        [attr.aria-controls]="'main' + authque?.categoryId">
                        {{authque?.name}}
                    </button>
                </h2>
                <div id="main{{authque?.categoryId}}" class="accordion-collapse collapse" [class.show]="i == 0"
                    [attr.aria-labelledby]="'base'+ authque?.categoryId" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <!-- <span *ngIf="showText" class="alert-msg w-100 fw-bolder mb-4 d-block">Please remember to use the
                            “save”
                            button at the bottom of this form to save your changes before leaving this section of the QA
                            !!!</span> -->
                        <form #f="ngForm" novalidate>
                            <div class="questions" *ngFor="let ques of authque.questions; let i=index;">
                                <label *ngIf="ques.questionType !== 'checkbox'"
                                    [ngClass]="{'black-label px-2': ques.questionType === 'label','px-4': ques.questionType != 'label'}">
                                    <i *ngIf="ques.icon && ques.icon !== 'none'" class="label-icon"
                                        style="background:url(app/assets/icons/{{ques.icon}}.svg) no-repeat 0 0"></i>
                                    <ng-container class="pull-left me-2" *ngIf="authque.serialNumberEnable">
                                        {{ques.counter}}
                                    </ng-container>

                                    {{ques.questionText}}
                                </label>
                                <span
                                    *ngIf="ques.questionType === 'email' && f.controls['email'+i] && f.controls['email'+i].invalid && f.controls['email'+i].dirty && ques.answers.answerText"
                                    class="red alert-message ms-3">Please enter a valid email address.</span>
                                <div *ngIf="ques.questionType !== 'checkbox'">
                                    <mat-form-field class="w-100 border-0" *ngIf="ques.questionType === 'text'"
                                        appearance="legacy">
                                        <input matInput [disabled]="!canEdit" name="username{{i}}"
                                            [(ngModel)]="ques.answers.answerText" (input)="textKeyPress()"
                                            (change)="selectChange(ques)">
                                    </mat-form-field>
                                    <mat-form-field class="w-100 border-0" *ngIf="ques.questionType === 'email'"
                                        appearance="legacy">
                                        <input matInput [disabled]="!canEdit" name="email{{i}}"
                                            [(ngModel)]="ques.answers.answerText" (change)="selectChange(ques)"
                                            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
                                    </mat-form-field>
                                    <mat-form-field class="w-100 border-0" *ngIf="ques.questionType === 'textarea'"
                                        appearance="legacy">
                                        <textarea maxlength="9980" rows="{{ques.answers.rowCount}}"
                                            [disabled]="!canEdit" (input)="textKeyPress()" (change)="selectChange(ques)"
                                            [(ngModel)]="ques.answers.answerText" name="textarea{{i}}"
                                            matInput></textarea>
                                    </mat-form-field>
                                    <!-- Ng container start here -->
                                    <ng-container *ngIf="ques.questionType === 'radio'">
                                        <mat-radio-group class="ms-5" name="{{i}}_radio"
                                            [(ngModel)]="ques.answers.answerText">
                                            <mat-radio-button class="ms-5 d-block"
                                                *ngFor=" let option of ques.optionValues.split('\n'); let i_index = index"
                                                [disabled]="!canEdit" value="{{ option }}">{{
                                                option }}</mat-radio-button>
                                        </mat-radio-group>
                                    </ng-container>
                                    <!-- Ng container end here -->
                                    <!-- Ng container start here -->
                                    <ng-container>
                                        <mat-form-field class="w-100 mb-3" *ngIf="ques.questionType === 'select'"
                                            appearance="legacy">
                                            <mat-select [(ngModel)]="ques.answers.answerText" name="answerText{{i}}">
                                                <mat-option [disabled]="!canEdit"
                                                    *ngFor=" let option of ques.optionValues.split('\n'); let i_index = index"
                                                    [disabled]="!canEdit" value="{{ option }}">
                                                    {{ option }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- <mat-form-field *ngIf="ques.questionType === 'address'" class="w-100 border-0"
                                            appearance="legacy">
                                            <input matInput [disabled]="!canEdit" g-places-autocomplete
                                                [(ngModel)]="ques.answers.answerText" name="answerText{{i}}"
                                                ng-blur="$ctrl.setAddress( ques.answers, i, section )"
                                                options="$ctrl.autocompleteOptions" (change)="selectChange(ques)" />                                                
                                        </mat-form-field> -->

                                        <mat-form-field *ngIf="ques.questionType === 'address'" class="w-100 border-0"
                                            appearance="legacy">
                                            <input matInput [disabled]="!canEdit" ngx-google-places-autocomplete
                                                value="{{ques.answers.answerText}}" [options]='options'
                                                #placesRef="ngx-places"
                                                (input)="handleAddressChange($event, ques.answers)"
                                                (onAddressChange)="handleAddressChange($event, ques.answers)" />
                                        </mat-form-field>
                                    </ng-container>
                                    <!-- Ng container end here -->
                                </div>
                                <div *ngIf="ques.questionType === 'date'" class="row">
                                    <div class="col-md-2 col-xs-4 no-gutter">
                                        <div class="form-group">
                                            <select [disabled]="!canEdit"
                                                class="w-100 form-control border-0 border-bottom rounded-0 py-2 bg-transparent"
                                                abm-form-control [(ngModel)]="ques.month" (change)="selectChange(ques)"
                                                name="month">
                                                <option selected value="undefined"> Month </option>
                                                <option *ngFor=" let month of months" value="{{month}}">{{month}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-xs-4">
                                        <div class="form-group">
                                            <select [disabled]="!canEdit"
                                                class="w-100  form-control border-0 border-bottom rounded-0 py-2 bg-transparent"
                                                abm-form-control [(ngModel)]="ques.day" (change)="selectChange(ques)"
                                                name="day">
                                                <option selected value="undefined"> Day </option>
                                                <option *ngFor=" let day of days" value="{{day}}">{{day}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-xs-4">
                                        <div class="form-group">
                                            <select [disabled]="!canEdit"
                                                class="w-100 form-control border-0 border-bottom rounded-0 py-2 bg-transparent"
                                                abm-form-control [(ngModel)]="ques.year" (change)="selectChange(ques)"
                                                name="year">
                                                <option selected value="undefined"> Year </option>
                                                <option *ngFor=" let year of years" value="{{year}}">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div *ngIf="ques.questionType === 'checkbox'">
                                    <mat-checkbox class="ms-5 matCheckboxClass" [disabled]="!canEdit" name="chk{{i}}"
                                        [ngModel]="getAnswerTextAsBoolean(ques)"
                                        (ngModelChange)="setAnswerText(ques, $event)" (change)="selectChange(ques)">
                                        {{ ques.questionText }}
                                    </mat-checkbox>
                                </div>

                                <ng-container *ngIf="ques.questionType === 'multi-checkbox'">
                                    <ng-container
                                        *ngFor="let option of ques.optionValues.split('\n'); let i_index = index">
                                        <mat-checkbox class="ms-5 matCheckboxClass d-flex" [disabled]="!canEdit"
                                            (change)="updateCheckBoxQuesType(ques,$event)"
                                            [checked]="ques?.answers?.answerText?.length ? isCheckBoxChecked(ques?.answers?.answerText,option) : ''"
                                            name="multi-chk{{i_index}}" value="{{option}}">
                                            {{ option }}
                                        </mat-checkbox>
                                    </ng-container>
                                </ng-container>

                            </div>
                            <div class="d-flex">
                                <div class="mb-0">
                                    <button type="button" [disabled]="!canEdit"
                                        (click)="save(authque,f,'0',i,'saveAll')"
                                        class="btn btn-save btn-theme-blue my-2 my-md-0">Save</button>

                                </div>
                                <div>
                                    <p class="mb-0 ps-3"><b>This form auto-saves every 3 minutes.</b></p>
                                    <p class="last-saved ml-3 d-inline-block" *ngIf="authque.modifiedDate">Last saved at
                                        {{ authque.modifiedDate | date : 'shortTime'}} on {{ authque.modifiedDate | date
                                        :
                                        'fullDate'}}
                                    </p>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact block start here -->
    <!-- <div class="row">
        <div class="accordion accordion-flush tab-info-details questionnaire" id="accordionFlushExample">
            <div class="accordion-item border mb-4">
                <h2 class="accordion-header" id="flush-headingFour">
                    <button class="accordion-button px-3 py-2" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                        Blurber Contacts
                    </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse show"
                    aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body px-3 px-md-5">
                        <a (click)="getContactData('Blurber'); gaClick('Blurber Download an Excel XLS')" class="pdf-download"><b>
                                <i class="fas fa-download"></i> Download an
                                Excel XLS</b></a>
                        <label for="exampleFormControlInput1" class="contact-headings form-label mt-4">HBG
                            wants to help you activate your own network of contacts.
                            Launching your book is a great time to call in favors. Please
                            spend some time considering contacts you have who might
                            help.</label>
                        <label for="exampleFormControlInput1" class="contact-headings form-label mb-5"><b>Blurbers:</b>
                            Please list
                            appropriate people to whom we might send bound galleys for
                            advance comment (authors, prominent people, specialists in your
                            field, etc.). Please indicate if you know someone personally and
                            provide mailing addresses and social media information.</label>

                        <ng-container *ngFor="let contacts of allContacts; let i = index">
                            <div *ngIf="contacts.contactType == 'Blurber' && !contacts.isDeleted"
                                class="accordion accordion-flush tab-info-details mb-4" id="newBluuberContact">
                                <div class="accordion-item border mb-4">
                                    <h2 class="accordion-header" id="base{{contacts.contactId}}">
                                        <a class="accordion-button px-3 py-2 " type="button"
                                            (click)="deleteNewItem(contacts.isNew, i);  gaClick('Delete Blurber Contact')"
                                            [class.collapsed]="!contacts.isNew" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#main' + contacts.contactId" aria-expanded="false"
                                            [attr.aria-controls]="'main' + contacts.contactId"
                                            role="button">{{contacts.contactName}} <button *ngIf="contacts.contactId"
                                                type="button" class="btn btn-theme-blue btn-delete"
                                                (click)="deleteContact(contacts.contactId)">Delete</button>
                                            </a> -->
    <!--<button class="accordion-button px-3 py-2 " type="button"
                                            (click)="deleteNewItem(contacts.isNew, i)"
                                            [class.collapsed]="!contacts.isNew" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#main' + contacts.contactId" aria-expanded="false"
                                            [attr.aria-controls]="'main' + contacts.contactId">
                                            {{contacts.contactName}}
                                             <button *ngIf="contacts.contactId" type="button"
                                                class="btn btn-theme-blue btn-delete"
                                                (click)="deleteContact(contacts.contactId)">Delete</button>
                                        </button>
                                        <button type="button"
                                        class="btn btn-theme-blue btn-delete"
                                        (click)="deleteContact(contacts.contactId)">Delete</button>
                                </button> -->
    <!-- </h2>
                                    <div id="main{{contacts.contactId}}" class="accordion-collapse collapse"
                                        [class.show]="contacts.isNew"
                                        [attr.aria-labelledby]="'base'+ contacts.contactId"
                                        data-bs-parent="#newBluuberContact">
                                        <div class="accordion-body px-3 px-md-5">
                                            <app-contacts [contactSection]="contacts" nameHeading="Blurber Contact Name"
                                                (saveSection)="saveContactSection($event);   gaClick('Save Blurber Contact')">
                                            </app-contacts>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <button type="button" (click)="addNewContact('Blurber'); gaClick('Add New Blurber Contact')"
                            class="btn pull-right btn-theme-gray px-4 my-2 my-md-0">Add
                            New Contact</button>
                    </div>
                </div>
            </div> -->
    <!-- Media Contacts -->
    <!-- <div class="accordion-item border mb-4">
                <h2 class="accordion-header" id="flush-headingFive">
                    <button class="accordion-button collapsed px-3 py-2" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                        Media Contacts
                    </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body px-3 px-md-5">
                        <a (click)="getContactData('Media'); gaClick('Media Download an Excel XLS')" class="pdf-download mb-4"><b><i
                                    class="fas fa-download"></i> Download
                                an
                                Excel XLS</b></a>
                        <label for="exampleFormControlInput1" class="contact-headings form-label">HBG
                            wants to help you activate your own network of contacts.
                            Launching your book is a great time to call in favors. Please
                            spend some time considering contacts you have who might
                            help.</label>
                        <label for="exampleFormControlInput1" class="contact-headings form-label mb-5"><b>Media
                                Contact:</b> Please
                            list
                            any
                            media contacts (reviewers, columnists, broadcasters, bloggers)
                            with whom you have a personal connection. Please provide mailing
                            addresses and social media information.</label>
                        <ng-container *ngFor="let contacts of allContacts; let i = index">
                            <div *ngIf="contacts.contactType == 'Media' && !contacts.isDeleted"
                                class="accordion accordion-flush tab-info-details mb-4" id="newMediaContact">
                                <div class="accordion-item border mb-4">
                                    <h2 class="accordion-header" id="base{{contacts.contactId}}">
                                        <a class="accordion-button px-3 py-2" type="button"
                                            [class.collapsed]="!contacts.isNew" data-bs-toggle="collapse"
                                            (click)="deleteNewItem(contacts.isNew, i); gaClick('Delete Media Contact')"
                                            [attr.data-bs-target]="'#main' + contacts.contactId" aria-expanded="false"
                                            [attr.aria-controls]="'main' + contacts.contactId">
                                            <span>{{contacts.contactName}}</span>
                                            <button *ngIf="contacts.contactId" type="button"
                                                class="btn btn-theme-blue btn-delete"
                                                (click)="deleteContact(contacts.contactId);  gaClick('Delete Media Contact')">Delete</button>
                                    </a>
                                    </h2>
                                    <div id="main{{contacts.contactId}}" class="accordion-collapse collapse"
                                        [class.show]="contacts.isNew"
                                        [attr.aria-labelledby]="'base'+ contacts.contactId"
                                        data-bs-parent="#newMediaContact">
                                        <div class="accordion-body px-3 px-md-5">
                                            <app-contacts [contactSection]="contacts" nameHeading="Media Contact Name"
                                                (saveSection)="saveContactSection($event);  gaClick('Save Media Contact')">
                                            </app-contacts>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <button type="button" (click)="addNewContact('Media');  gaClick('Add New Media Contact')"
                            class="btn btn-theme-gray px-4 my-2 my-md-0">Add
                            New Contact</button>
                    </div>
                </div>
            </div> -->
    <!-- Influencer Contacts-->
    <!-- <div class="accordion-item border mb-4">
                <h2 class="accordion-header" id="flush-headingSeven">
                    <button class="accordion-button collapsed px-3 py-2 " type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                        Influencer Contacts
                    </button>
                </h2>
                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body px-3 px-md-5">
                        <a (click)="getContactData('Influencer');  gaClick('Influencer Download an Excel XLS')" class="pdf-download mb-4"><b><i
                                    class="fas fa-download"></i> Download an
                                Excel XLS</b></a>
                        <label for="exampleFormControlInput1" class="contact-headings form-label mb-0">HBG
                            wants to help you activate your own network of contacts.
                            Launching your book is a great time to call in favors. Please
                            spend some time considering contacts you have who might
                            help.</label>
                        <label for="exampleFormControlInput1"
                            class="contact-headings form-label mb-5"><b>Influencers:</b> Please
                            list
                            any
                            thought leaders or tastemakers in your field who should be sent
                            copies of the finished book for word-of-mouth purposes. Think
                            broadly here. An influencer can be anyone from a librarian you
                            know personally to a CEO who invited you to speak at a company.
                            They could also be leaders of book clubs you have visited or
                            creatives in related fields. Please indicate if you know someone
                            personally. Please provide mailing addresses and, if applicable,
                            social media information.</label>
                        <ng-container *ngFor="let contacts of allContacts; let i = index">
                            <div *ngIf="contacts.contactType == 'Influencer' && !contacts.isDeleted"
                                class="accordion accordion-flush tab-info-details mb-4" id="newInfluencerContact">
                                <div class="accordion-item border mb-4">
                                    <h2 class="accordion-header" id="base{{contacts.contactId}}">
                                        <a class="accordion-button px-3 py-2 " type="button"
                                            (click)="deleteNewItem(contacts.isNew, i); gaClick('Delete Influencer Contact')"
                                            [class.collapsed]="!contacts.isNew" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#main' + contacts.contactId" aria-expanded="false"
                                            [attr.aria-controls]="'main' + contacts.contactId">
                                            <span>{{contacts.contactName}}</span>
                                            <button *ngIf="contacts.contactId" type="button"
                                                class="btn btn-theme-blue btn-delete"
                                                (click)="deleteContact(contacts.contactId); gaClick('Delete Influencer Contact')">Delete</button>
                                    </a>
                                    </h2>
                                    <div id="main{{contacts.contactId}}" class="accordion-collapse collapse"
                                        [class.show]="contacts.isNew"
                                        [attr.aria-labelledby]="'base'+ contacts.contactId"
                                        data-bs-parent="#newInfluencerContact">
                                        <div class="accordion-body px-3 px-md-5">
                                            <app-contacts [contactSection]="contacts" nameHeading="Influencer Name"
                                                (saveSection)="saveContactSection($event); gaClick('Save Influencer Contact')">
                                            </app-contacts>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <button type="button" (click)="addNewContact('Influencer'); gaClick('Add New Influencer Contact')"
                            class="btn pull-right btn-theme-gray px-4 my-2 my-md-0">Add
                            New Contact</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>