import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('access_token');

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        let cloneReq = request.clone();
        var p_auth = localStorage.getItem('pauth');
        if (p_auth && request.url.indexOf('jsonws') > 0 && request.url.indexOf('jsonws/ods-portlet.work/work/isbn/') < 0) {
            cloneReq = request.clone({
                params: request.params.set(
                    "p_auth",
                    p_auth
                ).set(
                    "check",
                    "true"
                ),
            })
        }

        return next.handle(cloneReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }));
    }
}